let cache = {};

function getElements(ids) {
    ids.forEach((id) => {
        cache[id] = document.getElementById(id);
    });
}

function activateTab(title, uid) {
    const pluginTab = cache['nav-plugin-options-tab'];
    const pluginContent = cache['nav-plugin-options'];
    const tabParent = cache['config-tabs'];
    const tabContent = cache['config-content'];
    const titleEl = cache['plugin-options-title'];

    const list = cache['plugin-list'];

    const pluginId = 'plugin_item_' + uid;

    list.childNodes.forEach((node) => {
        if (node.id === pluginId) node.classList.add('selected');
        else node.classList.remove('selected');
    });

    tabParent.childNodes.forEach((node) => node.classList.remove('active', 'show'));
    tabContent.childNodes.forEach((node) => node.classList.remove('active', 'show'));

    pluginTab.classList.remove('disabled');

    pluginTab.classList.add('active');

    pluginContent.classList.add('active', 'show');

    titleEl.innerText = title + ' Options';
}

function disableTab() {
    const pluginTab = cache['nav-plugin-options-tab'];
    const pluginContent = cache['nav-plugin-options'];
    const tabParent = cache['config-tabs'];
    const tabContent = cache['config-content'];

    if (pluginTab.classList.contains('active')) {
        pluginTab.classList.remove('active');
        pluginContent.classList.remove('active', 'show');

        const firstTab = tabParent.childNodes[0];
        const firstContent = tabContent.childNodes[0];

        firstTab.classList.add('active');

        firstContent.classList.add('active', 'show');
    }

    pluginTab.classList.add('disabled');
}

function init() {
    getElements([
        'nav-plugin-options',
        'nav-plugin-options-tab',
        'config-tabs',
        'config-content',
        'plugin-options-title',
        'plugin-list'
    ]);
    disableTab();
}

const dom = {
    disableTab,
    activateTab,
    init
};

export default dom;
