/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { REPO_BASE_DIR, SDK_SCRIPTS, SDK_MODULES } from './config';
import loadFiles from './loadFiles';
import env from '../Env';

const { cache, resetContext } = env;

let _sdkRepo = null;

function setSDKRepository(sdkRepo) {
    _sdkRepo = sdkRepo;
}

let filesTotal = 0;
let filesLoaded = 0;

function progress(url, xmlhttp, subheader) {
    var currentFile = url.replace(REPO_BASE_DIR, '');

    if (currentFile.length > 30)
        currentFile = '...' + currentFile.substring(currentFile.length - 30, currentFile.length);

    utils.popup(
        'Wait while playable is loading <small>[' + filesLoaded + '/' + filesTotal + ']</small>',
        (subheader ? "<div style='font-size:80%;'>" + subheader + '</div>' : '') +
            "<div style='font-size:90%; font-family: monospace; color:#cbcbcb'>" +
            currentFile +
            ' (0%)<br>0/0 KB</div>'
    );

    xmlhttp.onprogress = function (event) {
        let contentLength;

        if (event.lengthComputable) {
            contentLength = event.total;
        } else {
            contentLength = Number(event.target.getResponseHeader('x-decompressed-content-length'));
        }

        const percent = Math.round((event.loaded / contentLength) * 100);
        const total = Math.round(contentLength / 1024);
        const loaded = Math.round(event.loaded / 1024);
        utils.popup(
            'Wait while playable is loading <small>[' + (filesLoaded + 1) + '/' + filesTotal + ']</small>',
            (subheader ? "<div style='font-size:80%;'>" + subheader + '</div>' : '') +
                "<div style='font-size:90%; font-family: monospace; color:#cbcbcb'>" +
                currentFile +
                ' (' +
                percent +
                '%)<br>' +
                loaded +
                '/' +
                total +
                ' KB</div>'
        );
    };
}

function fileLoaded() {
    filesLoaded++;
}

async function loadPlayable(scene, sceneBuildConfig) {
    if (!_sdkRepo) return utils.hintError('SDK repository not found');

    const Playable = window.Playable;

    const build_config = scene.build;

    const cacheKey = scene.repository._id;

    // u.popup("Wait while playable is loading");

    filesTotal = 0;
    filesLoaded = 0;

    if (!env.CURRENT_SDK) {
        filesTotal += SDK_MODULES.length;
        filesTotal += SDK_SCRIPTS.length;
    }

    if (!cache[cacheKey]) {
        if (build_config.modules) filesTotal += Object.keys(build_config.modules).length;
        if (build_config.build_files) filesTotal += build_config.build_files.length;
        if (build_config.tool_files) filesTotal += build_config.tool_files.length;
    }

    const sdkHash = generateHash(_sdkRepo);
    const playableHash = generateHash(scene.repository);

    const sdkBaseDir = REPO_BASE_DIR + _sdkRepo.name;
    const playableBaseDir = REPO_BASE_DIR + scene.repository.name;

    utils.popup('Scene files loading...')

    if (!env.CURRENT_SDK) {
        const sdkModules = await loadFiles({
            files: SDK_MODULES,
            baseURL: sdkBaseDir,
            hash: sdkHash,
            onProgress: progress,
            onLoaded: fileLoaded
        });

        const sdkFiles = await loadFiles({
            files: SDK_SCRIPTS,
            baseURL: sdkBaseDir,
            hash: sdkHash,
            onProgress: progress,
            onLoaded: fileLoaded
        });

        env.CURRENT_SDK = {
            sources: sdkFiles,
            modules: sdkModules,
            // source_min: {},
            // modules_min: {}
        };
    }

    if (!cache[cacheKey]) {
        Playable.context = {};

        const playableModules = await loadFiles({
            files: build_config.modules,
            baseURL: playableBaseDir,
            hash: playableHash,
            onProgress: progress,
            onLoaded: fileLoaded
        });

        await loadFiles({
            files: build_config.tool_files,
            baseURL: playableBaseDir,
            hash: playableHash,
            onProgress: progress,
            onLoaded: fileLoaded,
            applyToContext: true
        });

        const playableFiles = await loadFiles({
            files: build_config.build_files,
            baseURL: playableBaseDir,
            hash: playableHash,
            onProgress: progress,
            onLoaded: fileLoaded
        });

        cache[cacheKey] = {
            onbuild: Playable.onbuild,
            config: Playable.config,
            ad_dynamic: Playable.ad_dynamic,
            translations: Playable.translations,
            context: Playable.context,
            sources: playableFiles,
            // source_min: {},
            // style: playableFiles.css,
            modules: playableModules,
            modules_min: {}
        };

        Playable.emit('cache:ready', cacheKey);

        resetContext();
    }

    await loadScene(scene, sceneBuildConfig);

    utils.popup('hide');
}

async function loadScene(scene, sceneBuildConfig) {
    const Playable = window.Playable;

    const cacheKey = scene._id;
    const repoCacheKey = scene.repository._id;

    if (!cache[cacheKey]) {
        Playable.context = {};

        if (cache[repoCacheKey] && cache[repoCacheKey].context)
            Playable.context = Object.assign(Playable.context, cache[repoCacheKey].context);

        filesTotal = 0;
        if (sceneBuildConfig.build_files) filesTotal += sceneBuildConfig.build_files.length;
        if (sceneBuildConfig.tool_files) filesTotal += sceneBuildConfig.tool_files.length;

        filesLoaded = 0;

        const playableBaseDir = REPO_BASE_DIR + scene.repository.name;
        const playableHash = generateHash(scene.repository);

        let sceneBaseDir = playableBaseDir + '/';

        if (sceneBuildConfig.base_path) sceneBaseDir += sceneBuildConfig.base_path;
        else if (scene.build.scenes_base_path)
            sceneBaseDir += scene.build.scenes_base_path + '/' + sceneBuildConfig.id;

        await loadFiles({
            files: sceneBuildConfig.tool_files,
            baseURL: sceneBaseDir,
            hash: playableHash,
            onProgress: progress,
            onLoaded: fileLoaded,
            applyToContext: true
        });

        const sceneFiles = await loadFiles({
            files: sceneBuildConfig.build_files,
            baseURL: sceneBaseDir,
            hash: playableHash,
            onProgress: progress,
            onLoaded: fileLoaded
        });

        cache[cacheKey] = {
            onbuild: Playable.onbuild,
            config: Playable.config,
            ad_dynamic: Playable.ad_dynamic,
            translations: Playable.translations,
            context: Playable.context,
            sources: sceneFiles,
            // source_min: {}
        };

        Playable.emit('cache:ready', cacheKey);

        resetContext();

        utils.popup('hide');
    }
}

function generateHash(repo) {
    let hash = null;

    if (repo && repo.commit) hash = repo.commit.id.substr(0, 7);
    else hash = new Date(repo.updated_at).getTime();

    return hash;
}

const Loader = {
    playable: loadPlayable,
    // scene: loadScene,
    setSDKRepository
};

export default Loader;
