import { useContext, useState, useEffect } from 'react';
import { creativeContext } from '../../../services/contexts';
import { Input, UserBadge, RestrictAccess } from '../../../components';
import { TagInput } from 'rsuite';
import { date } from '@gpg-web/utils';

const EditCreative = (props) => {
    const { selectedCreative, handleUpdateCreative } = useContext(creativeContext);

    const [data, setData] = useState({ name: '', tags: '' });

    useEffect(() => {
        if (selectedCreative)
            setData({
                name: selectedCreative.name,
                tags: selectedCreative.tags
            });
    }, [selectedCreative]);

    const handleChange = (e, a) => {
        console.log(e, a);
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setData({ ...data, [name]: value });
    };

    const saveChanges = () => {
        handleUpdateCreative({
            name: data.name,
            tags: data.tags
        });
    };

    return (
        <div className="px-2 my-3">
            <div className="fw-light">
                <div className="small mb-1">Last update: {date(selectedCreative.updated_at)}</div>
                <div>
                    by <UserBadge email={selectedCreative.updated_by} />
                </div>
            </div>

            <div className="mt-3 fw-light">
                <div className="small mb-1">Created: {date(selectedCreative.created_at)}</div>
                <div>
                    by <UserBadge email={selectedCreative.created_by} />
                </div>
            </div>

            <RestrictAccess requiredLvl={1}>
                <hr />
                <Input
                    name="name"
                    title={
                        <>
                            <i className="fas me-1 fa-sm fa-edit" />
                            Name
                        </>
                    }
                    value={data.name}
                    onChange={handleChange}
                />
                <label className="mb-2">
                    <i className="fas me-1 fa-sm fa-edit" />
                    Tags
                </label>
                <TagInput value={data.tags} onChange={(value) => setData({ ...data, tags: value })} block />
                {!selectedCreative.lock && (
                    <div className="text-end mt-3">
                        <button onClick={saveChanges} className="btn btn-primary btn-sm rounded-pill px-3">
                            Save
                        </button>
                    </div>
                )}
            </RestrictAccess>
        </div>
    );
};

export default EditCreative;
