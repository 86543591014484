const REPO_BASE_DIR = '/repositories/';

const SDK_SCRIPTS = ['build/sdk.js'];

const SDK_MODULES = [
    // 'build/banner.js',
    'build/analytics.js',
    'build/analytics_fb.js',
    'build/analytics_google.js',
    'build/ad_dynamic.js',
    'build/splash.js',
    'build/nuc.js',
    // 'build/ctain.js',
    // 'build/sticker.js',
    'build/google.js',
    'build/defs.js'
    // 'build/stw.js',
    // 'build/ctaflying.js',
    // 'build/ctaendcard.js',
    // 'build/particles.js',
    // 'build/particlepatterns/bills.js',
    // 'build/particlepatterns/stars.js',
    // 'build/particlepatterns/confetti.js',
];

export { REPO_BASE_DIR, SDK_SCRIPTS, SDK_MODULES };
