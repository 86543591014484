/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

function el(id) {
    return document.getElementById(id);
}

// function loadFileFromCDN(url, cb, crossOrigin)
// {
//     if (crossOrigin === void 0) crossOrigin = true;

//     var xmlhttp = new XMLHttpRequest();

//     xmlhttp.onreadystatechange = function()
//     {

//         if (xmlhttp.readyState == 4)
//         {
//             if (xmlhttp.status == 200)
//             {
//                 cb(xmlhttp.responseText);
//             }
//             else if (xmlhttp.status == 404)
//             {
//                 console.log("File not found: " + url)
//                 cb(null);
//             }

//             xmlhttp.onreadystatechange = xmlhttp.onload = null;

//         }
//     };

//     xmlhttp.open("GET", url, crossOrigin);
//     xmlhttp.send();
// }

// function loadBLOBFromCDN(url, cb, crossOrigin)
// {
//     if (crossOrigin === void 0) crossOrigin = true;

//     var xmlhttp = new XMLHttpRequest();

//     xmlhttp.responseType = "blob";

//     xmlhttp.onreadystatechange = function()
//     {

//         if (xmlhttp.readyState == 4)
//         {
//             if (xmlhttp.status == 200)
//             {
//                 cb(xmlhttp.response);
//             }
//             else if (xmlhttp.status == 404)
//             {
//                 console.log("File not found: " + url)
//                 cb(null);
//             }

//             xmlhttp.onreadystatechange = xmlhttp.onload = null;

//         }
//     };

//     xmlhttp.open("GET", url, crossOrigin);
//     xmlhttp.send();
// }

// function loadJsonSequence(jsonToLoad, cb, crossOrigin)
// {
//     var jsons = {}

//     if (typeof jsonToLoad == "string")
//     {
//         jsonToLoad = [jsonToLoad];
//     }
//     else if (!Array.isArray(jsonToLoad))
//     {
//         jsonToLoad = [jsonToLoad];
//     }

//     if (crossOrigin === void 0) { crossOrigin = true; }

//     if (!jsonToLoad || jsonToLoad.length == 0) {
//       cb(jsons)
//       return;
//     }

//     var src_url_to_load;
//     var key_json;
//     var counter = 0;

//     function loadNextScript() {
//         var done = false;

//         var xmlhttp = new XMLHttpRequest();
//         var current_data = jsonToLoad.shift()

//         if (typeof current_data == "object") {
//             src_url_to_load = current_data.src
//             if (!current_data.key) {
//                 key_json = counter
//                 counter += 1
//             } else
//                 key_json = current_data.key
//         } else {
//             key_json = counter
//             counter += 1
//             src_url_to_load = current_data
//         }

//         xmlhttp.onreadystatechange = function () {
//             if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
//                 var jsonContent = xmlhttp.responseText;
//                 if (src_url_to_load.indexOf(".json") > -1) {
//                     jsonContent = JSON.parse(jsonContent)
//                     jsons[key_json] = jsonContent
//                 }
//                 scriptLoaded();
//             }
//         };

//         xmlhttp.open("GET", src_url_to_load, crossOrigin);

//         xmlhttp.send();

//         function scriptLoaded() {
//             // check done variable to make sure we aren't getting notified more than once on the same script
//             if (!done) {
//                 xmlhttp.onreadystatechange = xmlhttp.onload = null;   // kill memory leak in IE
//                 done = true;
//                 if (jsonToLoad.length != 0) {
//                     loadNextScript();
//                 } else {
//                     cb(jsons)
//                 }
//             }
//         }
//     }
//     loadNextScript();
// }

function readFile(file, readAs) {
    return new Promise((resolve, reject) => {
        if (typeof file === 'string') {
            file = el(file);
        }

        if (file instanceof HTMLElement) {
            file = file.files;
        }

        if (file instanceof FileList) {
            file = file[0];
        }

        if (!file) {
            return resolve(null);
        }

        let options = {};

        if (file instanceof File) {
            options = {
                name: file.name,
                format: file.name.substr(file.name.lastIndexOf('.') + 1),
                title: file.name.substr(0, file.name.lastIndexOf('.')),
                mime: file.type
            };
        } else {
            options = {
                mime: file.type
            };
        }

        var reader = new FileReader();
        reader.onload = function () {
            resolve({
                result: reader.result,
                options: options
            });
        };

        reader.onerror = function () {
            reject(reader.error);
        };

        if (readAs === 'text') {
            reader.readAsText(file);
        } else {
            reader.readAsDataURL(file);
        }
    });
}

function downloadFileFromXML(url) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        xhr.responseType = 'arraybuffer';
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.anonymous = true;

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                resolve(xhr.response);
            }
        };

        xhr.onerror = function () {
            reject();
        };

        xhr.send(null);
    });
}

const Loader = {
    // json: loadJsonSequence,

    xml: downloadFileFromXML,

    // load: loadFileFromCDN,

    // blob: loadBLOBFromCDN,

    file: readFile
};

export default Loader;
