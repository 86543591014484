/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import DEFAULT_BODY from './DEFAULT_BODY';
import DEFAULT_HTML from './DEFAULT_HTML';
import configurator from './configurator';
import env from '../Env';

// const endStateCharacter = '\n';

function takeSource(obj, key) {
    return obj.find((e) => e.id === key);
}

const openTags = {
    js: '<script>',
    css: '<style>'
};

const closeTags = {
    js: '</script>',
    css: '</style>'
};

function openTag(type) {
    return openTags[type];
}
function closeTag(type) {
    return closeTags[type];
}

function buildHTML() {
    const { CURRENT_SDK, CURRENT_PLAYABLE, CURRENT_SCENE } = env;

    let currentType = 'js';

    // let source = DEFAULT_HTML;
    let SOURCE = openTag(currentType);

    function addContent(src) {
        if (!src) return;

        if (src.type !== currentType) {
            SOURCE += closeTag(currentType);
            currentType = src.type;
            SOURCE += openTag(currentType);
        }

        SOURCE += src.content;
    }

    // let styles = '';

    // for (let i in CURRENT_PLAYABLE.style) {
    //     styles += '<style>' + CURRENT_PLAYABLE.style[i] + '</style>';
    // }

    // source += styles;

    // let script = '';

    if (configurator.scriptHeader.length > 0) {
        for (let i in configurator.scriptHeader) {
            addContent(configurator.scriptHeader[i]);
        }
    }

    /**
     * Build SDK modules
     */
    let sdk_modules_list = configurator.sdk_modules;
    sdk_modules_list = utils.unique(sdk_modules_list);

    if (
        configurator.template.priorityModules &&
        Array.isArray(configurator.template.priorityModules) &&
        configurator.template.priorityModules.length > 0
    ) {
        configurator.template.priorityModules = utils.unique(configurator.template.priorityModules);

        for (let i in configurator.template.priorityModules) {
            let templateModules_src = configurator.template.priorityModules[i];

            addContent(takeSource(CURRENT_PLAYABLE.modules, templateModules_src));
        }
    }

    if (configurator.template.sdk !== false) {
        if (configurator.local) {
            const defs = takeSource(CURRENT_SDK.modules, 'defs');

            if (!defs) {
                return utils.hintError('The "definition" sdk module not found');
            }

            addContent(defs);
        }

        for (let i in CURRENT_SDK.sources) {
            addContent(CURRENT_SDK.sources[i]);
        }

        for (let i = 0; i < sdk_modules_list.length; i++) {
            let key = sdk_modules_list[i];

            if (configurator.template[key] !== false) {
                addContent(takeSource(CURRENT_SDK.modules, key));
            }
        }

        if (!configurator.local) {
            const defs = takeSource(CURRENT_SDK.modules, 'defs');

            if (!defs) {
                return utils.hintError('The "definition" sdk module not found');
            }

            addContent(defs);
        }
    }

    /**
     * END: Build SDK modules
     */

    if (
        configurator.template.modules &&
        Array.isArray(configurator.template.modules) &&
        configurator.template.modules.length > 0
    ) {
        configurator.template.modules = utils.unique(configurator.template.modules);

        for (let i in configurator.template.modules) {
            let templateModules_src = configurator.template.modules[i];

            addContent(takeSource(CURRENT_PLAYABLE.modules, templateModules_src));
        }
    } else if (configurator.template.modules === 'all') {
        for (let i in CURRENT_PLAYABLE.modules) {
            addContent(CURRENT_PLAYABLE.modules[i]);
        }
    }

    for (let i in CURRENT_PLAYABLE.sources) {
        addContent(CURRENT_PLAYABLE.sources[i]);
    }

    if (CURRENT_SCENE && CURRENT_SCENE.sources) {
        for (let i in CURRENT_SCENE.sources) {
            addContent(CURRENT_SCENE.sources[i]);
        }
    }

    if (configurator.scriptFooter.length > 0) {
        for (let i in configurator.scriptFooter) {
            addContent(configurator.scriptFooter[i]);
        }
    }

    addContent({
        id: '_gp_init',
        type: 'js',
        content: 'gp.init();'
    });

    SOURCE += closeTag(currentType);

    // source += '<script type="text/javascript">' + script + '</script>';

    // if (configurator.head && configurator.head.end && configurator.head.end.length > 0)
    // {
    //     source += configurator.head.end.join("");
    // }

    for (let key in configurator.replacements) {
        SOURCE = SOURCE.replaceAll(key, configurator.replacements[key]);
    }

    for (let key in configurator.replacements) {
        // eslint-disable-next-line
        SOURCE = SOURCE.replace(key[0] === '/' ? eval(key) : key, configurator.replacements[key]);
    }

    /**
     * Required for toutiao, pangle and mytarget network exchanges
     * These networks does not allow mraid and this keyword in the code.
     */
    if (
        configurator.network === 'toutiao' ||
        configurator.network === 'pangle' ||
        configurator.network === 'mytarget' ||
        configurator.network === 'moloco'
    ) {
        SOURCE = SOURCE.replaceAll('mraid', 'turbina');
        SOURCE = SOURCE.replaceAll('Mraid', 'Dodo');
    }

    let BODY = configurator.template.body || DEFAULT_BODY;

    let html =
        '<!DOCTYPE html><html lang="en"><head>' +
        configurator.head.begin.join('') +
        '\n' +
        DEFAULT_HTML +
        '\n' +
        configurator.head.end.join('') +
        '</head><body>' +
        configurator.body.begin.join('') +
        SOURCE +
        BODY +
        configurator.body.end.join('') +
        '</body></html>';

    return html;
}

export default buildHTML;
