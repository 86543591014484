import { useState, useEffect, useContext } from 'react';
import { store, utils } from '@gpg-web/utils';
import {
    Form,
    Button,
    Modal,
    InputGroup,
    Grid,
    Row,
    Col,
    Uploader,
    Loader,
    useToaster,
    Message
} from 'rsuite';
import { storeInfo, addApp, updateApp, appConext } from '../../services/app';

let timer;

const defaultValue = {
    app_store_url: '',
    google_play_url: '',
    name: '',
    picture: ''
};

const EditAppModal = (props) => {
    const { open, onClose, editing } = props;
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const [formValue, setFormValue] = useState(defaultValue);
    const { app, setApp } = useContext(appConext);

    useEffect(() => {
        if (!open) return;
        setFileInfo(null);
        if (editing)
            setFormValue({
                _id: editing._id,
                app_store_url: editing.app_store_url,
                google_play_url: editing.google_play_url,
                name: editing.name,
                picture: editing.picture
            });
        else setFormValue(defaultValue);
    }, [open, editing]);

    const toaster = useToaster();

    const hasData = !!formValue.name;

    useEffect(() => {
        if (hasData) return;
        if (!formValue.app_store_url) return;
        const storeId = store.appId(formValue.app_store_url);
        if (!storeId) return;

        clearTimeout(timer);
        timer = setTimeout(() => {
            setLoading(true);

            const params = {
                app_store_id: storeId
            };

            storeInfo(params)
                .then((res) => {
                    setFormValue((value) => {
                        return { ...value, name: res.title, picture: res.icon };
                    });
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }, 100);

        return () => clearTimeout(timer);
    }, [formValue.app_store_url, hasData]);

    useEffect(() => {
        if (hasData) return;
        if (!formValue.google_play_url) return;
        const storeId = store.appId(formValue.google_play_url);
        if (!storeId) return;

        clearTimeout(timer);
        timer = setTimeout(() => {
            setLoading(true);

            const params = {
                google_play_id: storeId
            };

            storeInfo(params)
                .then((res) => {
                    setFormValue((value) => {
                        return { ...value, name: res.title, picture: res.icon };
                    });
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }, 100);

        return () => clearTimeout(timer);
    }, [formValue.google_play_url, hasData]);

    function handleClose(argument) {
        setLoading(false);
        onClose();
    }

    function handleSave() {
        setLoading(true);

        const fn = editing ? updateApp : addApp;
        fn(formValue)
            .then(() => {
                if (app && app._id === formValue._id) {
                    Object.assign(app, formValue);
                    setApp(app);
                }
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                toaster.push(<Message type="error">{err}</Message>);
            });
    }

    return (
        <>
            <Modal open={open} backdrop="static" onClose={handleClose} size="sm" overflow={false}>
                <Modal.Header>
                    <Modal.Title>{editing ? 'Edit' : 'New'} App</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid disabled={loading} onChange={setFormValue} formValue={formValue}>
                        <Form.Group controlId="app_store_url_id">
                            <Form.ControlLabel>Apple URL</Form.ControlLabel>
                            <InputGroup inside>
                                <InputGroup.Addon>
                                    <i className="fab fa-app-store-ios text-gray-500" />
                                </InputGroup.Addon>
                                <Form.Control name="app_store_url" />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="google_play_url_id">
                            <Form.ControlLabel>Google Play URL</Form.ControlLabel>
                            <InputGroup inside>
                                <InputGroup.Addon>
                                    <i className="fab fa-google-play text-gray-500" />
                                </InputGroup.Addon>
                                <Form.Control name="google_play_url" />
                            </InputGroup>
                        </Form.Group>

                        <Grid fluid>
                            <Row className="show-grid">
                                <Col sm={8}>
                                    <Uploader
                                        fileListVisible={false}
                                        listType="picture"
                                        action="/api/app/upload-picture"
                                        disabled={loading}
                                        onUpload={(file) => {
                                            setUploading(true);
                                            utils.load.file(file.blobFile).then((value) => {
                                                setFileInfo(value.result);
                                            });
                                        }}
                                        onSuccess={(response, file) => {
                                            setUploading(false);
                                            setFormValue({ ...formValue, picture: response.picture });
                                        }}
                                        onError={() => {
                                            toaster.push(
                                                <Message type="error">Picture upload failed</Message>
                                            );
                                        }}
                                    >
                                        <button style={{ width: 150, height: 150 }}>
                                            {uploading && <Loader backdrop center />}
                                            {formValue.picture || fileInfo ? (
                                                <img
                                                    alt="App icon"
                                                    src={fileInfo || utils.staticUrl(formValue.picture)}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            ) : (
                                                <i className="fas fa-gamepad fs-1" />
                                            )}
                                        </button>
                                    </Uploader>
                                </Col>
                                <Col sm={16}>
                                    <Form.Group controlId="name_id">
                                        <Form.ControlLabel>App Name</Form.ControlLabel>
                                        <Form.Control name="name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                    <Button disabled={loading} loading={loading} onClick={handleSave} appearance="primary">
                        {editing ? 'Save' : 'Create'} App
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { EditAppModal };
