import { useEffect, useState } from 'react';
import PlayableDebugger from '../../../playground/Debugger';

const DebuggerIcon = () => {
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        const errorCb = (error) => {
            setErrors(error);
        };

        PlayableDebugger.on('error', errorCb);

        return () => {
            PlayableDebugger.off('error', errorCb);
        };
    }, []);

    return (
        <span className={errors ? 'text-danger' : ''}>
            <i className="fas fa-sm fa-code" />
            {errors ? (
                <i
                    style={{ marginLeft: '-12px', position: 'relative', top: '-10px', left: '10px' }}
                    className="fa fa-sm fa-exclamation-circle"
                />
            ) : null}
        </span>
    );
};

export default DebuggerIcon;
