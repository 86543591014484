/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import { useContext } from 'react';
import { layoutContext } from '../services/contexts';

const Footer = (props) => {
    const { footer } = useContext(layoutContext);

    if (!footer) return null;

    return (
        <footer className="sticky-footer">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright &copy; GREEN PANDA GAMES 2023</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
