import { useEffect, useState } from 'react';
import DEBUGGER_CONSTATS from '../../../playground/Debugger/DEBUGGER_CONSTATS';
import PlayableDebugger from '../../../playground/Debugger';

const OptionCheckbox = (prop) => {
    return (
        <>
            <label className="list-group-item">
                <div>
                    <input
                        className="form-check-input me-2"
                        type="checkbox"
                        name={prop.name}
                        checked={prop.value}
                        onChange={prop.handler}
                    />
                    {prop.title}
                </div>
                <div className="small text-muted mt-2">{prop.description}</div>
            </label>
        </>
    );
};

const DeveloperOptions = () => {
    const [devOpt, setDevOpt] = useState(PlayableDebugger.getDevOptions());

    const handler = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        setDevOpt((f) => ({ ...f, [name]: value }));
    };

    useEffect(() => {
        PlayableDebugger.setDevOptions(devOpt);
    }, [devOpt]);

    return (
        <>
            <h5>Debugger Options</h5>

            <div className="list-group list-group-flush" id="dev_opt">
                {DEBUGGER_CONSTATS.DEVELOPER_OPTIONS.map((val, i) => {
                    return (
                        <OptionCheckbox
                            key={i}
                            name={val.name}
                            value={devOpt[val.name]}
                            title={val.title}
                            description={val.description}
                            handler={handler}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default DeveloperOptions;
