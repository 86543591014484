import { obj } from '@gpg-web/utils';

function render(canvas, src, width, height, rotation) {
    const ctx = canvas.getContext('2d');

    if (rotation !== 0) {
        let _rad = (rotation * Math.PI) / 180;

        const _height = height * Math.abs(Math.cos(_rad)) + width * Math.abs(Math.sin(_rad));
        const _width = width * Math.abs(Math.cos(_rad)) + height * Math.abs(Math.sin(_rad));

        canvas.width = _width;
        canvas.height = _height;

        ctx.save();

        const _x = _width / 2;
        const _y = _height / 2;

        ctx.translate(_x, _y);
        ctx.rotate(_rad);
        ctx.drawImage(src, -width / 2, -height / 2, width, height);
        ctx.restore();
    } else {
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(src, 0, 0, width, height);
    }
}

function snapshotImage(canvas, mimeType, quality) {
    if (mimeType === 'image/png') {
        return canvas.toDataURL();
    } else {
        return canvas.toDataURL(mimeType, quality);
    }
}

function _screenshot(el, options, cb) {
    options = options || {};

    let quality = obj.getValue(options, 'quality', 1);

    let mimeType = obj.getValue(options, 'type', obj.getValue(options, 'format', 'image/png'));
    if (!mimeType.startsWith('image/')) mimeType = 'image/' + mimeType;

    let scale = obj.getValue(options, 'scale', 1);

    let rotation = obj.getValue(options, 'rotation', 0);

    let width = el.videoWidth * scale;
    let height = el.videoHeight * scale;

    if (options.scale === undefined && (options.width !== undefined || options.height !== undefined)) {
        if (options.width !== undefined && options.height !== undefined) {
            width = options.width;
            height = options.height;
        } else {
            const _aspect = el.videoWidth / el.videoHeight;

            if (options.width !== undefined) {
                width = options.width;
                height = width / _aspect;
            } else {
                height = options.height;
                width = height * _aspect;
            }
        }
    }

    const canvas = document.createElement('canvas');

    if (options.timestamps && options.timestamps.length > 0 && el instanceof HTMLVideoElement) {
        const timestamps = options.timestamps.slice();
        const result = [];

        function done() {
            if (result.length === 1) cb(result[0]);
            else cb(result);
        }

        function nextTimestamp() {
            if (timestamps.length <= 0) return done();

            const _time = timestamps.shift();

            if (typeof _time === 'string') {
                if (_time.slice(_time.length - 1) === '%') {
                    let _perc = _time.substr(0, _time.length - 1);
                    _perc = Number(_perc) / 100;
                    _perc = Math.max(0, Math.min(_perc, 1));
                    el.currentTime = el.duration * _perc;
                }
            } else if (typeof _time === 'number') {
                el.currentTime = _time;
            }
        }

        el.ontimeupdate = function () {
            render(canvas, el, width, height, rotation);
            const snapshot = snapshotImage(canvas, mimeType, quality);
            result.push(snapshot);

            nextTimestamp();
        };

        nextTimestamp();
    } else {
        render(canvas, el, width, height, rotation);
        const snapshot = snapshotImage(canvas, mimeType, quality);
        cb(snapshot);
    }
}

function screenshot(video, options) {
    return new Promise((resolve, reject) => {
        if (video instanceof File) {
            const file = video;

            video = document.createElement('video');

            video.src = window.URL.createObjectURL(file);
        }

        if (video instanceof HTMLVideoElement) {
            video.muted = true;
            video.oncanplaythrough = function () {
                video.oncanplaythrough = null;

                _screenshot(video, options, resolve);
            };

            video.load();
        } else {
            resolve(
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAJ9JREFUeNq01ssOwyAMRFG46v//Mt1ESmgh+DFmE2GPOBARKb2NVjo+17PXLD8a1+pl5+A+wSgFygymWYHBb0FtsKhJDdZlncG2IzJ4ayoMDv20wTmSMzClEgbWYNTAkQ0Z+OJ+A/eWnAaR9+oxCF4Os0H8htsMUp+pwcgBBiMNnAwF8GqIgL2hAzaGFFgZauDPKABmowZ4GL369/0rwACp2yA/ttmvsQAAAABJRU5ErkJggg=='
            );
        }
    });
}

export default screenshot;
