/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import Select from 'react-select';
import { Countries } from '../../consts';

const options = Countries.map((e) => {
    return { value: e.code, label: e.name };
});

const CountriesSelect = (props) => {
    return (
        <Select
            defaultValue={options.find((e) => e.value === props.defaultValue)}
            name="location"
            isDisabled={props.disabled}
            onChange={(e) => {
                props.onChange && props.onChange(e.value);
            }}
            options={options}
            placeholder="Choose Country"
            className="basic-single"
            classNamePrefix="select"
        />
    );
};

export { CountriesSelect };
