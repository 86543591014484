import { useMemo, useEffect } from 'react';
import { Countries, Languages } from '../../../consts';
import { ms, str, utils } from '@gpg-web/utils';
import {
    CheckPicker,
    IconButton,
    Stack,
    DateRangePicker,
    SelectPicker,
    ButtonGroup,
    InputPicker
} from 'rsuite';

const { allowedRange } = DateRangePicker;

const today = new Date();
const minDate = new Date(Date.now() - ms('1y'));
const yesterday = new Date(Date.now() - ms('1d'));
const last7Days = new Date(Date.now() - ms('7d'));
const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);

const predefinedRanges = [
    {
        label: 'Today',
        value: [today, today],
        placement: 'left'
    },
    {
        label: 'Yesterday',
        value: [yesterday, yesterday],
        placement: 'left'
    },
    {
        label: 'Last 7 days',
        value: [last7Days, today],
        placement: 'left'
    },
    {
        label: 'Last 30 days',
        value: [lastMonth, today],
        placement: 'left'
    }
];

const TIME_SCALE = [
    { label: 'HOUR', value: 'HOUR' },
    { label: 'DAY', value: 'DAY' }
];

const FILTERS = [
    { label: 'Apps', value: 'app' },
    { label: 'Ad Network', value: 'network' },
    { label: 'OS', value: 'os' },
    { label: 'Country', value: 'country' },
    { label: 'Device Language', value: 'lang' },
    { label: 'Orientation', value: 'orientation' }
];

const OS = [
    { label: 'iOS', value: 'iOS' },
    { label: 'Android', value: 'Android' }
];

const ORIENTATIONS = [
    { label: 'Portrait', value: 'p' },
    { label: 'Landscape', value: 'l' }
];

const NETWORKS = [
    { label: 'Applovin', value: 'applovin' },
    { label: 'IronSource', value: 'ironsource' },
    { label: 'Unity', value: 'unity' }
];

const LANGUAGES = Languages.map((e) => ({ label: str.trim(e.name, 20), value: e.code }));

const COUNTRIES = Countries.map((e) => ({ label: str.trim(e.name, 20), value: e.code }));

function renderValue(vals, name) {
    return vals.length === 1 ? vals[0].label : name + ' (' + vals.length + ')';
}

const PerformanceFilter = (props) => {
    const { dateRange, setDateRange, filters, setFilters, entities, timeScale, setTimeScale } = props;

    let days = Math.round((dateRange[1] - dateRange[0]) / ms('1d'));

    useEffect(() => {
        if (days > 10) setTimeScale('DAY');
        else if (days < 4) setTimeScale('HOUR');
    }, [days, setTimeScale]);

    function addFiler(filterId) {
        filters.push({ id: filterId, values: [] });
        setFilters(filters.slice());
    }

    function removeFiler(filterId) {
        utils.remove(filters, (e) => e.id === filterId);
        setFilters(filters.slice());
    }

    const filterList = useMemo(() => {
        const list = FILTERS.filter((F) => !filters.find((f) => f.id === F.value));

        let appFilter = filters.find((e) => e.id === 'app');
        if (appFilter && appFilter.values.length === 1) {
            !filters.find((f) => f.id === 'creative') &&
                list.splice(0, 0, { label: 'Creatives', value: 'creative' });
            !filters.find((f) => f.id === 'scene') &&
                list.splice(0, 0, { label: 'Scenarios', value: 'scene' });
        }

        return list;
    }, [filters]);

    return (
        <Stack wrap spacing={10} justifyContent="flex-end" className="mt-2 mb-4">
            <SelectPicker
                data={filterList}
                value=""
                onChange={addFiler}
                style={{ width: 150 }}
                cleanable={false}
                size="sm"
                placeholder={
                    <>
                        <i className="fas fa-plus me-1" /> Add Filter
                    </>
                }
            />

            {entities &&
                filters.map((filter) => {
                    const id = filter.id;

                    let appFilter = filters.find((e) => e.id === 'app');
                    if (appFilter && appFilter.values.length === 1) appFilter = appFilter.values[0];
                    else appFilter = null;

                    return (
                        <ButtonGroup key={id} justified size="sm" className="shadow-sm">
                            {id === 'app' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Apps')}
                                    placeholder="App"
                                    data={entities.apps}
                                    style={{ width: 200 }}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            utils.remove(filters, (e) => e.id === 'app');
                                            utils.remove(filters, (e) => e.id === 'creative');
                                            utils.remove(filters, (e) => e.id === 'scene');
                                        } else {
                                            filter.values = val;
                                            if (val.length !== 1) {
                                                utils.remove(filters, (e) => e.id === 'creative');
                                                utils.remove(filters, (e) => e.id === 'scene');
                                            }
                                        }

                                        setFilters(filters.slice());
                                    }}
                                />
                            )}

                            {id === 'creative' && appFilter && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Creatives')}
                                    placeholder="Creative"
                                    data={entities.creatives.filter((e) => e.app === appFilter)}
                                    style={{ width: 200 }}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                    virtualized
                                />
                            )}

                            {id === 'scene' && appFilter && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Scenarios')}
                                    placeholder="Scenario"
                                    data={entities.scenes.filter((e) => e.app === appFilter)}
                                    style={{ width: 200 }}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                    virtualized
                                />
                            )}

                            {id === 'country' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Countries')}
                                    placeholder="Country"
                                    data={COUNTRIES}
                                    style={{ width: 200 }}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                    virtualized
                                />
                            )}

                            {id === 'lang' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Languages')}
                                    placeholder="Language"
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                    name="lang"
                                    data={LANGUAGES}
                                    style={{ width: 200 }}
                                    virtualized
                                />
                            )}

                            {id === 'orientation' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Orientations')}
                                    placeholder="Orientation"
                                    data={ORIENTATIONS}
                                    style={{ width: 150 }}
                                    searchable={false}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                />
                            )}

                            {id === 'network' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'Networks')}
                                    placeholder="Network"
                                    data={NETWORKS}
                                    style={{ width: 150 }}
                                    searchable={false}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                />
                            )}

                            {id === 'os' && (
                                <CheckPicker
                                    renderValue={(a, b) => renderValue(b, 'OS')}
                                    placeholder="OS"
                                    data={OS}
                                    style={{ width: 150 }}
                                    searchable={false}
                                    value={filter.values}
                                    onChange={(val, e) => {
                                        if (e.type === 'click') {
                                            removeFiler(id);
                                        } else {
                                            filter.values = val;
                                            setFilters(filters.slice());
                                        }
                                    }}
                                />
                            )}

                            {filter.values.length === 0 && (
                                <IconButton
                                    onClick={() => removeFiler(id)}
                                    icon={<i className="fas fa-times" />}
                                />
                            )}
                        </ButtonGroup>
                    );
                })}

            <DateRangePicker
                value={dateRange}
                size="sm"
                cleanable={false}
                onChange={setDateRange}
                ranges={predefinedRanges}
                preventOverflow={true}
                placement="bottomEnd"
                disabledDate={allowedRange(minDate, today)}
            />

            <InputPicker
                value={timeScale}
                onChange={setTimeScale}
                data={TIME_SCALE}
                searchable={false}
                style={{ width: 130 }}
                disabled={days > 10 || days < 3}
                cleanable={false}
                size="sm"
            />
        </Stack>
    );
};

export { PerformanceFilter, ORIENTATIONS };
