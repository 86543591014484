import React from 'react';
import { getMember } from '../services/organization';

const Item = (props) => (
    <div className="mb-1">
        <span className="fw-bold">{props.title}:</span> {props.value}
    </div>
);

const VersionLabel = (props) => {
    if (props.version === 'draft')
        return (
            <span className={props.styled === undefined ? 'fw-bold text-primary' : props.styled}>
                Draft version
            </span>
        );

    return 'Version #' + props.version;
};

const UserBadge = (props) => {
    const email = props.email;

    const member = getMember(email);

    return (
        <span
            onClick={() => props.onClick && props.onClick(member)}
            role={props.onClick && 'button'}
            className="badge rounded-pill text-gray-800 bg-white border-bottom border-3"
        >
            <img className="icon-5 rounded-circle me-2" alt="Member avatar" src={member.picture} />
            {member.name}
        </span>
    );
};

export { Item, VersionLabel, UserBadge };
