import React from 'react';

import Logout from './Logout';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';
import PromtModal from './PromtModal';
import Popup from './Popup';
import QrPreview from './QrPreview';

const Modals = (props) => {
    return (
        <>
            <Logout />
            <AlertModal />
            <ConfirmModal />
            <PromtModal />
            <QrPreview />
            <Popup />
        </>
    );
};

export default Modals;
