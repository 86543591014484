import configer from 'configer.js';

const DYNAMIC_AUTOMATION_CONFIG = {
    min_imp: {
        type: 'number',
        min: 10,
        value: 5000,
        title: 'Min impressions to activate notifications and automation',
        description:
            'Setup this value to tell when configuration is able for automation and UA notification.'
    },

    // poor_kpi: {
    //     type: 'dynamic-kpi',
    //     min: 10,
    //     value: {
    //         indicator: 'cpi',
    //         sign: '>',
    //         val: 1
    //     },
    //     title: 'Poor performing KPI',
    //     description: 'Configured KPIs will be used as Poor Performing Threshold',
    //     onChange: changed
    // },

    auto_p: {
        title: 'Automating priorities assignments',
        description: 'Enable this options to enable automating priorities assignments',
        type: 'checkbox',
        value: false
    }
};

const DEFAULT_DYNAMIC_AUTOMATION_CONFIG = configer.parse(DYNAMIC_AUTOMATION_CONFIG);

function enableDisableModule(type, is) {
    var mdl = MODULES_DYNAMIC_CONFIG[type];

    if (mdl.block && mdl.block.show) {
        if (is) {
            mdl.hidden = false;
            mdl.ignore = false;
        } else {
            mdl.hidden = true;
            mdl.ignore = true;
        }

        mdl.block.apply();
    }
}

var MODULES_DYNAMIC_CONFIG = {
    banner_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'Banner',
        onChange: function (is) {
            enableDisableModule('banner', is);
        }
    },
    banner: {
        type: 'block',
        title: 'Banner Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {
            text: {
                type: 'text',
                title: 'Text',
                value: ''
            },
            background: {
                type: 'color',
                title: 'Background color',
                value: '#424242'
            },
            color: {
                type: 'color',
                title: 'Text color',
                value: '#f4f4f4'
            }
        }
    },
    ctain_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'In Game CTA',
        onChange: function (is) {
            enableDisableModule('ctain', is);
        }
    },
    ctain: {
        type: 'block',
        title: 'In Game CTA Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {
            text: {
                type: 'text',
                value: 'Install',
                title: 'Button text'
            },
            color: {
                type: 'color',
                value: '#F808F7',
                title: 'Button color'
            }
        }
    },
    ctaflying_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'Flying CTA',
        onChange: function (is) {
            enableDisableModule('ctaflying', is);
        }
    },
    ctaflying: {
        type: 'block',
        title: 'Flying CTA Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {
            enabled: {
                type: 'checkbox',
                title: 'Enable',
                value: true
            },

            delay: {
                type: 'range',
                title: 'Delay to show bonus:',
                min: 1,
                max: 30,
                step: 0.5,
                value: 10
            },

            autocta: {
                type: 'checkbox',
                title: 'Auto direct to store when bonus ends?',
                value: false
            }
        }
    },
    ctaendcard_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'Endcard CTA',
        onChange: function (is) {
            enableDisableModule('ctaendcard', is);
        }
    },
    ctaendcard: {
        type: 'block',
        title: 'Endcard CTA Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {}
    },
    tutorial_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'Tutorial',
        onChange: function (is) {
            enableDisableModule('tutorial', is);
        }
    },
    tutorial: {
        type: 'block',
        title: 'Tutorial Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {
            enbld: {
                type: 'checkbox',
                value: false,
                title: 'Enable tutorial'
            },
            autoplay: {
                type: 'number',
                description: 'Delay before autoclick. Will trigger on each stage tutorial.',
                title: 'Tutorial Autoclick sec. (set 0 to disable autoclick)',
                min: 0,
                value: 4
            },
            delay: {
                type: 'number',
                description: 'Delay before first stage of tutorial.',
                title: 'Tutorial Start delay sec.',
                min: 0,
                value: 1
            },
            idle: {
                max: 25,
                min: 0,
                description: 'Delay before each idle tutorial stage of inactivity.',
                title: 'Tutorial Idle delay sec. (Set 0 to disable idle tutorial)',
                type: 'number',
                value: 3
            },
            opaque: {
                type: 'checkbox',
                title: 'Show opaque',
                value: true
            },
            hand: {
                type: 'checkbox',
                title: 'Show hand',
                value: true
            },
            text: {
                type: 'checkbox',
                title: 'Show text',
                value: true
            },
            hand_scale: {
                type: 'range',
                title: 'Hand Size',
                min: 0,
                max: 2,
                step: 0.001,
                snap_threshold: 0.05,
                snap: [1],
                value: 1
            },
            text_size: {
                type: 'range',
                title: 'Text Size',
                min: 0,
                max: 2,
                step: 0.001,
                snap_threshold: 0.05,
                snap: [1],
                value: 1
            },
            text_color: {
                type: 'color',
                title: 'Text Color',
                value: '#ffffff'
            }
        }
    },
    sticker_enabled: {
        type: 'checkbox',
        value: false,
        ignore: true,
        title: 'Sticker',
        onChange: function (is) {
            enableDisableModule('sticker', is);
        }
    },
    sticker: {
        type: 'block',
        title: 'Sticker Ad Dynamic',
        hidden: true,
        ignore: true,
        config: {
            enabled: {
                type: 'checkbox',
                value: false,
                title: 'Enable sticker'
            },
            scale: {
                type: 'range',
                title: 'Scale',
                min: 0,
                max: 3,
                value: 1,
                step: 0.001,
                snap_threshold: 0.05,
                snap: [1]
            },
            show_trg: {
                type: 'select',
                title: 'Show on trigger',
                value: 'finish',
                options: ['finish', 'render', 'tap'],
                labels: ['Finish', 'Start', 'First Tap']
            }
        }
    }
};

export { DEFAULT_DYNAMIC_AUTOMATION_CONFIG, DYNAMIC_AUTOMATION_CONFIG, MODULES_DYNAMIC_CONFIG };
