import React from 'react';
import Select from 'react-select';
import { TimezonesArray } from '../../consts';

const options = TimezonesArray.map((e) => {
    return { value: e, label: e };
});

const TimezoneSelect = (props) => {
    return (
        <Select
            defaultValue={options.find((e) => e.value === props.defaultValue)}
            name="timezone"
            isDisabled={props.disabled}
            onChange={(e) => {
                props.onChange && props.onChange(e.value);
            }}
            options={options}
            placeholder="Choose Timezone"
            className="basic-single"
            classNamePrefix="select"
        />
    );
};

export { TimezoneSelect };
