const Plugin = {};
const cache = {};

// {
// 	"sticker": {
// 		onbuild: Playable.onbuild,
//         config: Playable.config,
//         context: Playable.context,
//         source: playableFiles.js
// 	}
// }

function resetContext() {
    Plugin.onbuild = Plugin.config = Plugin.context = void 0;
}

function enable(pluginUid) {
    env.CURRENT_PLUGIN = cache[pluginUid];

    Plugin.onbuild = env.CURRENT_PLUGIN.onbuild;
    Plugin.config = env.CURRENT_PLUGIN.config;

    Plugin.context = {};
    Object.assign(Plugin.context, env.CURRENT_PLUGIN.context);
}

window.Plugin = Plugin;

const env = {
    CURRENT_PLUGIN: null,
    resetContext,
    cache,
    enable
};

export default env;
