import { useContext } from 'react';
import { str } from '@gpg-web/utils';
import { adDynamicContext } from '../../../services/contexts';

const Badge = (props) => (
    <span className="badge badge-pill bg-white text-dark ms-1 border-bottom">{props.children}</span>
);

const VariationItem = (props) => {
    const { onClick, isSelected, totalPrio, stats } = props;

    const { name, priority, enabled, hash, status } = props.data;

    const badges = [];

    if (!stats) {
        badges.push(<Badge key="notshown">Not shown yet</Badge>);
    } else {
        badges.push(<Badge key="shown">Shown: {stats.c}</Badge>);
        badges.push(<Badge key="ctr">CTR: {((stats.cta / stats.imp) * 100).toFixed(2)}%</Badge>);
    }

    return (
        <>
            <div
                type="button"
                className={
                    'list-group-item px-3 py-2 w-100 list-group-item-action ' + (isSelected ? ' active' : '')
                }
                onClick={onClick}
            >
                <div className="d-flex w-100 justify-content-between">
                    <div title={name}>
                        {!enabled && <i className="fas fa-circle text-danger fa-sm me-2" />}
                        {status === 0 && <i className="fas fa-crown text-warning fa-sm me-2" />}
                        {status === 1 && <i className="fas fa-circle text-warning fa-xs me-2" />}
                        {status > 1 && <i className="fas fa-frown-open text-danger fa-sm me-2" />}
                        {str.trim(name, 14)}
                    </div>
                    <div>{badges}</div>
                </div>
                <div className="mt-2 d-flex justify-content-between">
                    <div>
                        <small className="ms-1 text-muted fw-light">
                            {enabled ? Math.round((priority / totalPrio) * 100) : 0}% chance of showing{' '}
                        </small>
                    </div>
                    <div>
                        <small className="ms-1 text-muted fw-light">#{hash}</small>
                    </div>
                </div>
            </div>
        </>
    );
};

const VariationList = () => {
    const { dynamic, selectedVariation, addNewVariation, selectVariation } = useContext(adDynamicContext);

    const variationsList = dynamic.list;
    const stats = dynamic.stats;

    const totalPriority = variationsList.reduce((prev, item) => {
        return prev + (item.enabled ? item.priority : 0);
    }, 0);

    const variationStats = variationsList.map((variation) => {
        return stats.find((stat) => stat.id === variation.hash);
    });

    return (
        <div>
            <div className="d-flex justify-content-end align-items-center my-2">
                <div className="tab-title me-auto p-0">Variations</div>
                <button
                    onClick={addNewVariation}
                    className="btn btn-sm btn-outline-primary text-nowrap rounded-pill ms-1"
                >
                    + Variation
                </button>
            </div>

            <hr />

            <div>
                {variationsList.length === 0 && (
                    <div className="text-muted small text-center mt-5">Add your first variation...</div>
                )}

                <ul className="list-group list-group-flush">
                    {variationsList.map((variation, i) => {
                        return (
                            <VariationItem
                                key={i}
                                data={variation}
                                totalPrio={totalPriority}
                                stats={variationStats[i]}
                                isSelected={selectedVariation === variation}
                                onClick={() => selectVariation(i)}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default VariationList;
