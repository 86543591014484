/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import axios from 'axios';

const userContext = React.createContext({});

const userEvents = {};

const logout = (redirect = '') => {
    sessionStorage.removeItem('user');

    window.location.href =
        '/provider/logout' + (redirect.length > 1 ? `?redirect=${window.btoa(encodeURI(redirect))}` : '');
};

let _user = sessionStorage.getItem('user');

if (_user) {
    _user = JSON.parse(_user);
}

const updateProfile = (data) => {
    return axios.put('/api/user/' + data.id, data).then((result) => {
        for (let key in data) {
            if (data[key] !== undefined) _user[key] = data[key];
        }

        sessionStorage.setItem('user', JSON.stringify(_user));

        userEvents.onprofileupdate && userEvents.onprofileupdate();

        return result;
    });
};

const getProfile = (id) => {
    if (_user) {
        return Promise.resolve(_user);
    }

    window.sessionStorage.removeItem('user');

    return axios.get('/provider/profile').then((data) => {
        _user = data;

        sessionStorage.setItem('user', JSON.stringify(data));

        return data;
    });
};

export { userEvents };

export { userContext };

export { logout };

export { getProfile };

export { updateProfile };
