/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { EventEmitter } from '@gpg-web/utils';
import './TranslationOption.js';

let translations = {};
let defaultTranslations = {};

function init(_translations) {
    const Playable = window.Playable;

    if (typeof _translations === 'object') {
        translations = Object.assign({}, _translations);

        if (!defaultTranslations[Playable.id]) {
            defaultTranslations[Playable.id] = JSON.parse(JSON.stringify(translations));
        }

        Translations.emit('update', translations);
    }
}

function destroy() {}

function getTranslations() {
    return JSON.parse(JSON.stringify(translations));
}

function getDefaultTranslations() {
    const Playable = window.Playable;

    return defaultTranslations[Playable.id] || {};
}

function setTranslation(t) {
    translations = t;
}

const Translations = {
    init,
    destroy,
    getTranslations,
    getDefaultTranslations,
    setTranslation
};

EventEmitter.call(Translations);

export default Translations;
