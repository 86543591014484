/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2022 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

const PlayableFrameView = () => {
    return (
        <div id="playabe-frame-container" className="playable-frame-block">
            <iframe title="Playable demo" id="playable-frame" src="about:blank" />

            <div id="exit-fullscreen-btn" onClick={() => window.Playable.device.exitFullScreen()}>
                <i className="fas fa-times" />
            </div>
        </div>
    );
};

export default PlayableFrameView;
