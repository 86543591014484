/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { utils } from '@gpg-web/utils';

const appConext = createContext({});

const appEvents = {};

let appList = null;

const getApps = () => {
    if (appList !== null) {
        return Promise.resolve(appList);
    }

    return axios.get('/api/app/list').then((data) => {
        appList = data;
        return data;
    });
};

// const apps = {};

// const getCachedApps = (ids) => {
//     return ids.map((id) => apps[id]);
// };

// const getAppsById = (ids) => {
//     const requestIds = [];

//     ids.forEach((id) => {
//         if (!apps[id]) requestIds.push(id);
//     });

//     if (requestIds.length === 0) {
//         return Promise.resolve(getCachedApps(ids));
//     }

//     return axios.post('/api/app/many', { list: requestIds }).then((data) => {
//         data.forEach((app) => {
//             apps[app._id] = app;
//         });

//         return getCachedApps(ids);
//     });
// };

const getApp = (id) => {
    return axios.get('/api/app/' + id);
};

let requestId = 0;
const useApp = () => {
    const { app, setApp } = useContext(appConext);
    let { appId } = useParams();
    appId = Number(appId);
    const _id = app._id;

    useEffect(() => {
        if (_id === appId) return;

        if (!appId) {
            setApp({});
            return;
        }

        utils.popup('Loading app...');

        requestId++;
        const currentRequestId = requestId;
        getApp(appId)
            .then((app) => {
                if (currentRequestId === requestId) {
                    utils.popup('hide');
                    setApp(app);
                }
            })
            .catch(utils.hintError);
    }, [setApp, appId, _id]);

    if (appId !== _id) return null;

    return app._id ? app : null;
};

export const addApp = (data) => {
    return axios.post('/api/app', data).then((res) => {
        if (appList !== null) appList.push(res);

        appEvents.onupdate && appEvents.onupdate(appList.slice());

        return res;
    });
};

export const updateApp = (data) => {
    return axios.put('/api/app', data).then((res) => {
        if (appList !== null) {
            const exists = appList.find((e) => e._id === data._id);
            Object.assign(exists, data);
            appEvents.onupdate && appEvents.onupdate(appList.slice());
        }

        return res;
    });
};

export const addScene = (data) => {
    return axios.post('/api/scene', data);
};

// const updateApp = (id, data) => {
//     return axios.put('/api/app/' + id, data).then((res) => {
//         apps[id] = null;

//         if (appList) {
//             const index = appList.findIndex((p) => p._id === id);
//             if (index > -1) {
//                 appList[index] = res;
//             }
//         }
//         return res;
//     });
// };

// const removeApp = (id) => {
//     return axios.delete('/api/app/' + id).then((res) => {
//         utils.remove(appList, (e) => e._id === id);
//         appEvents.onupdate && appEvents.onupdate(appList.slice());
//         return;
//     });
// };

export const storeInfo = (params) => {
    return axios.get('/api/app/store-info', { params });
};

export const getScenes = (appId) => {
    return axios.get('/api/scene/list', { params: { app: appId } });
};

export const getScene = (sceneId) => {
    return axios.get('/api/scene/' + sceneId);
};

export const updateScene = (data) => {
    return axios.put('/api/scene', data);
};

export const deleteScene = (sceneId) => {
    return axios.delete('/api/scene/' + sceneId);
};

let resources = null;
export const getResources = () => {
    if (resources) return Promise.resolve(resources);

    return axios.get('/api/app/resources').then((res) => {
        resources = res;
        return res;
    });
};

export {
    getApps,
    getApp,
    // updateApp,
    // removeApp,
    // getAppsById,
    appConext,
    // apps,
    appEvents,
    useApp
};
