/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import TranslationsPanel from './Translations/TranslationsPanel';
import { creativeContext } from '../../../services/contexts';

const Configs = () => {
    const { selectedCreative } = useContext(creativeContext);

    return (
        <>
            <div id="config-tabs" className="nav nav-tabs" role="tablist">
                <button
                    title="General options"
                    className="nav-link active"
                    id="nav-general-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-general-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-general-options"
                    aria-selected="true"
                >
                    <i className="fas fa-cogs" />
                </button>

                <button
                    title="Playable specific options"
                    className="nav-link"
                    id="nav-playable-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-playable-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-playable-options"
                    aria-selected="false"
                >
                    <i className="fas fa-gamepad" />
                </button>

                <button
                    title="Ad Dynamic options"
                    className="nav-link"
                    id="nav-ad-dynamic-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-ad-dynamic-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-ad-dynamic-options"
                    aria-selected="false"
                >
                    <i className="fas fa-chart-line" />
                </button>

                <button
                    title="Tutorial options"
                    className="nav-link"
                    id="nav-tutorial-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-tutorial-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-tutorial-options"
                    aria-selected="false"
                >
                    <i className="fas fa-book" />
                </button>

                <button
                    title="CTA options"
                    className="nav-link"
                    id="nav-cta-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-cta-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-cta-options"
                    aria-selected="false"
                >
                    <i className="fas fa-bullhorn" />
                </button>

                <button
                    title="Translation options"
                    className="nav-link"
                    id="nav-translations-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-translations-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-translations-options"
                    aria-selected="false"
                >
                    <i className="fas fa-language" />
                </button>

                <button
                    title="Images options"
                    className="nav-link"
                    id="nav-images-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-images-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-images-options"
                    aria-selected="false"
                >
                    <i className="fas fa-photo-video" />
                </button>

                <button
                    title="Sounds options"
                    className="nav-link"
                    id="nav-sounds-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-sounds-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-sounds-options"
                    aria-selected="false"
                >
                    <i className="fas fa-music" />
                </button>

                <button
                    title="Plugin options"
                    className="nav-link disabled"
                    id="nav-plugin-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-plugin-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-plugin-options"
                    aria-selected="false"
                >
                    <i className="fas fa-dice-d6" />
                </button>

                {process.env.NODE_ENV === 'development' && (
                    <button
                        title="Test options"
                        className="nav-link"
                        id="nav-test-options-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-test-options"
                        type="button"
                        role="tab"
                        aria-controls="nav-test-options"
                        aria-selected="false"
                    >
                        <i className="fab fa-dev" />
                    </button>
                )}
            </div>

            <div className="tab-content" id="config-content">
                <div
                    className="tab-pane show active"
                    id="nav-general-options"
                    role="tabpanel"
                    aria-labelledby="nav-general-options-tab"
                >
                    <div className="tab-title">General Options</div>
                    <div id="general-options" />
                    <hr />
                    <div id="extra-options-tab-block" className="tab-block disabled">
                        <div className="tab-title">Advanced Options</div>
                        <div id="extra-options"></div>
                    </div>
                </div>

                <div
                    className="tab-pane"
                    id="nav-playable-options"
                    role="tabpanel"
                    aria-labelledby="nav-playable-options-tab"
                >
                    <div className="tab-title">Playable Options</div>
                    <div id="playable-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-ad-dynamic-options"
                    role="tabpanel"
                    aria-labelledby="nav-ad-dynamic-options-tab"
                >
                    <div className="tab-title">Ad Dynamic</div>
                    <div id="ad-dynamic-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-tutorial-options"
                    role="tabpanel"
                    aria-labelledby="nav-tutorial-options-tab"
                >
                    <div className="tab-title">Tutorial Options</div>
                    <div id="tutorial-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-cta-options"
                    role="tabpanel"
                    aria-labelledby="nav-cta-options-tab"
                >
                    <div className="tab-title">CTA Options</div>
                    <div id="cta-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-translations-options"
                    role="tabpanel"
                    aria-labelledby="nav-translations-options-tab"
                >
                    <TranslationsPanel></TranslationsPanel>
                </div>

                <div
                    className="tab-pane"
                    id="nav-tracking-options"
                    role="tabpanel"
                    aria-labelledby="nav-tracking-options-tab"
                >
                    <div className="tab-title">Tracking</div>
                    <div id="tracking-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-images-options"
                    role="tabpanel"
                    aria-labelledby="nav-images-options-tab"
                >
                    <div className="tab-title">Images</div>
                    <div id="images-options" />
                </div>

                <div
                    className="tab-pane"
                    id="nav-sounds-options"
                    role="tabpanel"
                    aria-labelledby="nav-sounds-options-tab"
                >
                    <div className="tab-title">Sounds</div>
                    <div id="sounds-options" />
                </div>
                <div
                    className="tab-pane"
                    id="nav-plugin-options"
                    role="tabpanel"
                    aria-labelledby="nav-plugin-options-tab"
                >
                    <div id="plugin-options-title" className="tab-title">
                        Plugin Options
                    </div>
                    <div id="plugin-options" />
                </div>
                {process.env.NODE_ENV === 'development' && (
                    <div
                        className="tab-pane"
                        id="nav-test-options"
                        role="tabpanel"
                        aria-labelledby="nav-test-options-tab"
                    >
                        <div id="test-options-title" className="tab-title">
                            Test Options
                        </div>
                        <div id="test-options" />
                    </div>
                )}
            </div>
            {selectedCreative && selectedCreative.dynamic && (
                        <div className="border-top">
                            <Link
                                className="btn btn-light mt-3 w-100"
                                to={'/dynamic/' + selectedCreative._id}
                            >
                                Go to Variations <i className="fas fa-chevron-right ms-2" /> 
                            </Link>
                        </div>
                    )}
        </>
    );
};
export default Configs;
