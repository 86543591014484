import { obj } from '@gpg-web/utils';

const preMessages = {
    success: '<strong>Success!</strong>',
    info: '<strong>Info!</strong>',
    warning: '<strong>Warning!</strong>',
    danger: '<strong>Danger!</strong>'
};

const preIcons = {
    success: '<i class="fa fa-check ms-1 me-2"></i>',
    info: '<i class="fa fa-info-circle ms-1 me-2"></i>',
    warning: '<i class="fas fa-exclamation-triangle ms-1 me-2"></i>',
    danger: '<i class="fa fa-times-circle ms-1 me-2"></i>'
};

export default function hint(message, _options) {
    // console.log(message);
    const container = document.getElementById('hintsContainer');

    if (message === 'clear') {
        container.innerHTML = '';
        return;
    }

    let options = {
        duration: _options
    };

    if (typeof _options === 'object') options = _options;

    if (message === 'error') {
        message = 'Some error has ocured!';
        options.alert = 'danger';
        options.icon = true;
        options.pre = true;
        options.duration = 4000;
        options.dismissible = true;
    } else if (message === 'ok') {
        message = 'Success!';
        options.alert = 'success';
        options.icon = true;
        options.pre = true;
        options.duration = 2000;
        options.dismissible = true;
    }

    let duration = obj.getValue(options, 'duration', 4000);
    const color = obj.getValue(options, 'color', 'light');
    const bg = obj.getValue(options, 'bg', 'secondary');
    const alert = obj.getValue(options, 'alert', null);
    const dismissible = obj.getValue(options, 'dismissible', obj.getValue(options, 'close', !!alert));
    const pre = obj.getValue(options, 'pre', true);
    const icon = obj.getValue(options, 'icon', true);

    const html = `
        ${
            dismissible
                ? '<button type="button" class="btn-close p-2 m-2" style="font-size: 10px" data-bs-dismiss="alert" aria-label="Close"></button>'
                : ''
        }

        ${typeof icon === 'string' ? '<i class="' + icon + ' ms-1 me-2"></i>' : ''}

        ${alert !== null && pre ? (icon === true ? preIcons[alert] || '' : preMessages[alert] || '') : ''}
    
        <span>
            ${message.replaceAll('\n', '<br/>')}
        </span>


    `;

    let element = document.createElement('div');

    element.classList.add('hint');

    setTimeout(() => {
        element.classList.add('hint-appearing');
    }, 100);

    duration += 400; // Appearing animation

    element.classList.add('alert');
    element.classList.add('p-2');
    element.setAttribute('role', 'alert');

    if (alert !== null) {
        element.classList.add('alert-' + alert);

        if (dismissible) {
            element.classList.add('alert-dismissible');
        }
    } else {
        element.classList.add('bg-' + bg);
        element.classList.add('text-' + color);
    }

    element.innerHTML = html;

    container.appendChild(element);

    setTimeout(() => {
        if (element.parentNode) {
            element.classList.add('hint-hiding');

            setTimeout(() => {
                if (element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            }, 400);
        }
    }, duration);
}
