import DebuggerCard from './DebuggerCard';
import PlayableDebugger from '../../../playground/Debugger';
import { useEffect, useState } from 'react';

const FPSCard = (prop) => {
    const [fps, setFps] = useState(PlayableDebugger.getFPS());

    useEffect(() => {
        const fpsCb = (frameRate) => {
            setFps(frameRate);
        };

        PlayableDebugger.on('fps', fpsCb);

        return () => {
            PlayableDebugger.off('fps', fpsCb);
        };
    }, []);

    let status = '';
    if (fps < 30) {
        status = 'warning';
    }

    return (
        <>
            <DebuggerCard
                title="Framerate"
                min={prop.min}
                status={status}
                value={fps}
                label="FPS"
            ></DebuggerCard>
        </>
    );
};

export default FPSCard;
