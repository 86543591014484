import { useContext } from 'react';
import { adDynamicContext } from '../../../services/contexts';
import { date } from '@gpg-web/utils';

const LogItem = (props) => {
    const item = props.item;

    const isFirst = props.first;

    const { t, a, msg } = item;

    return (
        <>
            <li className={'list-group-item py-2' + (isFirst ? '' : ' border-top')}>
                <div className="text-muted d-flex justify-content-between small fw-light mb-1">
                    <span>{date(t, 'ago')}</span>
                    <span>{a}</span>
                </div>
                {msg}
            </li>
        </>
    );
};
const LogsPane = (props) => {
    const { selectedVariation } = useContext(adDynamicContext);

    const logs = selectedVariation.log || [];

    return (
        <>
            <div className="px-3">
                {logs.length ? (
                    logs.map((log, i) => {
                        return <LogItem key={i} item={log} first={i === 0}></LogItem>;
                    })
                ) : (
                    <p>No logs for this variation found.</p>
                )}
            </div>
        </>
    );
};

export default LogsPane;
