import { useEffect } from 'react';
import { Languages } from '../../../../consts';
import { utils } from '@gpg-web/utils';
import AddTranslation from './AddTranslation';
import { useState } from 'react';

const TranslationInput = (prop) => {
    const { lang, placeholder, value, handler } = prop;

    const language = Languages[lang];

    const onChangeHandler = (e) => {
        const val = e.target.value;

        handler(lang, val);
    };

    return (
        <>
            <div className="input-group input-group-sm py-1">
                <span className="input-group-text">{language}</span>
                <input
                    type="text"
                    className="form-control"
                    defaultValue={value}
                    onChange={onChangeHandler}
                    placeholder={placeholder}
                />
            </div>
        </>
    );
};

const TranslationTerm = (prop) => {
    const { term, values, onChange, defaultVal, usedLang } = prop;

    const [langs, setLangs] = useState(Object.keys(values) || []);

    useEffect(() => {
        setLangs(Object.keys(values));
    }, [values]);

    const addLangHandler = (lang) => {
        langs.push(lang);
        setLangs([...langs]);
        onChange(term, lang, defaultVal[lang] || '');
    };

    const removeLangHandler = (lang) => {
        const rIndex = langs.indexOf(lang);
        if (rIndex > -1) {
            langs.splice(rIndex, 1);
            setLangs([...langs]);
            onChange(term, lang, undefined);
        }
    };

    const inputChangeHandler = (lang, val) => {
        onChange(term, lang, val);
    };

    const id = utils.generateId(term);

    return (
        <>
            <div className="mb-3 ms-2 border-bottom border-1 border-secondary ">
                <div
                    role="button"
                    className="accordion accordion-button collapsed p-1 px-3 bg-gray-200"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}`}
                    aria-expanded="false"
                    aria-controls={id}
                >
                    {term}
                </div>
                <div className="collapse bg-gray-200" data-bs-parent="#translations-options" id={id}>
                    <div className="p-3">
                        <AddTranslation
                            allLang={langs.slice()}
                            usedLang={usedLang}
                            onLangAdded={addLangHandler}
                            onLangRemoved={removeLangHandler}
                        ></AddTranslation>

                        <div>
                            {langs.map((lang) => (
                                <TranslationInput
                                    key={lang}
                                    lang={lang}
                                    placeholder={term}
                                    value={values[lang]}
                                    handler={inputChangeHandler}
                                ></TranslationInput>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TranslationTerm;
