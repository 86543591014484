import { useRef, useState } from 'react';
import { Languages } from '../../../../consts';

const LanguageItem = (prop) => {
    return (
        <>
            <span
                onClick={prop.handler}
                role="button"
                style={{ margin: '2px', fontSize: '13px' }}
                className={'badge ' + (prop.isAdded ? 'bg-success' : 'bg-light text-dark')}
            >
                {Languages[prop.lang]}
            </span>
        </>
    );
};

const AddTranslation = (prop) => {
    const inputEl = useRef(null);
    const [searchLang, setSearchLang] = useState('');

    const langInputHandler = (e) => {
        const val = e.target.value;

        setSearchLang(val);
    };

    const btnClickHandler = (e) => {
        inputEl.current.focus();
    };

    const isSeaching = searchLang.length > 0;

    const { allLang, usedLang } = prop;

    let searched;

    if (isSeaching) {
        searched = Object.keys(Languages)
            .filter((key) => Languages[key].toLowerCase().includes(searchLang) && key !== 'en')
            .slice(0, 10);
    } else {
        searched = usedLang.slice(0);
    }

    const onRemoveHandler = (lang) => {
        prop.onLangRemoved(lang);
    };

    const onAddHandler = (lang) => {
        //inputEl.current.focus()
        prop.onLangAdded(lang);
    };

    return (
        <>
            <div className="btn-group mb-3">
                <button
                    className="btn btn-sm btn-primary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    onClick={btnClickHandler}
                >
                    Add translation
                </button>
                <div className="dropdown-menu p-2 shadow-sm" style={{ width: '250px' }}>
                    <input
                        type="text"
                        ref={inputEl}
                        className="form-control"
                        value={searchLang}
                        onChange={langInputHandler}
                        placeholder="Search..."
                    />

                    {isSeaching ? <div className="dropdown-divider"></div> : null}

                    <div className="pt-2">
                        {searched.map((lang) => {
                            const isAdded = allLang.indexOf(lang) > -1;

                            const cb = isAdded
                                ? onRemoveHandler.bind(undefined, lang)
                                : onAddHandler.bind(undefined, lang);

                            return <LanguageItem key={lang} lang={lang} handler={cb} isAdded={isAdded} />;
                        })}

                        {searched.length === 0 && <div className="m-2 text-muted">Not found</div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddTranslation;
