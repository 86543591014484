/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { getRepositories, addRepository, deleteRepository, pullRepository, updateRepositoryCommit } from '../services/repository';
import { utils, date, str } from '@gpg-web/utils';

const Repositories = (props) => {
    const [repositories, setRepositories] = useState([]);
    const [newRep, setNewRep] = useState({});

    useEffect(() => {
        getRepositories().then((list) => setRepositories(list.slice())).catch(utils.hintError);
    }, []);

    const _addRepository = () => {
        if (!newRep.name) return utils.hintError('Repository Name is required');

        utils.popup('loading');

        addRepository(newRep)
            .then((res) => {
                utils.hint('ok');
                setNewRep({});
                repositories.push(res);
                setRepositories(repositories.slice());
                utils.popup('hide');
            })
            .catch(utils.hintError);
    };

    const inputHandler = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setNewRep({ ...newRep, [name]: value });
    };

    const _deleteRepository = (id) => {
        utils.popup('loading');

        deleteRepository(id)
            .then((res) => {
                utils.hint('ok');
                utils.remove(repositories, (e) => e._id === id);
                setRepositories(repositories.slice());
                utils.popup('hide');
            })
            .catch(utils.hintError);
    };

    const _pullRepository = (id) => {
        utils.popup('loading');

        pullRepository(id)
            .then((res) => {
                utils.hint('ok');
                let found = repositories.find((e) => e._id === res._id);
                if (found) {
                    Object.assign(found, res);
                    setRepositories(repositories.slice());
                }
                utils.popup('hide');
            })
            .catch(utils.hintError);
    };

    const _updateCommit = (name) => {
        utils.popup('loading');

        updateRepositoryCommit(name)
            .then((res) => {
                utils.hint('ok');
                let found = repositories.find((e) => e._id === res._id);
                if (found) {
                    Object.assign(found, res);
                    setRepositories(repositories.slice());
                }
                utils.popup('hide');
            })
            .catch(utils.hintError);
    }

    return (
        <div className="container">
            <h1 className="h3 mb-4 text-gray-700">Repositories Page</h1>

            <div className="card shadow-sm mb-4">
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="newRepositoryURL" className="form-label">
                            Git repository URL
                        </label>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon3">https://github.com/Green-Panda-Playable-Team/</span>
                            <input
                                name="name"
                                onChange={inputHandler}
                                value={newRep.name}
                                placeholder="repository name"
                                type="text"
                                className="form-control"
                                id="newRepositoryURL"
                            />
                        </div>
                    </div>
                    <button className="btn btn-success" onClick={_addRepository}>
                        Add repository
                    </button>
                </div>
            </div>

            <div>
                {repositories.map((rep) => {
                    return (
                        <RepositoryCard
                            pull={_pullRepository}
                            updateCommit={_updateCommit}
                            remove={_deleteRepository}
                            key={rep._id}
                            repository={rep}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const RepositoryCard = (props) => {
    const { _id, name, updated_at, commit } = props.repository;

    return (
        <div className="card shadow-sm mt-2">
            <div className="card-body d-flex align-items-center">
                <div>
                    <div>{name}</div>
                    <a href={"https://github.com/Green-Panda-Playable-Team/" + name} target="_blank" rel="noreferrer" className="small text-muted">{"https://github.com/Green-Panda-Playable-Team/" + name}</a>
                    <div className="small">Updated: {date(updated_at)}</div>
                </div>
                <div className="ms-auto">
                    <div>Hash: {commit.id}</div>
                    <i>{str.trim(commit.message, 40)}</i>
                </div>
                <button onClick={() => props.updateCommit(name)} className="btn btn-success ms-3">
                    <i className="fas fa-sm fa-sync-alt" /> Update commit
                </button>
                <button onClick={() => props.pull(_id)} className="btn btn-primary ms-2">
                    <i className="fas fa-sm fa-sync-alt" /> Pull
                </button>
                <button onClick={() => props.remove(_id)} className="btn btn-danger ms-2">
                    Remove
                </button>
            </div>
        </div>
    );
};

export default Repositories;
