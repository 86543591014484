import { network_ui, network_ui_script } from './network_ui';
import { fps_meter } from './fps_meter';
import { vconsole, vconsole_min } from './vconsole';
import { stats_js } from './stats_js';
import { events_debugger } from './events_debugger';
import { console_debugger } from './console_debugger';

const libs = {
    network_ui,
    network_ui_script,
    fps_meter,
    vconsole,
    vconsole_min,
    stats_js,
    events_debugger,
    console_debugger
};

export default libs;
