/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import Builder from './Builder';
import { EventEmitter, utils } from '@gpg-web/utils';
import { saveAs } from 'file-saver';
import Loader from './Loader';
import env from './Env';
import GENERAL_CONFIG from './GENERAL_CONFIG';
// import TRACKING_CONFIG from './TRACKING_CONFIG';
import configer from 'configer.js';
import Device from './Device';
import Debugger from './Debugger';
import Translations from './Translations';
import { glog } from '../services/gtag';
// import Compressor from './Compressor';
import DownloadFolder from './DownloadFolder';
import PreapreBuilds from './PreapreBuilds';
import PluginManager from './PluginManager';
import JSZip from 'jszip';

function log(str) {
    console.log('%c[playground] %s', 'background:#0F3970;color:white', str);
}

window.log = log;

let xframe = null;

async function generateAndDownloadPlayable(network) {
    const options = { network: network };

    if (network === 'adwords') {
        const result = await Builder.production(options);
        options.google_rotated = true;
        const resultRotated = await Builder.production(options);

        const zip = new JSZip();

        zip.file(result.fileName, result.blob);
        zip.file(resultRotated.fileName, resultRotated.blob);

        const zipBlobContent = await zip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
                level: 9
            }
        });

        saveAs(zipBlobContent, 'Adwords_' + Builder.title() + '.zip');
    } else {
        const result = await Builder.production(options);

        let blob = null;

        if (result.blob) {
            blob = result.blob;
        } else {
            blob = new Blob([result.content], {
                type: result.blobType
            });
        }

        glog('playable_download', 'download', result.title);

        saveAs(blob, result.fileName);
    }
}

async function generateAndUploadPlayable(network) {
    const Hosting = window.Hosting;

    if (Hosting) {
        const result = await Builder.production({ network: network });

        glog('playable_download', 'hosting', result.title);

        const url = await Hosting.cd(Playable.id).upload(result.fileName, result.blob || result.content);

        Playable.emit('build:host', url);

        return url;
    }
}

let _applyTimeout = null;
function applySourceToFrame(content, src) {
    Playable.demo = void 0;

    if (src) {
        xframe.onload = function () {
            Playable.demo = xframe.contentWindow;
            Playable.demo.alert = utils.alert;
            // Playable.emit('restart');
        };

        xframe.src = src;
    } else {
        var container = xframe.parentNode;

        xframe.remove();
        xframe.src = 'about:blank';
        container.prepend(xframe);
        _applyTimeout = setTimeout(function () {
            const xdocument = (window.xdocument = xframe.contentWindow.document);
            xdocument.open();
            xdocument.write(content);
            xdocument.close();
            Playable.demo = xframe.contentWindow;
            Playable.demo.alert = utils.alert;

            Playable.emit('restart');
        }, 150);
    }
}

function showEndcard() {
    if (Playable.demo && Playable.demo.game.showPopup) {
        Playable.demo.game.showPopup();
    } else {
        utils.alert("This playable doesn't have showPopup: function");
    }

    glog('playable', 'show_popup');
}

let configChangedDebounceTimer;

function configChanged(obj) {
    clearTimeout(configChangedDebounceTimer);
    if (!obj.onChange) {
        configChangedDebounceTimer = setTimeout(() => {
            restartPlayable();
        }, 500);
    }
}

let _can_apply = true;
function restartPlayable(hard) {
    log('restarting playable...');

    glog('playable', 'restarting_playable');

    if (_can_apply || hard) {
        _can_apply = false;

        setTimeout(function () {
            _can_apply = true;
        }, 300);

        const content = Builder.demo();

        applySourceToFrame(content);
    }
}

// let compressingQueue = 0;

// async function compressProject(CURRENT_SDK, CURRENT_PLAYABLE, CURRENT_SCENE)
// {
//     Playable.compressed = false;

//     compressingQueue++;

//     Playable.emit('compress:start');
//     Playable.emit('compress:running', true);

//     let compressed = 0;

//     if (!CURRENT_SDK.compressed)
//     {
//         CURRENT_SDK.compressed = true;
//         compressed += await Compressor(CURRENT_SDK, "source");
//         compressed += await Compressor(CURRENT_SDK, "modules");
//     }

//     if (!CURRENT_PLAYABLE.compressed)
//     {
//         CURRENT_PLAYABLE.compressed = true;
//         compressed += await Compressor(CURRENT_PLAYABLE, "source");
//         compressed += await Compressor(CURRENT_PLAYABLE, "modules");
//     }

//     if (CURRENT_SCENE && !CURRENT_SCENE.compressed)
//     {
//         CURRENT_SCENE.compressed = true;
//         compressed += await Compressor(CURRENT_SCENE, "source");
//     }

//     compressingQueue--;

//     if (compressingQueue <= 0)
//     {
//         Playable.compressed = true;
//         compressingQueue = 0;
//         Playable.emit('compress:running', false);
//         Playable.emit('compress:finish');
//     }

//     if (compressed > 0)
//     {
//         log('compressing done: ' + compressed + ' files has been compressed');
//     }
// }

// async function compressScene(CURRENT_SCENE)
// {
//     Playable.compressed = false;

//     compressingQueue++;

//     Playable.emit('compress:start');
//     Playable.emit('compress:running', true);

//     let compressed = 0;

//     if (!CURRENT_SCENE.compressed)
//     {
//         CURRENT_SCENE.compressed = true;
//         compressed += await Compressor(CURRENT_SCENE, "source");
//     }

//     compressingQueue--;

//     if (compressingQueue <= 0)
//     {
//         Playable.compressed = true;
//         compressingQueue = 0;
//         Playable.emit('compress:running', false);
//         Playable.emit('compress:finish');
//     }

//     if (compressed > 0)
//     {
//         log('compressing done: ' + compressed + ' files has been compressed');
//     }
// }

const defaultConfigSnapshots = {};

function saveDefaultConfigs(sceneId) {
    if (defaultConfigSnapshots[sceneId]) return;

    const snapshot = {};
    defaultConfigSnapshots[sceneId] = snapshot;

    snapshot.general = configer.snapshot(GENERAL_CONFIG);
    // snapshot.track = configer.snapshot(TRACKING_CONFIG);
    snapshot.config = configer.snapshot(Playable.config || {});
    snapshot.ad_dynamic = configer.snapshot(Playable.ad_dynamic || {});
}

function restoreDefaultConfigs(sceneId) {
    const snapshot = defaultConfigSnapshots[sceneId];
    if (!snapshot) return;

    configer.mixin(GENERAL_CONFIG, snapshot.general || {});
    // configer.mixin(TRACKING_CONFIG, snapshot['track'] || {});
    Playable.config && configer.mixin(Playable.config, snapshot.config || {});
    Playable.ad_dynamic && configer.mixin(Playable.ad_dynamic, snapshot.ad_dynamic || {});

    Playable.translations && Translations.setTranslation(Translations.getDefaultTranslations());

    // PluginManager.resetConfigs();
}

let _scene = null;

let downloading = false;

const Playable = {
    compressed: true,

    load: function (playable) {
        if (downloading) return downloading;

        downloading = this._load(playable);

        return downloading;
    },

    _load: async function (scene) {
        // if (downloading) return;

        // downloading = true;

        try {
            env.resetContext();

            _scene = scene;

            // Playable.model = playable;

            const build_config = (Playable.build_config = scene.build);

            Playable.id = scene._id;
            Playable.name = scene.name;
            Playable.appName = scene.app.name;
            const repository = (Playable.repository = scene.repository);
            Playable.app_store_url = scene.app_store_url || scene.app.app_store_url;
            Playable.google_play_url = scene.google_play_url || scene.app.google_play_url;
            // Playable.scenes = build_config.scenes;

            if (repository.commit && repository.commit.id) {
                Playable.hash = repository.commit.id.substr(0, 7);
            } else {
                const playable_updated = '' + new Date(repository.updated_at).getTime();

                Playable.hash = playable_updated.substr(3, playable_updated.length - 6);
                Playable.hash = Playable.hash
                    .replace(new RegExp('0', 'g'), 'a')
                    .replace(new RegExp('1', 'g'), 'i')
                    .replace(new RegExp('2', 'g'), 'z')
                    .replace(new RegExp('9', 'g'), 'g');
            }

            let sceneBuildConfig = build_config.scenes.find((e) => e.id === scene.source.scene);

            log('downloading ' + scene.name);

            await Loader.playable(scene, sceneBuildConfig);

            // if (sceneBuildConfig) {
            //     await Loader.scene(playable, scene);
            // }

            log('plugins preloading...');
            await PluginManager.preload();

            log('enabling ' + scene.name);
            env.enablePlayable(scene);
            env.enableScene(scene);

            Playable.loaded = true;

            // compressProject(env.CURRENT_SDK, env.CURRENT_PLAYABLE, env.CURRENT_SCENE);
            downloading = false;
            Playable.emit('load');
        } catch (err) {
            downloading = false;
            console.error(err);
            throw err;
        }
    },

    // changeScene: async function (sceneId) {
    //     if (Playable.active) {
    //         const playable = Playable.model;
    //         const scene = Playable.scenes.find((e) => e.id === sceneId);

    //         if (playable && scene) {
    //             log('changing scene to: ' + scene.id);
    //             glog('playable', 'scene_changed', scene.id);

    //             restoreDefaultConfigs();

    //             env.resetContext();

    //             await Loader.scene(playable, scene);
    //             Playable.emit('scene:load', scene.id);

    //             _scene = scene;

    //             env.enableScene(scene.id);

    //             Playable.init();

    //             Playable.emit('scene:changed', scene.id);

    //             // compressScene(env.CURRENT_SCENE);
    //         }
    //     }
    // },

    buildConfigs: function () {
        const config = Playable.config || {};

        const tabs = ['tutorial', 'cta', 'images', 'sounds', 'extra'];

        const playableConfig = {};

        for (let key in config) {
            if (tabs.indexOf(key) === -1) playableConfig[key] = config[key];
        }

        const enabled = (el) => document.getElementById(el).classList.remove('disabled');
        const disabled = (el) => document.getElementById(el).classList.add('disabled');
        const isActive = (el) => document.getElementById(el).classList.contains('active');
        const resetActiveTab = () => document.getElementById('nav-general-options-tab').click();

        const generalOptionsGUI = new configer.GUI('general_options', {
            config: GENERAL_CONFIG,
            parent: 'general-options'
        });
        generalOptionsGUI.on('change', configChanged);

        const config_playable_id = 'scene_' + _scene.id;

        if (config.extra) {
            enabled('extra-options-tab-block');

            new configer.GUI('extra_options_' + config_playable_id, {
                config: config.extra.config,
                parent: 'extra-options'
            });
        } else {
            disabled('extra-options-tab-block');
        }

        const playableOptionsGUI = new configer.GUI('custom_options_' + config_playable_id, {
            config: playableConfig,
            parent: 'playable-options'
        });
        playableOptionsGUI.on('change', configChanged);

        if (Playable.ad_dynamic) {
            enabled('nav-ad-dynamic-options-tab');
            const adDynamicOptionsGUI = new configer.GUI('ad_dynamic_options_' + config_playable_id, {
                config: Playable.ad_dynamic,
                parent: 'ad-dynamic-options'
            });
            adDynamicOptionsGUI.on('change', configChanged);
        } else {
            if (isActive('nav-ad-dynamic-options-tab')) resetActiveTab();
            disabled('nav-ad-dynamic-options-tab');
        }

        if (config.tutorial) {
            enabled('nav-tutorial-options-tab');

            const tutorialOptionsGUI = new configer.GUI('tutorial_options_' + config_playable_id, {
                config: config.tutorial,
                parent: 'tutorial-options'
            });
            tutorialOptionsGUI.on('change', configChanged);
        } else {
            if (isActive('nav-tutorial-options-tab')) resetActiveTab();
            disabled('nav-tutorial-options-tab');
        }

        if (config.cta) {
            enabled('nav-cta-options-tab');
            const ctaOptionsGUI = new configer.GUI('cta_options_' + config_playable_id, {
                config: config.cta,
                parent: 'cta-options'
            });
            ctaOptionsGUI.on('change', configChanged);
        } else {
            if (isActive('nav-cta-options-tab')) resetActiveTab();
            disabled('nav-cta-options-tab');
        }

        // new configer.GUI('tracking_options', {
        //     config: TRACKING_CONFIG,
        //     parent: 'tracking-options'
        // });

        if (config.images) {
            enabled('nav-images-options-tab');
            const imagesOptionsGUI = new configer.GUI('images_options_' + config_playable_id, {
                config: config.images,
                parent: 'images-options'
            });
            imagesOptionsGUI.on('change', configChanged);
        } else {
            if (isActive('nav-images-options-tab')) resetActiveTab();
            disabled('nav-images-options-tab');
        }

        if (config.sounds) {
            enabled('nav-sounds-options-tab');
            const soundsOptionsGUI = new configer.GUI('sounds_options_' + config_playable_id, {
                config: config.sounds,
                parent: 'sounds-options'
            });
            soundsOptionsGUI.on('change', configChanged);
        } else {
            if (isActive('nav-sounds-options-tab')) resetActiveTab();
            disabled('nav-sounds-options-tab');
        }

        if (Playable.translations) {
            enabled('nav-translations-options-tab');
            Translations.init(Playable.translations);
        } else {
            if (isActive('nav-translations-options-tab')) resetActiveTab();
            disabled('nav-translations-options-tab');
        }

        if (process.env.NODE_ENV === 'development') {
            enabled('nav-test-options-tab');
            new configer.GUI('test' + config_playable_id, {
                config: {
                    testTranslation: {
                        type: 'translation',
                        title: 'Test Translation Text:',
                        value: '',
                        placeholder: 'This text will be translation'
                    },
                    testColor: {
                        type: 'color',
                        title: 'Color test:',

                        value: {
                            color: '#ffffff',
                            alpha: 1
                        }
                    },
                    testFont: {
                        type: 'font',
                        title: 'Text styles:',
                        font: {
                            options: ['Arial', 'Arial Black', 'Helvetta'],
                            labels: ['Font 1', 'Font 2', 'Font 3']
                        },
                        weight: {
                            options: [400, 600, 700]
                        },
                        value: {
                            size: 1,
                            font: 'Arial',
                            color: { color: '#ff0000', alpha: 1 },
                            weight: 400,
                            strokeWidth: 1,
                            strokeColor: '#ffff00'
                        }
                    }
                },
                parent: 'test-options'
            });
            // document.getElementById('nav-test-options-tab').click();
        }
    },

    init: function () {
        if (downloading) return;

        utils.popup('Playground initializing...');

        Playable.emit('scene:changed', _scene.name);

        log('initializing ' + Playable.name + ' - ' + _scene.name);

        xframe = Playable.demoFrame = document.getElementById('playable-frame');

        GENERAL_CONFIG.ios_url.value = Playable.app_store_url;
        GENERAL_CONFIG.android_url.value = Playable.google_play_url;
        GENERAL_CONFIG.playable_title.value = Playable.build_config.title_template || '{EXCH}_{ID}_{HASH}';

        saveDefaultConfigs(_scene._id);

        Playable.buildConfigs();

        Device.init(Playable);
        Debugger.init(Playable);

        PluginManager.init(Playable).then(() => {
            if (!window.location.search.includes('creative')) restartPlayable();

            Playable.emit('init');

            utils.popup('hide');
            Playable.active = true;
        });
    },

    initDynamic: function () {
        xframe = Playable.demoFrame = document.getElementById('playable-frame');
        Device.init(Playable);
    },

    destroyDynamic: function () {
        Device.destroy();
        xframe = Playable.demoFrame = null;
    },

    destroy: function () {
        _scene = null;

        Playable.loaded = false;
        Playable.active = false;
        Playable.demo = null;
        Playable.demoFrame = null;

        clearTimeout(_applyTimeout);

        Device.destroy();
        Debugger.destroy();
        Translations.destroy();

        log('destroyed');
    },

    get scene() {
        return _scene;
    },

    get scene_id() {
        return _scene._id;
    },

    device: Device,

    debugger: Debugger,

    env: env,

    resetConfigs: () => {
        log('configs reset');
        restoreDefaultConfigs(_scene._id);
        Playable.buildConfigs();
        PluginManager.resetAll(false);
        restartPlayable(true);
    },

    snapshotConfigs: function () {
        return JSON.stringify({
            // tracking: configer.snapshot(TRACKING_CONFIG),
            general: configer.snapshot(GENERAL_CONFIG),
            config: Playable.config ? configer.snapshot(Playable.config) : undefined,
            ad_dynamic: Playable.ad_dynamic ? configer.snapshot(Playable.ad_dynamic) : undefined,
            translations: Playable.translations ? Playable.translations : undefined,
            plugins: PluginManager.activeList.reduce((acc, val) => {
                acc[val] = configer.snapshot(PluginManager.getPluginConfig(val));
                return acc;
            }, {})
        });
    },

    applyConfigs: async function (data, liveApply = true) {
        const { general, config, ad_dynamic, translations, plugins } = JSON.parse(data);

        configer.mixin(GENERAL_CONFIG, general);
        // configer.mixin(TRACKING_CONFIG, tracking);
        if (Playable.config && config) configer.mixin(Playable.config, config);
        if (Playable.ad_dynamic && ad_dynamic) configer.mixin(Playable.ad_dynamic, ad_dynamic);
        if (Playable.translations && translations) utils.extend(Playable.translations, translations);

        liveApply && Playable.buildConfigs();

        const pluginsConfig = plugins;

        const activeList = Object.keys(pluginsConfig);

        if (activeList.length) {
            await PluginManager.whenReady();

            PluginManager.removeAll(false);

            for (let pluginId of activeList) {
                await PluginManager.add(pluginId, false, pluginsConfig[pluginId]);
            }
        }

        liveApply && restartPlayable(true);
    },

    restart: restartPlayable,

    applySourceToFrame: applySourceToFrame,

    showEndcard: showEndcard,

    download: generateAndDownloadPlayable,

    plugins: PluginManager,

    upload: generateAndUploadPlayable,

    downloadFolder: DownloadFolder,

    preapreBuilds: PreapreBuilds,

    generate: Builder.production,

    getTitle: Builder.title,

    setSDKRepository: Loader.setSDKRepository
};

EventEmitter.call(Playable);

window.Playable = Playable;

export default Playable;
