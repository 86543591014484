import { useState, useContext } from 'react';
import { adDynamicContext } from '../../../../services/contexts';

function getCtr(cta, c) {
    return ((cta / c) * 100).toFixed(2) || 0;
}

const Button = (props) => {
    return (
        <button
            type="button"
            onClick={props.clickHandler}
            className={'btn btn-sm ' + (props.active ? '  btn-primary disabled' : ' btn-outline-primary')}
        >
            {props.children}
        </button>
    );
};

const OverAllTable = (props) => {
    const { data } = props;

    const { imp, cta, eng, fin, err, itr } = data;

    const ctr = cta && imp ? getCtr(cta, imp) + '%' : '-';

    return (
        <table className="table table-sm table-striped table-bordered w-100">
            <tbody>
                <tr>
                    <th>Variation shown</th>
                    <td>{imp}</td>
                </tr>
                <tr>
                    <th>Engagement</th>
                    <td>{eng}</td>
                </tr>
                <tr>
                    <th>Completion</th>
                    <td>{fin}</td>
                </tr>
                <tr>
                    <th>Clicked to Store</th>
                    <td>{cta}</td>
                </tr>
                <tr>
                    <th>CTR</th>
                    <td>{ctr}</td>
                </tr>

                <br />
                <tr>
                    <th>Interactions</th>
                    <td>{itr}</td>
                </tr>
                <tr>
                    <th>Errors</th>
                    <td>{err}</td>
                </tr>
            </tbody>
        </table>
    );
};
const USVWTable = (props) => {
    const { data } = props;

    const { us, ww } = data;

    const ctrUS = (us.cta / us.imp).toFixed(3) * 100 || 0;
    const ctrWW = (ww.cta / ww.imp).toFixed(3) * 100 || 0;

    return (
        <table className="table table-sm table-striped table-bordered w-100">
            <tbody>
                <tr>
                    <th></th>
                    <th>US</th>
                    <th>World</th>
                </tr>
                <tr>
                    <th>Variation shown</th>
                    <td>{us.imp}</td>
                    <td>{ww.imp}</td>
                </tr>
                <tr>
                    <th>Clicks</th>
                    <td>{us.cta}</td>
                    <td>{ww.cta}</td>
                </tr>
                <tr>
                    <th>CTR</th>
                    <td>{ctrUS}%</td>
                    <td>{ctrWW}%</td>
                </tr>
            </tbody>
        </table>
    );
};

// const PeriodsTable = (props) => {
//     function sum(array, from, to) {
//         return array.slice(from, to).reduce(function (a, vl) {
//             return a + vl;
//         }, 0);
//     }

//     const { data } = props;

//     const day = data.day || {
//         c: [],
//         cta: []
//     };

//     let c = new Array(7).fill(0);
//     c.splice(7 - day.c.length, 7, ...day.c);

//     let cta = new Array(7).fill(0);
//     cta.splice(7 - day.cta.length, 7, ...day.cta);

//     const day_0 = { c: data._c, cta: data._cta };

//     const day_1 = { c: c[6], cta: cta[6] };

//     const day_3 = {
//         c: sum(c, 4, 7) + day_0.c,
//         cta: sum(cta, 4, 7) + day_0.cta
//     };

//     const day_7 = {
//         c: sum(c, 1) + day_0.c,
//         cta: sum(cta, 1) + day_0.cta
//     };

//     return (
//         <table className="table table-sm table-striped table-bordered w-100">
//             <tbody>
//                 <tr>
//                     <th></th>
//                     <th>Today</th>
//                     <th>Yesterday</th>
//                     <th>L3</th>
//                     <th>L7</th>
//                 </tr>
//                 <tr>
//                     <th>Variation shown</th>
//                     <td>{day_0.c}</td>
//                     <td>{day_1.c}</td>
//                     <td>{day_3.c}</td>
//                     <td>{day_7.c}</td>
//                 </tr>
//                 <tr>
//                     <th>Clicks</th>
//                     <td>{day_0.cta}</td>
//                     <td>{day_1.cta}</td>
//                     <td>{day_3.cta}</td>
//                     <td>{day_7.cta}</td>
//                 </tr>
//                 <tr>
//                     <th>CTR</th>
//                     <td>{getCtr(day_0.cta, day_0.c)}%</td>
//                     <td>{getCtr(day_1.cta, day_1.c)}%</td>
//                     <td>{getCtr(day_3.cta, day_3.c)}%</td>
//                     <td>{getCtr(day_7.cta, day_7.c)}%</td>
//                 </tr>
//             </tbody>
//         </table>
//     );
// };

const tables = {
    overall: {
        name: 'Overall',
        icon: 'fa fa-globe',
        table: OverAllTable
    },
    usvw: {
        name: 'US vs World',
        icon: 'fas fa-globe-americas',
        table: USVWTable
    },
    // periods: {
    //     name: 'Periods',
    //     icon: 'fa fa-calendar',
    //     table: PeriodsTable
    // }
};

const AnalyticsPane = (props) => {
    const [dataTable, setDataTable] = useState('overall');

    const { dynamic, selectedVariation } = useContext(adDynamicContext);

    const variationStats = dynamic.stats.find((stat) => stat.id === selectedVariation.hash);

    const SelectedTableComponent = tables[dataTable].table;

    const stats = variationStats || {
        c: 0,
        imp: 0,
        eng: 0,
        fin: 0,
        cta: 0,
        err: 0,
        itr: 0,
        day: {
            imp: [],
            cta: [],
            updated: 0
        },
        us: {
            imp: 0,
            cta: 0
        },
        ww: {
            imp: 0,
            cta: 0
        }
    };

    return (
        <div className="p-2 pe-1">
            <div className="d-flex flex-row justify-content-between">
                {/* <span className="">Campaing IR: {33.42}%</span> */}

                <div className="btn-group shadow-sm ms-auto" role="group">
                    {Object.keys(tables).map((key) => {
                        const table = tables[key];
                        return (
                            <Button
                                key={key}
                                active={key === dataTable}
                                clickHandler={() => setDataTable(key)}
                            >
                                <i className={table.icon} />
                                &nbsp;{table.name}
                            </Button>
                        );
                    })}
                </div>
            </div>
            <hr></hr>
            <div className="rounded mt-3 px-3">
                {<SelectedTableComponent data={stats}></SelectedTableComponent>}
            </div>
        </div>
    );
};

export default AnalyticsPane;
