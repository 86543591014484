/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import DownloadButton from './DownloadButton';
import FolderExportButton from './FolderExportButton';
import UploadButton from './UploadButton';
import { Spinner } from '@gpg-web/react';

import { DOWNLOAD_NETWORKS, UPLOAD_NETWORKS } from '../../../consts';

const ControlBar = () => {
    const Playable = window.Playable;

    const [compressing, setCompressing] = useState(!Playable.compressed);

    useEffect(() => {
        setCompressing(!Playable.compressed);

        function compressing(val) {
            setCompressing(val);
        }

        Playable.on('compress:running', compressing);

        return () => {
            Playable.off('compress:running', compressing);
        };
    }, [Playable]);

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-center spt-1 my-2 border-top w-100">
            <div className={'d-flex flex-wrap ' + (compressing ? ' dsbl' : '')}>
                <UploadButton networks={UPLOAD_NETWORKS} action={window.Playable.upload} />

                <DownloadButton networks={DOWNLOAD_NETWORKS} action={window.Playable.download} />

                <FolderExportButton networks={DOWNLOAD_NETWORKS} action={window.Playable.downloadFolder} />

                {compressing && (
                    <div className="ms-auto mt-2 pt-1 d-flex align-items-center">
                        <Spinner /> &nbsp; Compressing ...
                    </div>
                )}
            </div>
        </div>
    );
};

export default ControlBar;
