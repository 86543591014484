import React from 'react';

const Input = (props) => (
    <div className="form-group mb-2">
        <label className="mb-1" htmlFor={props.id}>
            {props.title || props.name}
        </label>
        <input
            name={props.name}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            type="text"
            value={props.value}
            className="form-control"
            id={props.id}
        />
    </div>
);

const TextArea = (props) => (
    <div className="form-group mb-2">
        <label className="mb-1" htmlFor={props.id}>
            {props.title || props.name}
        </label>
        <textarea
            name={props.name}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            type="text"
            value={props.value}
            className="form-control"
            id={props.id}
        />
    </div>
);

const BaseTextArea = (props) => (
    <div className="form-group mb-2">
        <label className="mb-1" htmlFor={props.id}>
            {props.title || props.name}
        </label>
        <textarea
            name={props.name}
            defaultValue={props.defaultValue}
            onChange={(e) => props.onChange(e.target.value)}
            type="text"
            value={props.value}
            className="form-control"
            id={props.id}
        />
    </div>
);

export { Input, TextArea, BaseTextArea };
