/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import GENERAL_CONFIG from './GENERAL_CONFIG';

let PARAMS = {
    width: 495,
    height: 870,
    zoom: 1,
    landscape: false,
    volume: 0
};

const saved = window.localStorage.getItem('device-emulator-params');

if (saved) PARAMS = JSON.parse(saved);

let Playable = {};
let fullScreenPreview = false;

function _resize() {
    if (!Playable.demoFrame) return;

    if (fullScreenPreview) return;

    const width = PARAMS.landscape ? PARAMS.height : PARAMS.width;
    const height = PARAMS.landscape ? PARAMS.width : PARAMS.height;

    const frameParentBounds = Playable.demoFrame.parentElement.getBoundingClientRect();
    frameParentBounds.height -= 10;
    frameParentBounds.width -= 10;

    let scwidth = width * PARAMS.zoom;
    let scheight = height * PARAMS.zoom;

    if (scwidth > frameParentBounds.width || scheight > frameParentBounds.height) {
        let dw = scwidth - frameParentBounds.width;
        let dh = scheight - frameParentBounds.height;

        if (dw > dh) {
            PARAMS.zoom = frameParentBounds.width / width;
        } else {
            PARAMS.zoom = frameParentBounds.height / height;
        }

        scwidth = width * PARAMS.zoom;
        scheight = height * PARAMS.zoom;

        dw = scwidth - frameParentBounds.width;
        dh = scheight - frameParentBounds.height;

        const maxD = Math.max(dw, dh);

        if (maxD > 0) {
            if (dw > dh) {
                PARAMS.zoom = PARAMS.zoom - maxD / width;
            } else {
                PARAMS.zoom = PARAMS.zoom - maxD / height;
            }
        }

        // ScalePerc.val(Math.round(PARAMS.zoom * 100))
    }

    //Playable.demoFrame.contentWindow.window.devicePixelRatio = 0.1
    Playable.demoFrame.style.width = width + 'px';
    Playable.demoFrame.style.height = height + 'px';

    Playable.demoFrame.style.transform = 'translate(-50%, -50%) scale(' + PARAMS.zoom + ')';
    if (width > height) {
        Playable.demoFrame.style.borderWidth = '12px 65px 12px 30px';
    } else {
        Playable.demoFrame.style.borderWidth = '30px 12px 65px';
    }
}

function resize() {
    PARAMS.zoom = 1;
    _resize();
}

function fullScreen() {
    if (!Playable.demoFrame) return;

    const parent = document.getElementById('play-panel');
    const exitFullScreenBtn = document.getElementById('exit-fullscreen-btn');

    if (parent && exitFullScreenBtn) {
        Playable.demoFrame.classList.add('fullscreen');
        parent.classList.remove('sticky-top');
        exitFullScreenBtn.classList.add('show');
    }
}

function exitFullScreen() {
    const parent = document.getElementById('play-panel');
    const exitFullScreenBtn = document.getElementById('exit-fullscreen-btn');

    if (parent && exitFullScreenBtn) {
        Playable.demoFrame.classList.remove('fullscreen');
        parent.classList.add('sticky-top');
        exitFullScreenBtn.classList.remove('show');
    }
}

function init(playable) {
    Playable = playable;

    window.addEventListener('resize', resize);
    document.addEventListener('visibilitychange', updatePlayState);

    setParams();

    Playable.on('restart', function () {
        Playable.demo.gp.on('postrender', function () {
            setParams();
        });
    });
}

function destroy() {
    window.removeEventListener('resize', resize);
    document.removeEventListener('visibilitychange', updatePlayState);
}

function updatePlayState() {
    if (PARAMS.paused) {
        Playable.demo &&
            Playable.demo.game &&
            Playable.demo.gp.once('update', () => {
                pause();
            });
    }
}

function pause() {
    PARAMS.paused = true;
    Playable.demo && Playable.demo.game && Playable.demo.game.pause();
    setVolume(0);
}

function resume() {
    PARAMS.paused = false;
    Playable.demo && Playable.demo.game && Playable.demo.game.resume();
    setVolume();
}

function setVolume(volume) {
    if (volume === undefined) volume = PARAMS.volume;

    PARAMS.volume = Math.max(Math.min(volume, 1), 0);

    if (Playable.demo && Playable.demo.gp) {
        Playable.demo.gp.vol = PARAMS.volume * GENERAL_CONFIG.sound_volume.value;

        Playable.demo.game && Playable.demo.game.volume && Playable.demo.game.volume(Playable.demo.gp.vol);
    }
}

function setParams(p) {
    PARAMS = p || PARAMS;

    window.localStorage.setItem('device-emulator-params', JSON.stringify(PARAMS));

    resize();

    setPaused();

    setVolume();
}

function setPaused() {
    PARAMS.paused ? pause() : resume();
}

const Device = {
    init,
    destroy,
    params: PARAMS,
    setParams,
    pause,
    resume,
    setVolume,
    fullScreen,
    exitFullScreen
};

export default Device;
