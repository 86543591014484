/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { Networks, NETWORK_IMAGE_KEY, NetworkImages, DOWNLOAD_NETWORKS } from '../../../consts';
import { useToaster, Message } from 'rsuite';
import { utils, store } from '@gpg-web/utils';
import { Modal, Spinner } from '@gpg-web/react';
import { uploadUADashboardAssets } from '../../../services/creative';

const networks = utils.without(DOWNLOAD_NETWORKS, 'preview');

const NetworkItem = (props) => {
    return (
        <div className="form-check mb-2 card shadow">
            <label
                className="form-check-label card-body d-flex align-items-center"
                htmlFor={'downloadModal' + props.network}
            >
                <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.checked}
                    name={props.network}
                    onChange={props.onChange}
                    id={'downloadModal' + props.network}
                />
                <div className="ms-3">
                    <div className="fw-bold small">{Networks[props.network].displayName}</div>
                </div>

                <div className="ms-auto">{NetworkImages[props.icon]}</div>
            </label>
        </div>
    );
};

const colors = [
    '#02315E',
    '#00457E',
    '#2F70AF',
    '#B9848C',
    '#806491',
    '#0A2647',
    '#144272',
    '#205295',
    '#2C74B3'
];

const CreativeItem = (props) => {
    return (
        <div className="bg-gray-100 py-2 px-3 my-2">
            <i className="fas fa-puzzle-piece me-2" style={{ color: utils.sample(colors) }} />
            <span className="small">{props.data.name}</span>
            {props.canRemove && (
                <span
                    role="button"
                    onClick={props.onRemove}
                    data-id={props.data._id}
                    className="text-gray-500 float-end"
                >
                    <i className="fas fa-times pe-none" />
                </span>
            )}
        </div>
    );
};

const DownloadModal = (props) => {
    let { show, onHide, creatives } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectedNetworks, setSelectedNetworks] = useState([]);
    const [selectedCreatives, setSelectedCreatives] = useState([]);
    const [splitNetworks, setSplitNetworks] = useState(false);
    const toaster = useToaster();

    useEffect(() => {
        if (creatives) setSelectedCreatives(creatives.slice());
    }, [creatives]);

    const catchError = (err) => {
        toaster.push(
            <Message showIcon type="error">
                {err.message || err}
            </Message>,
            { duration: 5000 }
        );
        setLoading(false);
    };

    const download = () => {
        setLoading(true);

        const Playable = window.Playable;

        const params = {
            split_folders_by_network: splitNetworks,
            name: Playable.name + '_' + utils.timestamp(),
            networks: selectedNetworks,
            creatives: selectedCreatives
        };

        Playable.downloadFolder(params).then(_onHide).catch(catchError);
    };

    const publish = async () => {
        try {
            let yes = await utils.confirm(
                'Are you sure you want to publish creatives to UA Dashboard ?<br/> Only Applovin, Unity, Facebook and Google Adwords will be published'
            );
            if (!yes) return;

            setLoading(true);
            const Playable = window.Playable;

            const allowedNetworks = ['unity', 'applovin', 'facebook', 'adwords'];
            const networks = selectedNetworks.filter((e) => allowedNetworks.includes(e));

            if (networks.length === 0) return catchError('Please select allowed ad networks to continue');

            const params = {
                networks: networks,
                creatives: selectedCreatives
            };

            const result = await Playable.preapreBuilds(params);

            yes = await utils.confirm(
                result.length +
                    ' files ready.<br/>Do you want to upload them on UA Dashboard for both stores?'
            );

            if (!yes) return setLoading(false);

            for (let build of result) {
                console.log(build);
                let network = build.network;
                if (network === 'adwords') network = 'google';

                const formData = new FormData();

                let hasUrl = false;
                if (build.google_play_url) {
                    formData.append('playMarketId', store.appId(build.google_play_url));
                    hasUrl = true;
                }
                if (build.app_store_url) {
                    formData.append('appStoreId', store.appId(build.app_store_url));
                    hasUrl = true;
                }

                if (!hasUrl) throw new Error('Please, provide at least one store URL for ' + build.name);

                formData.append('network', network);
                formData.append('name', build.name);

                formData.append('file', build.blob);

                await uploadUADashboardAssets(formData);

                toaster.push(
                    <Message showIcon type="success">
                        {'Asset ' + build.name + ' uploaded for ' + build.network}
                    </Message>,
                    { duration: 3000 }
                );
            }

            _onHide();
        } catch (err) {
            catchError(err.message || err);
        }
    };

    const _onHide = () => {
        setLoading(false);
        setError(null);
        onHide(false);
    };

    const changeNetworkSelection = (e) => {
        const target = e.target;
        const checked = target.checked;
        const name = target.name;

        if (checked) {
            selectedNetworks.push(name);
        } else {
            utils.remove(selectedNetworks, (e) => e === name);
        }

        setSelectedNetworks(utils.uniq(selectedNetworks));
    };

    const selectAllNetworks = (select) => {
        if (select) setSelectedNetworks(networks.slice());
        else setSelectedNetworks([]);
    };

    const removeSelectedCreative = (e) => {
        const creativeId = Number(e.target.getAttribute('data-id'));

        console.log(creativeId);

        utils.remove(selectedCreatives, (e) => e._id === creativeId);
        setSelectedCreatives(selectedCreatives.slice());
    };

    return (
        <Modal show={show} onHide={_onHide} size="xl" title="Publish">
            <div className="modal-body px-4">
                <div className="d-flex justify-content-end align-items-center mb-4 pb-3 border-bottom">
                    {error && (
                        <div className="text-danger me-auto">
                            <i className="fa fa-exclamation-circle me-2" />
                            {error}
                        </div>
                    )}
                    <button data-bs-dismiss="modal" className="btn btn-light btn-sm me-2 rounded-pill">
                        Cancel
                    </button>
                    <button
                        disabled={loading || selectedNetworks.length === 0}
                        onClick={publish}
                        className="btn btn-primary btn-sm rounded-pill me-2"
                    >
                        {loading && <Spinner size="10px" />} <span className="ms-1">Publish Creatives</span>
                    </button>
                    <button
                        disabled={loading || selectedNetworks.length === 0}
                        onClick={download}
                        className="btn btn-primary btn-sm rounded-pill"
                    >
                        {loading && <Spinner size="10px" />} <span className="ms-1">Download Creatives</span>
                    </button>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 mb-3">
                        <div className="card shadow">
                            <div className="border-bottom p-3">
                                <div className="d-flex small justify-content-between">
                                    <div>
                                        <span className="fw-light me-2">Creatives:</span>
                                        <b>{selectedCreatives.length}</b>
                                    </div>
                                    <div>
                                        <span className="fw-light me-2">Networks:</span>
                                        <b>{selectedNetworks.length}</b>
                                    </div>
                                    <div>
                                        <span className="fw-light me-2">Total Assets:</span>
                                        <b>
                                            {selectedCreatives.length *
                                                (selectedNetworks.length +
                                                    (selectedNetworks.includes('adwords') ? 1 : 0))}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="text-muted small fw-bold">CREATIVES</div>

                                    {selectedCreatives.map((creative) => (
                                        <CreativeItem
                                            key={creative._id}
                                            canRemove={selectedCreatives.length > 1}
                                            onRemove={removeSelectedCreative}
                                            data={creative}
                                            onChange={changeNetworkSelection}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 mb-3">
                        <div className="d-flex justify-content-between">
                            <div className="form-check mb-3 ms-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={networks.length === selectedNetworks.length}
                                    onChange={(e) => selectAllNetworks(e.target.checked)}
                                    id="selectAllAdNetworks"
                                />
                                <label
                                    className="form-check-label small fw-light text-muted"
                                    htmlFor="selectAllAdNetworks"
                                >
                                    AD NETWORKS
                                </label>
                            </div>
                            <div className="form-check mb-3 ms-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={splitNetworks}
                                    onChange={(e) => setSplitNetworks(e.target.checked)}
                                    id="splitNetworkByFolder"
                                />
                                <label
                                    className="form-check-label small fw-light text-muted"
                                    htmlFor="splitNetworkByFolder"
                                >
                                    SPLIT FOLDERS
                                </label>
                            </div>
                        </div>
                        <div>
                            {networks.map((key) => (
                                <NetworkItem
                                    checked={selectedNetworks.includes(key)}
                                    key={key}
                                    network={key}
                                    icon={NETWORK_IMAGE_KEY[key] ? NETWORK_IMAGE_KEY[key] : key}
                                    onChange={changeNetworkSelection}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DownloadModal;
