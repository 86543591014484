import { Stack, InputPicker } from 'rsuite';

const DIMENSIONS = [
    { label: 'Apps', value: 'app', type: 'ENTITY' },
    { label: 'Scenarios', value: 'scene', type: 'ENTITY' },
    { label: 'Creatives', value: 'creative', type: 'ENTITY' },
    { label: 'Ad Network', value: 'network', type: 'DELIVERY' },
    { label: 'Country', value: 'country', type: 'DELIVERY' },
    { label: 'OS', value: 'os', type: 'DEVICE' },
    { label: 'OS Version', value: 'os_version', type: 'DEVICE' },
    { label: 'Device Language', value: 'lang', type: 'DEVICE' },
    { label: 'Device Model', value: 'device', type: 'DEVICE' },
    { label: 'Orientation', value: 'orientation', type: 'DEVICE' }
];

const PerformanceDimensions = (props) => {
    const { dimension, dimension2, setDimension, setDimension2 } = props;

    return (
        <Stack wrap spacing={10} className="mt-4">
            <InputPicker
                container={document.getElementById('wrapper')}
                data={DIMENSIONS.filter((e) => e.value !== dimension2)}
                value={dimension}
                onChange={setDimension}
                style={{ width: 220 }}
                cleanable={false}
                groupBy="type"
                size="sm"
                placeholder="Choose dimension"
            />

            <i className="fas fa-plus me-1 fa-sm text-gray-500" />

            <InputPicker
                data={DIMENSIONS.filter((e) => e.value !== dimension)}
                value={dimension2}
                onChange={setDimension2}
                style={{ width: 220 }}
                groupBy="type"
                size="sm"
                placeholder="Choose one more dimension"
            />
        </Stack>
    );
};

export { PerformanceDimensions, DIMENSIONS };
