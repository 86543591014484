import { useEffect, useState } from 'react';
import { Row, Col, Panel } from 'rsuite';
import ReactECharts from 'echarts-for-react';
import { date, ms } from '@gpg-web/utils';

function initGraphsOptions() {
    return {
        tooltip: {
            trigger: 'axis'
        },
        title: {
            left: 'left',
            text: 'Timeline'
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {},
                magicType: { type: ['line', 'bar', 'pie'] },
                saveAsImage: {}
            }
        },
        legend: {
            data: ['Blank']
        },
        grid: {
            left: '6%',
            right: '4%'
        },
        xAxis: {
            type: 'time',
            boundaryGap: false
            // data: [56, 48, 51, 59, 55, 53, 56, 63, 58, 55]
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%']
        },
        dataZoom: [
            {
                type: 'slider',
                start: 75,
                end: 100
            }
        ],
        series: []
    };
}

// option = {
//     legend: {},
//     tooltip: {},
//     xAxis: [{ type: 'category', gridIndex: 0 }],
//     dataset: {
//         source: [
//             ['product', '2012'],
//             ['Matcha Latte', 41.1],
//             ['Milk Tea', 16.4],
//             ['Cheese Cocoa', 24.1]
//         ]
//     },
//     yAxis: [{ gridIndex: 0 }],

//     series: [
//         { type: 'bar', seriesLayoutBy: 'row' },
//         { type: 'bar', seriesLayoutBy: 'row' },
//         { type: 'bar', seriesLayoutBy: 'row' }
//     ]
// };

function initDistributionOptions() {
    return {
        title: {
            left: 'left',
            text: 'Distribution'
        },
        tooltip: {
            trigger: 'item',
            position: function (pt) {
                return [pt[0] - 100, pt[1] + 20];
            }
        },
        legend: {
            top: '10%',
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                top: '40%',
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: null,
                data: []
            }
        ]
    };
}

const PerformanceGraphs = (props) => {
    const { activeCard, loading, data } = props;
    const [layoutReady, setLayoutReady] = useState(false);
    const [distributionOptions, setDistributionOptions] = useState(initDistributionOptions());
    const [graphsOptions, setGraphsOptions] = useState(initGraphsOptions());
    const [theme, setTheme] = useState(document.documentElement.getAttribute('data-bs-theme'));

    useEffect(() => {
        /**
         * Put this useEffect and timer
         * to wait a little bit while dom will be ready
         * and all components will have correct bounds
         *
         * as graphs will use dom bounds to calculate size.
         */
        setTimeout(() => {
            setLayoutReady(true);
        }, 100);

        function themeChanged(event) {
            setTheme(event.detail);
            console.log(event.detail);
        }

        window.addEventListener('theme-change', themeChanged);

        return () => {
            window.removeEventListener('theme-change', themeChanged);
        };
    }, []);

    useEffect(() => {
        if (!data) return;

        const _distributionOptions = initDistributionOptions();
        const _graphsOptions = initGraphsOptions();

        let distributions = [];

        const timeScale = data.scale;

        function buildTs(ts) {
            let splited = date(ts, 'mmm dd yyyy').split(' ');
            return {
                ts: +ts,
                m: splited[0],
                d: splited[1],
                y: splited[2],
                h: timeScale === 'HOUR' ? ts.getHours() : 0
            };
        }

        for (let row of data.list) {
            row.parsedTs = buildTs(new Date(row.ts));
            let name = row.dm1;
            if (row.dm2 !== undefined) name += ' | ' + row.dm2;

            row.label = name;

            let exists = distributions.find((e) => e.name === name);
            if (!exists) {
                exists = { value: 0, name: name };
                distributions.push(exists);
            }

            exists.value += row.i;
        }

        distributions.sort((a, b) => (a.value > b.value ? -1 : 1));
        const others = distributions.splice(4, distributions.length - 4);

        if (others.length > 0) {
            const sum = others.reduce((a, b) => a + b.value, 0);

            // for (let other of others) {
            //     const row = data.list.find((e) => e.label === other.name);
            //     sum += other.value;
            // }

            distributions.push({ value: sum, name: 'Other' });
        }

        _distributionOptions.series = [
            {
                type: 'pie',
                radius: ['40%', '70%'],
                top: '40%',
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: null,
                data: distributions
            }
        ];

        const legend = [];
        const series = [];
        const timestamps = [];

        let indexes = Math.round((data.dateRange[1] - data.dateRange[0]) / ms('1d'));
        indexes = indexes || 1;

        if (timeScale === 'HOUR') indexes *= 24;

        let lastTs = new Date(data.dateRange[1]);
        let now = new Date();
        now.setHours(now.getHours() + 2);
        if (lastTs > now) lastTs = now;

        // if (lastTs > now) lastTs = now;
        // else if ((now - lastTs) / ms('1d') > 1) {
        //     console.log((now - lastTs) / ms('1d'));
        //     lastTs.setHours(23, 59, 59);
        // }

        // lastTs.setHours(lastTs.getHours() + 2);

        for (let i = 0; i < indexes; i++) {
            timestamps.splice(0, 0, buildTs(lastTs));

            if (timeScale === 'HOUR') lastTs.setHours(lastTs.getHours() - 1);
            else lastTs.setDate(lastTs.getDate() - 1);
        }

        distributions.forEach((dist) => {
            if (dist.name === 'Other') return;

            const seriesData = [];

            for (let ts of timestamps) {
                let exists = data.list.find((e) => {
                    return (
                        e.parsedTs.y === ts.y &&
                        e.parsedTs.m === ts.m &&
                        e.parsedTs.d === ts.d &&
                        e.parsedTs.h === ts.h &&
                        e.label === dist.name
                    );
                });

                // exists && console.log(exists.label);

                if (exists && exists.i) {
                    if (activeCard === 'impressions') seriesData.push([ts.ts, exists.i]);
                    else if (activeCard === 'engagment')
                        seriesData.push([ts.ts, (exists.e / exists.i) * 100]);
                    else if (activeCard === 'finish') seriesData.push([ts.ts, (exists.f / exists.i) * 100]);
                    else if (activeCard === 'cta') seriesData.push([ts.ts, (exists.c / exists.i) * 100]);
                } else seriesData.push([ts.ts, 0]);
            }

            const seriesOpt = {
                name: dist.name,
                type: 'line',
                showSymbol: false,
                data: seriesData
            };

            if (activeCard === 'impressions') {
                seriesOpt.areaStyle = {};
                seriesOpt.lineStyle = { width: 0 };
                seriesOpt.stack = 'Total';
            }
            legend.push(dist.name);
            series.push(seriesOpt);
        });

        if (others.length > 0) {
            const seriesData = [];
            const extra = [];

            for (let ts of timestamps) {
                seriesData.push([ts.ts, 0]);
                extra.push(0);
            }

            others.forEach((dist) => {
                for (let i = 0; i < timestamps.length; i++) {
                    const ts = timestamps[i];
                    let exists = data.list.find((e) => {
                        return (
                            e.parsedTs.y === ts.y &&
                            e.parsedTs.m === ts.m &&
                            e.parsedTs.d === ts.d &&
                            e.parsedTs.h === ts.h &&
                            e.label === dist.name
                        );
                    });

                    if (exists && exists.i) {
                        if (activeCard === 'impressions') seriesData[i][1] += exists.i;
                        else if (activeCard === 'engagment') {
                            seriesData[i][1] += exists.i;
                            extra[i] += exists.e;
                        } else if (activeCard === 'finish') {
                            seriesData[i][1] += exists.i;
                            extra[i] += exists.f;
                        } else if (activeCard === 'cta') {
                            seriesData[i][1] += exists.i;
                            extra[i] += exists.c;
                        }
                    }
                }
            });

            if (activeCard !== 'impressions') {
                for (let i = 0; i < seriesData.length; i++) {
                    if (seriesData[i][1]) seriesData[i][1] = (extra[i] / seriesData[i][1]) * 100;
                }
            }

            const seriesOpt = {
                name: 'Other',
                type: 'line',
                lineStyle: { width: 0 },
                showSymbol: false,
                data: seriesData
            };

            if (activeCard === 'impressions') {
                seriesOpt.areaStyle = {};
                seriesOpt.stack = 'Total';
            }
            legend.push('Other');
            series.push(seriesOpt);
        }

        if (activeCard === 'impressions') {
            _graphsOptions.yAxis = {
                type: 'value',
                boundaryGap: [0, '10%']
            };
            _graphsOptions.tooltip = {
                trigger: 'axis'
            };
        } else {
            _graphsOptions.yAxis = {
                type: 'value',
                // max: 100,
                boundaryGap: false,
                axisLabel: {
                    formatter: '{value}%'
                }
            };
            _graphsOptions.tooltip = {
                trigger: 'axis',
                valueFormatter: (value) => value.toFixed(2) + '%'
            };
        }

        // const xAxis = [];

        // for (let ts of timestamps) {
        //     if (ts.h === 0) {
        //         if (ts.m === 'Jan') xAxis.push(ts.y);
        //         else xAxis.push(ts.m + ' ' + ts.d);
        //     } else xAxis.push((ts.h < 10 ? '0' : '') + ts.h + ':00');
        // }
        // _graphsOptions.xAxis.data = xAxis;

        _graphsOptions.legend.data = legend;
        _graphsOptions.series = series;

        setDistributionOptions(_distributionOptions);
        setGraphsOptions(_graphsOptions);
    }, [data, activeCard]);

    const loadingOption = {
        text: 'LOADING ...',
        color: theme === 'dark' ? '#fff' : '#454545',
        textColor:  theme === 'dark' ? '#fff' : '#000',
        maskColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.8)'
    };

    return (
        <Row className="mt-3" gutter={16}>
            <Col md={18} sm={24} xs={24}>
                <Panel bordered>
                    {layoutReady && (
                        <ReactECharts
                            notMerge
                            theme={theme === 'dark' && 'dark'}
                            showLoading={loading}
                            loadingOption={loadingOption}
                            style={{ height: '400px', width: '100%' }}
                            option={graphsOptions}
                        />
                    )}
                </Panel>
            </Col>
            <Col md={6} sm={24} xs={24}>
                <Panel bordered>
                    {layoutReady && (
                        <ReactECharts
                            notMerge
                            theme={theme === 'dark' && 'dark'}
                            showLoading={loading}
                            loadingOption={loadingOption}
                            style={{ height: '400px', width: '100%' }}
                            option={distributionOptions}
                        />
                    )}
                </Panel>
            </Col>
        </Row>
    );
};

export { PerformanceGraphs };
