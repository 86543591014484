import screenshot from './Screenshot';

const Media = {
    screenshot,

    thumbnail: function (el) {
        return screenshot(el, { quality: 0.5, timestamps: ['25%'], format: 'jpeg' });
    }
};

export default Media;
