import { useContext } from 'react';

import { createBrowserRouter, createRoutesFromElements, Navigate, Route, Outlet } from 'react-router-dom';

import { NotFoundPage } from '@gpg-web/react';

import AppListPage from './pages/app/AppsList';
import ScenesListPage from './pages/app/ScenesList';
// import AppEditPage from './pages/app/Edit';
import AppAdd from './pages/app/Add';
import Repositories from './pages/Repositories';

import PlaygroundPage from './pages/playground';

import DynamicPage from './pages/dynamic';

import PerformancePage from './pages/analytics/performance';

import { userContext } from './services/user';

import App from './App';

const Analytics = (props) => (
    <div className="text-center">
        <p className="lead text-gray-800 my-5">
            New analytics dashboard still WIP. If you needed it deadly - let me know, i will switch turbo mode
            on, and try to provide new tool asap.
        </p>
    </div>
);

const ProtectedRoute = (props) => {
    const { lvl } = useContext(userContext);

    if (lvl < props.requiredLvl) {
        return <Navigate to="/404" replace />;
    }

    return <Outlet />;
};

export const AppRouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route exact path="/" element={<AppListPage />} />
            <Route exact path="/apps" element={<AppListPage />} />
            <Route exact path="/apps/:appId" element={<ScenesListPage />} />
            <Route path="/apps/:appId/scenes/:sceneId/playground" element={<PlaygroundPage />} />

            <Route element={<ProtectedRoute requiredLvl={1} />}>
                <Route path="/dynamic/:id" element={<DynamicPage />} />
                <Route exact path="/analytics/performance" element={<PerformancePage />} />
                <Route exact path="/analytics" element={<Analytics />} />
            </Route>

            <Route element={<ProtectedRoute requiredLvl={3} />}>
                <Route exact path="/admin/app/add" element={<AppAdd />} />
                <Route exact path="/admin/repositories" element={<Repositories />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
        </Route>
    )
);
