/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import { Networks, NETWORK_IMAGE_KEY, NetworkImages } from '../../../consts';
import { utils } from '@gpg-web/utils';

const MenuItem = (props) => {
    return (
        <button className="dropdown-item d-flex align-items-end" onClick={() => props.onClick(props.network)}>
            <div className="me-2">
                <div className="title">{Networks[props.network].displayName}</div>
                <div className="subtitle">{Networks[props.network].description}</div>
            </div>
            <div className="ms-auto">{NetworkImages[props.icon]}</div>
        </button>
    );
};

const UploadButton = (props) => {
    const networks = props.networks || [];
    const action = props.action || (() => {});

    const uploadPlayable = (network) => {
        utils.popup('uploading');

        action(network)
            .then((url) => {
                utils.popup('hide');
                utils.qrPreview(utils.staticUrl('hosting/' + url));
            })
            .catch(utils.hintError);
    };

    return (
        <div className="dropdown playable-download-menu me-3 mt-2">
            <button
                className="btn btn-sm dropdown-toggle no-after shadow-sm"
                type="button"
                id="downloadButtonDropdownMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i className="fas fa-share" /> &nbsp;Upload
            </button>
            <div
                className="dropdown-menu dropdown-menu-start shadow"
                aria-labelledby="downloadButtonDropdownMenu"
            >
                <div className="dropdown-header text-center">
                    <strong>Upload playable</strong>
                </div>
                <div className="networks">
                    <div className="networks-content">
                        {networks.map((key) => (
                            <MenuItem
                                key={key}
                                network={key}
                                icon={NETWORK_IMAGE_KEY[key] ? NETWORK_IMAGE_KEY[key] : key}
                                onClick={uploadPlayable}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadButton;
