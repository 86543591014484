import { useState, useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useToaster, Message } from 'rsuite';
import { adDynamicContext, layoutContext } from '../../services/contexts';
import { getDynamicCreative, updateDynamicCreative } from '../../services/dynamic';
import ControlPanel from './ControlPanel';
import Preview from './Preview';
import VariationPanel from './VariationPanel';
import configer from 'configer.js';
import { userContext } from '../../services/user';
import { restartPlayable, recalculatePriorities } from './utils';
import { DYNAMIC_AUTOMATION_CONFIG } from '../../consts';
import { utils } from '@gpg-web/utils';

const DynamicPage = () => {
    const params = useParams();

    const [creative, setCreative] = useState(null);
    const [errors, setErrors] = useState(null);
    const [dynamic, setDynamic] = useState(null);
    const [preview, setPreview] = useState(null);
    const [config, setConfig] = useState(null);
    const [changed, setChanged] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [selectedVariationIndex, selectVariation] = useState(-1);
    const { tooglePlaygroundView } = useContext(layoutContext);
    const user = useContext(userContext);
    const toaster = useToaster();

    const { id } = params;
    let selectedVariation = null;
    if (dynamic && selectedVariationIndex > -1) {
        selectedVariation = dynamic.list[selectedVariationIndex];
    }

    const dynamicGui = dynamic && dynamic.gui;

    useEffect(() => {
        if (errors) {
            toaster.push(
                <Message showIcon type="error">
                    {errors}
                </Message>,
                { duration: 5000 }
            );
            setErrors(null);
        }
    }, [toaster, errors]);

    useEffect(() => {
        if (!id) return;

        utils.popup('loading');

        getDynamicCreative(id)
            .then((creative) => {
                setPreview(creative.dynamic.preview);
                delete creative.dynamic.preview;
                setConfig(creative.dynamic.config);
                delete creative.dynamic.config;
                setDynamic(creative.dynamic);
                delete creative.dynamic;
                setCreative(creative);

                utils.popup('hide');
            })
            .catch(setErrors);
    }, [id]);

    useEffect(() => {
        if (!dynamicGui) return;

        if (selectedVariation) {
            configer.mixin(config, JSON.parse(selectedVariation.snapshot));
            dynamicGui.apply(undefined, false);
            restartPlayable(preview, config);
        }

        let timer;

        setCanSave(false);
        function configChanged() {
            if (selectedVariation) setCanSave(true);

            clearTimeout(timer);

            timer = setTimeout(() => {
                restartPlayable(preview, config);
            }, 500);
        }

        dynamicGui.on('change', configChanged);

        return () => {
            clearTimeout(timer);
            dynamicGui.off('change', configChanged);
        };
    }, [selectedVariation, preview, config, dynamicGui]);

    const addNewVariation = () => {
        var variation = {
            name: 'Variation ' + (dynamic.list.length + 1),
            snapshot: JSON.stringify(configer.snapshot(config)),
            config: JSON.stringify(configer.parse(config)),
            hash: configer.hash(config),
            priority: 1,
            enabled: true,
            log: []
        };

        variation.log.push({
            t: Date.now(),
            a: user.email,
            msg: 'Successfully created'
        });

        dynamic.list.push(variation);

        recalculatePriorities(dynamic.list);

        setDynamic({ ...dynamic });
        selectVariation(dynamic.list.length - 1);
        setChanged(true);
    };

    const updateVariation = (name, value) => {
        selectedVariation[name] = value;
        setDynamic({ ...dynamic });
        setChanged(true);
    };

    const saveVariationConfig = () => {
        if (selectedVariation) {
            selectedVariation.config = JSON.stringify(configer.parse(config));
            selectedVariation.snapshot = JSON.stringify(configer.snapshot(config));
            selectedVariation.hash = configer.hash(config);
            setDynamic({ ...dynamic });
            setCanSave(false);
            setChanged(true);
        }
    };

    const removeVariation = () => {
        if (selectedVariationIndex < 0) return;

        utils.confirm('Are you sure you want to remove this variation?', (yes) => {
            if (!yes) return;

            dynamic.list.splice(selectedVariationIndex, 1);
            recalculatePriorities(dynamic.list);
            selectVariation(-1);
            setDynamic({ ...dynamic });
            setChanged(true);
            document.getElementById('nav-dynamic-variation-options-tab').click();
        });
    };

    const publish = () => {
        utils.confirm('Are you sure you want to publish all your changes?', (yes) => {
            if (!yes) return;

            utils.popup('saving');

            const data = {
                list: dynamic.list,
                automation: configer.parse(DYNAMIC_AUTOMATION_CONFIG)
            };

            updateDynamicCreative(creative._id, data)
                .then(() => {
                    setChanged(false);
                    utils.popup('hide');
                    utils.hint('Published', { alert: 'success' });
                })
                .catch(utils.hintError);
        });
    };

    useEffect(() => {
        tooglePlaygroundView(true);

        return () => {
            utils.popup('hide');
            tooglePlaygroundView(false);
        };
    }, [tooglePlaygroundView]);

    if (!creative) {
        return <></>;
    }

    return (
        <div className="container-fluid playground-container">
            <div className="row">
                <adDynamicContext.Provider
                    value={{
                        changed,
                        creative,
                        dynamic,
                        preview,
                        config,
                        setDynamic,
                        setChanged,
                        addNewVariation,
                        selectedVariationIndex,
                        selectedVariation,
                        selectVariation,
                        updateVariation,
                        canSave,
                        saveVariationConfig,
                        removeVariation,
                        publish
                    }}
                >
                    <div className="control-panel col-lg-3 col-md-12 bg-gray-100">
                        <ControlPanel />
                        <div className="border-top">
                            <Link
                                className="btn btn-light mt-3 w-100"
                                to={
                                    '/apps/' +
                                    creative.app +
                                    '/scenes/' +
                                    creative.scene +
                                    '/playground?creative=' +
                                    creative._id
                                }
                            >
                                <i className="fas fa-chevron-left me-2" /> Back to Playground
                            </Link>
                        </div>
                    </div>
                    <Preview />
                    <div className="config-panel col-lg-4 col-md-12 sticky-top bg-gray-100">
                        <VariationPanel />
                    </div>
                </adDynamicContext.Provider>
            </div>
        </div>
    );
};

export default DynamicPage;
