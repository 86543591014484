import { useEffect, useContext } from 'react';
import configer from 'configer.js';
import { adDynamicContext } from '../../../services/contexts';
import { DYNAMIC_AUTOMATION_CONFIG } from '../../../consts';

const SelectVariation = () => {
    const { dynamic, setChanged } = useContext(adDynamicContext);

    useEffect(() => {
        configer.mixin(DYNAMIC_AUTOMATION_CONFIG, dynamic.automation);

        const gui = new configer.GUI('dynamic_automation_options', {
            config: DYNAMIC_AUTOMATION_CONFIG,
            parent: 'automation-options'
        });

        function configChanged() {
            setChanged(true);
        }

        gui.on('change', configChanged);

        return () => {
            gui.off('change', configChanged);
        };
    }, [setChanged, dynamic.automation]);

    return (
        <div>
            <div className="tab-title">Automation Options</div>
            <div id="automation-options" />
        </div>
    );
};

export default SelectVariation;
