import React from 'react';
// style="border-radius: 120px;"
// style="display: flex;height: 100%;align-items: center;margin: 10px;"

const QrPreview = () => {
    return (
        <>
            <div
                className="modal fade"
                id="qrPreviewModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="qrPreviewLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id="qrPreviewLabel">
                                Text QR Code Preview
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div className="d-flex mx-4 mt-3 mb-3">
                            <input
                                id="qrPreviewText"
                                autoComplete="off"
                                type="text"
                                readOnly
                                className="form-control me-2"
                                placeholder="Type here"
                            />
                            <button
                                id="qrPreviewModalCopy"
                                title="Copy text to clipboard"
                                className="btn btn-sm"
                            >
                                <i className="fas fa-copy" />{' '}
                            </button>
                        </div>
                        <div id="qrCodeImage" className="d-flex justify-content-center m-4 mt-2 pb-2"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QrPreview;
