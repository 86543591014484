/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState } from 'react';
import { Networks } from '../../../consts';
import { utils } from '@gpg-web/utils';

let savedDefaultParams = window.localStorage.getItem('folder-export-default-params');

if (savedDefaultParams) savedDefaultParams = JSON.parse(savedDefaultParams);
else
    savedDefaultParams = {
        networks: {
            ironsource_dapi: true,
            unity: true,
            applovin: true,
            facebook: true,
            mintegral: true
        },
        split_folders_by_network: true,
        prefix: '',
        sufix: '',
        compression_level: 9
    };

savedDefaultParams.compression_level = 9;

const NetworkToggler = (params) => {
    return (
        <>
            <input
                checked={!!params.networks[params.network]}
                onChange={(e) => params.onToggle(params.network, e.target.checked)}
                type="checkbox"
                className="btn-check"
                id={'folder-export-toggler-' + params.network}
                autoComplete="off"
            />
            <label
                className="btn btn-sm btn-outline-primary m-1"
                htmlFor={'folder-export-toggler-' + params.network}
            >
                {Networks[params.network].displayName}
            </label>
        </>
    );
};

const FolderExportButton = (props) => {
    const networks = props.networks || [];
    const action = props.action || (() => {});

    const [params, setParams] = useState(savedDefaultParams);
    const [downloading, setDownloading] = useState(false);

    const inputHandler = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        params[name] = value;

        window.localStorage.setItem('folder-export-default-params', JSON.stringify(params));

        setParams({ ...params });
    };

    const changeNetwork = (network, add) => {
        params.networks[network] = !!add;

        window.localStorage.setItem('folder-export-default-params', JSON.stringify(params));

        setParams({ ...params });
    };

    const downloadFolder = () => {
        setDownloading(true);

        // window.Playable.downloadFolder(params)

        const options = {
            ...params,
            networks: Object.keys(params.networks).filter((e) => params.networks[e])
        };

        action(options)
            .then(() => {
                setDownloading(false);
            })
            .catch((err) => {
                utils.hintError(err);
                setDownloading(false);
            });
    };

    return (
        <div className="btn-group dropdown ms-3 playable-download-folder-menu mt-2">
            <button
                type="button"
                disabled={downloading}
                className="btn btn-sm btn-outline-dark dropdown-toggle no-after"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                data-bs-offset="200,0"
                aria-expanded="false"
            >
                <i className="fas fa-folder-open" />
            </button>

            <div className="dropdown-menu dropdown-menu-end shadow pb-3">
                <div className="dropdown-header text-center mb-2">
                    <strong>Folder configuration</strong>
                </div>
                <div className="px-2">
                    <div> Networks </div>
                    {networks.map((e) => (
                        <NetworkToggler
                            key={e}
                            network={e}
                            networks={params.networks}
                            onToggle={changeNetwork}
                        />
                    ))}

                    <div className="form-check form-switch mt-2 ms-1">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="split_folders_by_network"
                            onChange={inputHandler}
                            id="is-split-folders-by-networks"
                            checked={params.split_folders_by_network}
                        />
                        <label className="form-check-label" htmlFor="is-split-folders-by-networks">
                            Split folders by networks
                        </label>
                    </div>
                </div>

                <div className="dropdown-divider" />

                <div className="p-2">
                    <label className="form-label">Prefix & suffix for each filename</label>
                    <div className="input-group input-group-sm">
                        <input
                            type="text"
                            className="form-control"
                            name="prefix"
                            onChange={inputHandler}
                            value={params.prefix}
                            placeholder="Prefix"
                        />
                        <span className="input-group-text">&nbsp;filename&nbsp;</span>
                        <input
                            type="text"
                            className="form-control text-end"
                            name="sufix"
                            onChange={inputHandler}
                            value={params.sufix}
                            placeholder="Suffix"
                        />
                    </div>
                </div>
            </div>

            <button
                type="button"
                onClick={downloadFolder}
                disabled={downloading}
                className="btn btn-sm shadow-sm"
            >
                Download Folder
            </button>
        </div>
    );
};

export default FolderExportButton;
