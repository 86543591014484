/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { Link, useNavigate } from 'react-router-dom';
import { RestrictAccess } from '../../components/RestrictAccess';
import { VERSION } from '../../consts';

const DashboardSidebar = (props) => {
    const history = useNavigate();

    return (
        <>
            <div
                onClick={() => {
                    history('/');
                }}
                role="button"
                className="brand text-light"
            >
                <div className="text-logo">
                    <i className="fas fa-puzzle-piece" />
                </div>
                <span className="ms-1"> Dashboard </span>
            </div>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item">
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-gamepad"></i>&nbsp;
                    <span>Playable Ads</span>
                </Link>
            </li>
            <RestrictAccess requiredLvl={1}>
                <li className="nav-item">
                    <div
                        className="nav-link collapsed"
                        type="button"
                        role="tab"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAnalytics"
                        aria-expanded="true"
                        aria-controls="collapseAnalytics"
                    >
                        <i className="fas fa-chart-bar"></i>&nbsp;
                        <span>Analytics</span>
                    </div>
                    <div id="collapseAnalytics" className="collapse" data-bs-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/analytics/performance">
                                Performance
                            </Link>
                            <Link className="collapse-item fa-" to="/analytics">
                                Events Overview
                            </Link>
                            <Link className="collapse-item fa-" to="/analytics">
                                Events Funnel
                            </Link>
                            <Link className="collapse-item fa-" to="/analytics">
                                Crashlytics
                            </Link>
                            <Link className="collapse-item fa-" to="/analytics">
                                User Attributes
                            </Link>
                            <Link className="collapse-item fa-" to="/analytics">
                                User Journey
                            </Link>
                        </div>
                    </div>
                </li>
            </RestrictAccess>

            <hr className="sidebar-divider" />

            <RestrictAccess requiredLvl={3}>
                <li className="nav-item">
                    <div
                        className="nav-link collapsed"
                        type="button"
                        role="tab"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseAdmin"
                        aria-expanded="true"
                        aria-controls="collapseAdmin"
                    >
                        <i className="fas fa-fw fa-user"></i>&nbsp;
                        <span>Admin</span>
                    </div>
                    <div id="collapseAdmin" className="collapse" data-bs-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/admin/playable/add">
                                Add new playable
                            </Link>
                            <Link className="collapse-item" to="/admin/playable/edit">
                                Manage playables
                            </Link>
                            <Link className="collapse-item" to="/admin/repositories">
                                Repositories
                            </Link>
                        </div>
                    </div>
                </li>
            </RestrictAccess>
            <hr className="sidebar-divider" />
            <li className="nav-item mt-auto">
                <span className="nav-link pb-0">
                    <span>Playable Tool v{VERSION}</span>
                </span>
            </li>
        </>
    );
};

export default DashboardSidebar;
