const style1 =
    'display: block; pointer-events: none; color: rgb(0, 255, 0); font-size: 45px; font-weight: 300; position: fixed; right: 0px; bottom: 0px; z-index: 2300; font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; background-color: rgba(0, 0, 0, 0.26); padding: 5px 17px; width: 37px;';

// const style2="display: block; pointer-events: none; color: #00ff00; font-size: 45px; font-weight: 900; position: fixed; right: 3px; bottom: 0px; z-index: 2300; -webkit-text-stroke-width: 1.4px; -webkit-text-stroke-color: #000000; font-family: monospace;";

const fps_meter = `
gp.once('render', function() {

	var indicator = document.createElement("div");
	indicator.style.cssText = '${style1}';
	window.el("gscalable").appendChild(indicator);

	gp.on('second', function() {
		indicator.innerText = gp.fps;
	})
});
`;

export { fps_meter };
