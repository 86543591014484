/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import Select from 'react-select';
import { getRepositories } from '../../services/repository';
import { utils } from '@gpg-web/utils';

const RepositorySelect = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getRepositories()
            .then((result) => {
                setOptions(
                    result.map((e) => {
                        return { value: e._id, label: e.name };
                    })
                );
            })
            .catch(utils.hintError);
    }, []);

    return (
        <Select
            value={options.find((e) => e.value === props.value)}
            name="repository"
            isDisabled={props.disabled}
            onChange={(e) => {
                props.onChange && props.onChange(e.value);
            }}
            options={options}
            placeholder="Choose Repository"
            className="basic-single"
            classNamePrefix="select"
        />
    );
};

export { RepositorySelect };
