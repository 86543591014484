import { useState, useContext, useEffect } from 'react';
import { useToaster, Message, Placeholder } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useApp } from '../../services/app';
import { getSDKRepository, getRepository } from '../../services/repository';
import { Playable } from '../../playground';
import { layoutContext, playgroundContext } from '../../services/contexts';
import { getScene } from '../../services/app';
import { utils } from '@gpg-web/utils';

import ConfigPanel from './ConfigPanel';
import PlayPanel from './PlayPanel';
import ControlPanel from './ControlPanel';
import CreativesProvider from './Creatives/Provider';

let timer,
    playableId = 0;

const PlaygroundPage = () => {
    const { tooglePlaygroundView } = useContext(layoutContext);
    const { setScene, setCreative } = useContext(playgroundContext);
    const app = useApp();
    let { sceneId } = useParams();
    sceneId = Number(sceneId);
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState(null);
    const toaster = useToaster();

    useEffect(() => {
        if (errors) {
            toaster.push(
                <Message showIcon type="error">
                    {errors}
                </Message>,
                { duration: 5000 }
            );
            setErrors(null);
        }
    }, [toaster, errors]);

    useEffect(() => {
        if (!(app && sceneId)) return;

        let currentPlayableId = -1;

        clearTimeout(timer);
        timer = setTimeout(async () => {
            try {
                utils.popup('Loading scene...');
                const scene = await getScene(sceneId);
                utils.popup('Loading SDK repository...');
                const sdk = await getSDKRepository();
                Playable.setSDKRepository(sdk);
                utils.popup('Loading scene repository...');
                const repository = await getRepository(scene.source.id);

                if (!repository.build) throw new Error('Wrong repository specified');

                scene.repository = repository;
                scene.build = repository.build;
                scene.app = app;

                setScene(scene);

                await Playable.load(scene);
                playableId++;
                currentPlayableId = playableId;

                setLoaded(true);
            } catch (err) {
                setErrors(err.message || err);
            }
        }, 200);

        return () => {
            clearTimeout(timer);
            if (playableId === currentPlayableId) {
                setLoaded(false);
                setCreative(null);
                setScene(null);
                Playable.destroy();
            }
        };
    }, [app, sceneId, setScene, setCreative]);

    useEffect(() => {
        tooglePlaygroundView(true);

        return () => {
            tooglePlaygroundView(false);
        };
    }, [tooglePlaygroundView]);

    useEffect(() => {
        if (loaded) Playable.init();
    }, [loaded]);

    return (
        <div className="container-fluid playground-container">
            <div className="row">
                <CreativesProvider>
                    <div className="control-panel col-lg-3 col-md-12 bg-gray-100">
                        {!loaded && (
                            <>
                                <Placeholder.Graph active className="mb-3" height={50} />
                                <Placeholder.Graph active height="100%" />
                            </>
                        )}
                        {loaded && <ControlPanel />}
                    </div>

                    <div
                        id="play-panel"
                        className="play-panel col-lg-5 col-md-12 text-center sticky-top bg-gray-100"
                    >
                        {!loaded && (
                            <>
                                <Placeholder.Graph active className="mb-3" height={50} />
                                <Placeholder.Graph active height="100%" />
                                <Placeholder.Graph active className="mt-3" height={50} />
                            </>
                        )}
                        {loaded && <PlayPanel />}
                    </div>

                    <div className="config-panel col-lg-4 col-md-12 sticky-top bg-gray-100">
                        {!loaded && (
                            <>
                                <Placeholder.Graph active className="mb-3" height={50} />
                                <Placeholder.Graph active height="100%" />
                            </>
                        )}
                        {loaded && <ConfigPanel />}
                    </div>
                </CreativesProvider>
            </div>
        </div>
    );
};

export default PlaygroundPage;
