import { useEffect, useState } from 'react';
import { Row, Col, Panel, Placeholder } from 'rsuite';

function diffPercent(a, b) {
    return Math.round(100 - (a / b) * 100);
}

const Card = (props) => {
    const { data } = props;

    return (
        <Panel
            onClick={props.onClick}
            bordered
            role="button"
            className={props.active ? 'bg-white shadow-sm rounded-4' : 'rounded-4'}
        >
            <div className="pb-2">
                <div>{props.title}</div>
            </div>
            {data && (
                <div className="text-center">
                    <h2 className="fw-bold">
                        <i
                            className={
                                'fas fs-4 me-3 align-top pt-2 ' +
                                (data.diff < 0
                                    ? 'fa-angle-double-down text-danger'
                                    : 'fa-angle-double-up text-success')
                            }
                        />
                        {data.value}
                    </h2>
                    <div className="text-center">
                        <div></div>
                        <div>
                            <b>
                                {Math.abs(data.diff)}% {data.diff < 0 ? 'down' : 'up'}
                            </b>{' '}
                            from previous period
                        </div>
                    </div>
                </div>
            )}
            {!data && <Placeholder.Paragraph />}
        </Panel>
    );
};

const PerformanceCards = (props) => {
    const { activeCard, setActiveCard, loading, data } = props;

    const [values, setValues] = useState({});

    useEffect(() => {
        if (!data) return;

        let impressions = 0;
        let engagment = 0;
        let finish = 0;
        let cta = 0;

        let prev_impressions = data.prev.i;
        let prev_engagment = data.prev.e;
        let prev_finish = data.prev.f;
        let prev_cta = data.prev.c;

        let diff_impressions = 0;
        let diff_engagment = 0;
        let diff_finish = 0;
        let diff_cta = 0;

        data.list.forEach((row) => {
            impressions += row.i;
            engagment += row.e;
            finish += row.f;
            cta += row.c;
        });

        if (impressions) {
            engagment = (engagment / impressions) * 100;
            finish = (finish / impressions) * 100;
            cta = (cta / impressions) * 100;
        } else {
            engagment = -1;
            finish = -1;
            cta = -1;
        }

        if (prev_impressions) {
            prev_engagment = (prev_engagment / prev_impressions) * 100;
            prev_finish = (prev_finish / prev_impressions) * 100;
            prev_cta = (prev_cta / prev_impressions) * 100;

            if (impressions) {
                diff_impressions = diffPercent(prev_impressions, impressions);
                diff_engagment = diffPercent(prev_engagment, engagment);
                diff_finish = diffPercent(prev_finish, finish);
                diff_cta = diffPercent(prev_cta, cta);
            } else {
                diff_impressions = -100;
                diff_engagment = Math.round(-prev_engagment);
                diff_finish = Math.round(-prev_finish);
                diff_cta = Math.round(-prev_cta);
            }
        } else {
            if (impressions) {
                diff_impressions = 100;
                diff_engagment = Math.round(engagment);
                diff_finish = Math.round(finish);
                diff_cta = Math.round(cta);
            }
        }

        setValues({
            impressions: impressions.toLocaleString(),
            engagment: engagment > -1 ? engagment.toFixed(2) + '%' : '-',
            finish: finish > -1 ? finish.toFixed(2) + '%' : '-',
            cta: cta > -1 ? cta.toFixed(2) + '%' : '-',
            diff_impressions,
            diff_engagment,
            diff_finish,
            diff_cta
        });
    }, [data]);

    return (
        <Row className="mt-3" gutter={16}>
            <Col md={6} sm={12} xs={24}>
                <Card
                    title="Impressions"
                    onClick={() => setActiveCard('impressions')}
                    active={activeCard === 'impressions'}
                    data={loading ? false : { value: values.impressions, diff: values.diff_impressions }}
                />
            </Col>
            <Col md={6} sm={12} xs={24}>
                <Card
                    title="Engagement"
                    onClick={() => setActiveCard('engagment')}
                    active={activeCard === 'engagment'}
                    data={loading ? false : { value: values.engagment, diff: values.diff_engagment }}
                />
            </Col>
            <Col md={6} sm={12} xs={24}>
                <Card
                    title="Completion Rate"
                    onClick={() => setActiveCard('finish')}
                    active={activeCard === 'finish'}
                    data={loading ? false : { value: values.finish, diff: values.diff_finish }}
                />
            </Col>
            <Col md={6} sm={12} xs={24}>
                <Card
                    title="Clicked to Store"
                    onClick={() => setActiveCard('cta')}
                    active={activeCard === 'cta'}
                    data={loading ? false : { value: values.cta, diff: values.diff_cta }}
                />
            </Col>
        </Row>
    );
};

export { PerformanceCards };
