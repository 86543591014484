/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

let modal = null;

const Promt = function (msg, cb, options) {
    options = options || {};

    const el = document.getElementById('promtModal');

    if (modal === null) {
        modal = new window.bootstrap.Modal(el);
    }

    const input = el.querySelector('#promtInputField');
    const inputLabel = el.querySelector('#promtInputFieldLabel');

    input.placeholder = options.placeholder || 'Type here';

    inputLabel.innerHTML = options.label || '';

    input.value = '';

    let result = false;

    const okB = el.querySelector('#promtModalOkButton');

    el.querySelector('#promtModalLabel').innerHTML = msg;

    okB.onclick = function () {
        result = input.value;
    };

    el.addEventListener(
        'hidden.bs.modal',
        function () {
            cb && cb(result);
        },
        { once: true }
    );

    modal.show();
};

export default Promt;
