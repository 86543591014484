import { createContext } from 'react';

const layoutContext = createContext({});

const playgroundContext = createContext(null);

const adDynamicContext = createContext({});

const analyticsContext = createContext({});

const creativeContext = createContext({});

export { layoutContext, playgroundContext, adDynamicContext, analyticsContext, creativeContext };
