/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useState } from 'react';
import { creativeContext } from '../../../services/contexts';
import { userContext } from '../../../services/user';
import { RestrictAccess } from '../../../components';
import { Spinner } from '@gpg-web/react';

import CreativeListItem from './ListItem';

const CreativesList = () => {
    const {
        list,
        selectedCreative,
        handleSelectCreative,
        handleRemoveCreative,
        handleDuplicateCreative,
        handleCreateCreative,
        handleLockCreative,
        handleDownload,
        handleAdNetworkConnect
    } = useContext(creativeContext);

    const [filter, setFilter] = useState("");

    const { lvl: userLvl, email: userEmail } = useContext(userContext);

    let displayList = [];

    if (list) {
        displayList = list.slice();

        if (filter.length > 0) {
            const _filter = filter.toLowerCase();
            displayList = displayList.filter(e => e.name.toLowerCase().includes(_filter));
        }
    }

    const _handleRemoveCreative = (e) => {
        const creativeId = Number(e.target.getAttribute('data-id'));
        handleRemoveCreative(creativeId);
    };

    const _handleDuplicateCreative = (e) => {
        const creativeId = Number(e.target.getAttribute('data-id'));
        handleDuplicateCreative(creativeId);
    };

    const _handleDownload = (e) => {
        const creativeId = Number(e.target.getAttribute('data-id'));
        const creative = list.find((e) => e._id === creativeId);
        handleDownload([creative]);
    };

    const _handleAdNetworkConnection = (e) => {
        const creativeId = Number(e.target.getAttribute('data-id'));
        handleAdNetworkConnect(creativeId);
    };

    const downloadAll = () => {
        handleDownload(displayList);
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <div className="tab-title">Creatives</div>
            </div>

            <RestrictAccess requiredLvl={1}>
                <div className="d-flex justify-content-end">
                    {list && list.length > 0 && userLvl > 1 && (
                        <button onClick={downloadAll} className="btn btn-sm btn-light text-nowrap">
                            Download All
                        </button>
                    )}

                    <button onClick={handleCreateCreative} className="btn btn-sm btn-light text-nowrap ms-1">
                        + Playable
                    </button>
                    {!!window.Playable.ad_dynamic && (
                        <button
                            onClick={() => handleCreateCreative(true)}
                            className="btn btn-sm btn-light text-nowrap ms-1"
                        >
                            + Dynamic Playable
                        </button>
                    )}
                </div>

                <hr />
            </RestrictAccess>

            {!list ? (
                <div className="text-muted d-flex align-items-center">
                    <Spinner /> &nbsp; Getting creatives ...
                </div>
            ) : (
                <div>
                    
                    <div onChange={(e) => setFilter(e.target.value.toLowerCase())} value={filter} className="px-1 mb-3"><input type="search" placeholder="Search" className="form-control form-control-sm" /></div>
                    
                    <ul className="list-group list-group-flush">
                        {filter.length === 0 && <li
                            type="button"
                            onClick={(e) => handleSelectCreative(0)}
                            className={
                                'list-group-item d-flex align-items-center py-2 list-group-item-action ' +
                                (!selectedCreative ? ' active' : '')
                            }
                        >
                            <div className="me-auto">
                                <div className="fw-bold">Default</div>
                            </div>
                        </li>}
                        {displayList.map((e) => {
                            return (
                                <CreativeListItem
                                    isSelected={selectedCreative && selectedCreative._id === e._id}
                                    canRemove={e.created_by === userEmail || userLvl > 1}
                                    key={e._id}
                                    onAdNetworkConnect={_handleAdNetworkConnection}
                                    onDownload={_handleDownload}
                                    onRemove={_handleRemoveCreative}
                                    onDuplicate={_handleDuplicateCreative}
                                    onSelect={handleSelectCreative}
                                    onLock={handleLockCreative}
                                    data={e}
                                />
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CreativesList;
