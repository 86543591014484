import axios from 'axios';

export const getCreatives = (scene) => {
    return axios.get('/api/creative/list', {
        params: {
            scene: scene
        }
    });
};

let adNetworksCache = {};
export const getAdNetworkCreatives = (params) => {
    let key = params.storeId + params.network;

    if (adNetworksCache[key]) return Promise.resolve(adNetworksCache[key]);

    return axios.get('/api/creative/network-list', { params }).then((res) => {
        adNetworksCache[key] = res;
        return res;
    });
};

export const uploadUADashboardAssets = (params) => {
    return axios.post('/api/creative/upload-ua-dashboard', params);
};

const cache = {};

export const getCreative = (creativeId) => {
    if (cache[creativeId]) return Promise.resolve(cache[creativeId]);

    return axios.get('/api/creative/' + creativeId).then((res) => {
        cache[res._id] = res;
        return res;
    });
};

export const addCreative = (data) => {
    return axios.post('/api/creative', data).then((res) => {
        cache[res._id] = res;
        return res;
    });
};

export const copyCreative = (data) => {
    return axios.post('/api/creative/copy', data).then((res) => {
        cache[res._id] = res;
        return res;
    });
};
export const updateCreative = (creativeId, data) => {
    return axios.put('/api/creative/' + creativeId, data).then((res) => {
        if (cache[res._id]) Object.assign(cache[res._id], res);
        else cache[res._id] = res;

        return res;
    });
};

export const deleteCreative = (creativeId) => {
    return axios.delete('/api/creative/' + creativeId);
};
