/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import utils from './utils';
import './banner';
import './scss/index.scss';
import 'configer.js/dist/style.css';
import bootstrap from '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AppRouter } from './AppRouter.js';
import axios from 'axios';
import Hosting from './services/hosting';

import { logout } from './services/user';

window.bootstrap = bootstrap;
window.u = utils;
window.axios = axios;
window.Hosting = Hosting;

window.detectAdBlock = async function () {
    let adBlockEnabled = false;
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    try {
        await fetch(new Request(googleAdUrl)).catch((_) => (adBlockEnabled = true));
    } catch (e) {
        adBlockEnabled = true;
    }

    if (adBlockEnabled) {
        utils.alert(
            '<span class="text-danger"><div class="fw-bold mb-3 h3"><i class="fas fa-shield-alt me-2"></i> AdBlock DETECTED !</div> We noticed you\'re using AdBlock.<br/>Please disable AdBlock, as it may cause a platform errors. <div class="mt-2">Refresh this page after you done.</div></span>'
        );
    }

    return adBlockEnabled;
};

axios.interceptors.response.use(
    function (response) {
        // utils.popup('hide');
        return response.data;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            const redirect = window.location.pathname + window.location.search;

            logout(redirect);
        }

        utils.popup('hide');

        let err = 'Unknown error';

        if (error.response && error.response.data && error.response.data.msg) {
            err = error.response.data.msg;
        }

        return Promise.reject(err);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <RouterProvider router={AppRouter} />
    // </React.StrictMode>
);
