/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import JSZip from 'jszip';
import buildHTML from './buildHTML';
import dapiSDK from './libs/dapi';
import nucleoSDK from './libs/nucleo';
import configurator from './configurator';

function generateDemo() {
    configurator.init({ local: true });
    configurator.general();

    // Playable.emit("pregenerating", configurator);

    configurator.replace('{GPP_NETWORK}', 'demo');
    // configurator.replace('{PLAYABLE_DATE}', 0);

    // Playable.emit("generating", configurator);

    const content = buildHTML();

    return content;
}

function generateZipForMintegral(content, title) {
    var zip = new JSZip();
    // var _mac_fold = zip.folder("__MACOSX");
    var assets = zip.folder(title);
    assets.file(title + '.html', content);

    return zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
            level: 9
        }
    });
}

function generateZipForTikTok(content) {
    var zip = new JSZip();
    zip.file('index.html', content);
    zip.file('config.json', '{"playable_orientation":0}');

    return zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
            level: 9
        }
    });
}

function generatePlaybleIntoZip(content) {
    var zip = new JSZip();
    zip.file('index.html', content);

    return zip.generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
            level: 9
        }
    });
}

async function generateProductionAsync(option) {
    window.log('generating playable...');

    option = option || {};

    if (option.dontInitConfigurator !== true) {
        configurator.init(option);
    }

    configurator.general();

    // Playable.emit("pregenerating", configurator);

    var network = configurator.network;

    // Playable.emit("generating", configurator);

    if (network === 'mytarget' || network === 'moloco') {
        configurator.replace('{GPP_NETWORK}', 'facebook');
    } else if (network === 'pangle') {
        configurator.replace('{GPP_NETWORK}', 'toutiao');
    } else if (network === 'adwords') {
        configurator.replace('{GPP_NETWORK}', 'google');
    } else if (network === 'ironsource_dapi' || network === 'ironsource_nucleo') {
        configurator.replace('{GPP_NETWORK}', 'ironsource');
    } else {
        configurator.replace('{GPP_NETWORK}', network);
    }

    var networkExtraLib = '';

    if (network === 'ironsource_dapi') {
        networkExtraLib = dapiSDK;
    } else if (network === 'ironsource_nucleo') {
        networkExtraLib = nucleoSDK;
    } else if (network === 'adwords') {
        networkExtraLib =
            '<script id="google_tag">var clickTag = "http://www.google.com"; clickTag = (/android/i.test(navigator.userAgent))? "' +
            configurator.google_play_url +
            '" : "' +
            configurator.app_store_url +
            '";</script>';
        configurator.body.end.push(
            '<script src="https://tpc.googlesyndication.com/pagead/gadgets/html5/api/exitapi.js" type="text/javascript"></script>'
        );
    } else if (network === 'pangle') {
        configurator.body.end.push(
            '<script src="https://sf3-ttcdn-tos.pstatp.com/obj/union-fe-nc/playable/sdk/playable-sdk.js"></script>'
        );
    } else if (network === 'toutiao') {
        configurator.body.end.push(
            '<script src="https://sf1-ttcdn-tos.pstatp.com/obj/ttfe/union/playable/sdk/index.b5662ec443f458c8a87e.js"></script>'
        );
    }

    configurator.head.begin.push(networkExtraLib);

    const content = buildHTML();

    const generated_playable_title = option.title || configurator.title();

    // Playable.emit("postgenerating", configurator);

    const result = {
        title: generated_playable_title,
        google_play_url: configurator.google_play_url,
        app_store_url: configurator.app_store_url,
        extension: 'html',
        blobType: 'text/plain;charset=utf-8',
        content: null,
        blob: null
    };

    let blob = null;

    if (option.return_zip !== false) {
        if (network === 'adwords' || network === 'mytarget') {
            blob = await generatePlaybleIntoZip(content);
        } else if (network === 'mintegral') {
            blob = await generateZipForMintegral(content, generated_playable_title);
        } else if (network === 'vungle') {
            blob = await generatePlaybleIntoZip(content);
        } else if (network === 'pangle') {
            blob = await generateZipForTikTok(content);
        } else if (network === 'toutiao') {
            blob = await generateZipForTikTok(content);
        }
    }

    if (blob) {
        result.extension = 'zip';
        result.blob = blob;
        result.blobType = blob.type;
    } else {
        result.content = content;
    }

    result.fileName = result.title + '.' + result.extension;

    return result;
}

function generateTitle(options) {
    // if (ignoreNetwork)
    // {
    //     configurator.options.ignore_network_title = true;
    // }

    configurator.init(options);
    configurator.general();
    const title = configurator.title();

    // if (ignoreNetwork)
    // {
    //     configurator.options.ignore_network_title = false;
    // }

    return title;
}

const Builder = {
    demo: generateDemo,
    production: generateProductionAsync,
    title: generateTitle
};

export default Builder;
