import { useState, useEffect, useContext } from 'react';
import {
    Container,
    Content,
    Header,
    Placeholder,
    IconButton,
    Breadcrumb,
    Table,
    Whisper,
    Dropdown,
    Popover,
    Button,
    Grid,
    Row,
    Col,
    Message,
    Divider,
    Panel,
    useToaster,
    ButtonToolbar,
    Tag
} from 'rsuite';
import { useApp, getScenes, appConext, deleteScene } from '../../services/app';
import { getMember } from '../../services/organization';
import { getRepositories } from '../../services/repository';
import { SceneTypes } from '../../consts';
import { date } from '@gpg-web/utils';
import user from '../../img/undraw_profile.svg';
import { useNavigate, Link } from 'react-router-dom';
import PlusIcon from '@rsuite/icons/Plus';
import { EditAppModal } from './EditAppModal';
import { EditSceneModal } from './EditSceneModal';
import { utils } from '@gpg-web/utils';

const { Column, HeaderCell, Cell } = Table;

const ScenesListPage = () => {
    const app = useApp();

    const { resources } = useContext(appConext);

    const history = useNavigate();
    const toaster = useToaster();

    const [sortColumn, setSortColumn] = useState('updated_at');
    const [sortType, setSortType] = useState('desc');
    const [rows, setRows] = useState([]);
    const [scenes, setScenes] = useState(null);
    const [repos, setRepos] = useState(null);
    const [editingApp, setEditingApp] = useState(null);
    const [editingScene, setEditingScene] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (!app) return;

        getScenes(app._id)
            .then((list) => {
                getRepositories()
                    .then((repos) => {
                        setScenes(list);
                        setRepos(repos);
                    })
                    .catch(utils.hintError);
            })
            .catch(utils.hintError);
    }, [app]);

    useEffect(() => {
        if (!(scenes && repos && resources)) return;

        setRows(
            scenes.map((e) => {
                const creator = getMember(e.updated_by);
                const repo = repos.find((repo) => repo._id === e.source.id);

                const hasScene = repo.build?.scenes?.find((sourceScene) => sourceScene.id === e.source.scene);
                const creatives = resources.creatives.filter((c) => c.size > 0 && c.scene === e._id);
                const totalSzie = creatives.reduce((a, b) => a + b.size, 0);

                const tags = e.tags || [];

                return {
                    _id: e._id,
                    name: e.name,
                    app: e.app,
                    type: e.type,
                    tags: e.tags || [],
                    hasScene: hasScene,
                    updated_by: creator.name,
                    creatives: creatives.length,
                    updated_at: date(e.updated_at, 'ago-1'),
                    sort_updated_at: new Date(e.updated_at),
                    sort_source: e.source.type + repo.name,
                    sort_tags: tags.length,
                    size: creatives.length > 0 ? Math.floor(totalSzie / creatives.length) : 0,
                    source: {
                        type: e.source.type,
                        scene: e.source.scene,
                        id: e.source.id,
                        repository: repo
                    }
                };
            })
        );
    }, [scenes, repos, resources]);

    const getData = () => {
        if (sortColumn && sortType) {
            let sortBy = sortColumn;
            return rows.sort((a, b) => {
                if (sortBy === 'updated_at') sortBy = 'sort_updated_at';
                if (sortBy === 'created_at') sortBy = 'sort_created_at';

                let x = a[sortBy];
                let y = b[sortBy];

                if (!isNaN(x)) x = Number(x);
                if (!isNaN(y)) y = Number(y);

                if (sortType === 'asc') {
                    return x < y ? -1 : 1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }
        return rows;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const handleEdit = (e) => {
        setEditingScene(e);
        setModalOpen(true);
    };

    const handleDelete = (e) => {
        const sceneId = e._id;

        utils.confirm('Are you sure you want to delete this scene?', (yes) => {
            if (!yes) return;

            utils.popup('Deleting...');
            deleteScene(sceneId)
                .then(() => {
                    utils.popup('hide');
                    toaster.push(
                        <Message showIcon type="success">
                            Deleted
                        </Message>,
                        { duration: 2000 }
                    );
                    utils.remove(scenes, (e) => e._id === sceneId);
                    setScenes(scenes.slice());
                })
                .catch((err) => {
                    toaster.push(
                        <Message showIcon type="error">
                            {err}
                        </Message>,
                        { duration: 5000 }
                    );
                });
        });
    };

    return (
        <Container className="mt-3 mx-3">
            <EditAppModal open={!!editingApp} editing={editingApp} onClose={() => setEditingApp(null)} />
            <EditSceneModal
                open={modalOpen}
                editing={editingScene}
                setScenes={setScenes}
                scenes={scenes}
                onClose={() => setModalOpen(false)}
            />
            <Header className="d-flex justify-content-between">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Dashboard</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/apps">Apps</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{app ? app.name : 'Loading...'}</Breadcrumb.Item>
                </Breadcrumb>

                <ButtonToolbar>
                    <Button
                        disabled={!app}
                        onClick={() => setEditingApp(app)}
                        startIcon={<i className="fas fa-edit fa-sm me-1" />}
                    >
                        Edit App
                    </Button>
                    <Button
                        disabled={!app}
                        onClick={() => {
                            setEditingScene(null);
                            setModalOpen(true);
                        }}
                        startIcon={<PlusIcon />}
                        appearance="primary"
                    >
                        New Scenario
                    </Button>
                </ButtonToolbar>
            </Header>

            <Content>
                {!app && <Placeholder.Paragraph rows={2} graph="image" active />}
                {app && (
                    <Grid fluid>
                        <Row className="show-grid">
                            <Col lg={2} md={3} sm={3} xs={12}>
                                <div className="me-4">
                                    <img
                                        width="100%"
                                        className="rounded-3"
                                        alt="application icon"
                                        src={utils.staticUrl(app.picture)}
                                    />
                                </div>
                            </Col>
                            <Col lg={22} md={21} sm={21} xs={12}>
                                <h3>{app.name}</h3>
                                <div className="d-flex" style={{ fontSize: '0.65rem' }}>
                                    <a
                                        className="text-decoration-none text-gray-500"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={app.app_store_url || null}
                                    >
                                        <i className="fab fa-sm fa-app-store-ios me-2" />
                                        Apple App Store
                                    </a>
                                    <span className="mx-2 text-gray-500"> | </span>
                                    <a
                                        className="text-decoration-none text-gray-500"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={app.google_play_url || null}
                                    >
                                        <i className="fab fa-sm fa-google-play me-2" />
                                        Google Play Store
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                )}

                <Panel header="Scenarios">
                    <Table
                        autoHeight
                        onRowClick={(data, e) => {
                            if (e.target.tagName === 'BUTTON' || e.target.tagName === 'LI') return;

                            if (data.hasScene)
                                history('/apps/' + data.app + '/scenes/' + data._id + '/playground');
                            else
                                toaster.push(
                                    <Message showIcon type="error">
                                        Source scene not found
                                    </Message>,
                                    { duration: 5000 }
                                );
                        }}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        rowHeight={70}
                        onSortColumn={handleSortColumn}
                        loading={!repos}
                    >
                        <Column role="button" flexGrow={1} fixed verticalAlign="middle" align="center">
                            <HeaderCell>ID</HeaderCell>
                            <Cell dataKey="_id" />
                        </Column>
                        <Column
                            role="button"
                            minWidth={170}
                            fixed
                            flexGrow={3}
                            sortable
                            verticalAlign="middle"
                        >
                            <HeaderCell>Name</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                        <Column role="button" flexGrow={2} minWidth={155} sortable verticalAlign="middle">
                            <HeaderCell>Type</HeaderCell>
                            <TypeCell dataKey="type" />
                        </Column>
                        <Column role="button" flexGrow={2} minWidth={180} sortable verticalAlign="middle">
                            <HeaderCell>Tags</HeaderCell>
                            <TagsCell dataKey="sort_tags" />
                        </Column>
                        <Column role="button" minWidth={210} flexGrow={3} sortable verticalAlign="middle">
                            <HeaderCell>Source</HeaderCell>
                            <SourceCell dataKey="sort_source" />
                        </Column>
                        <Column role="button" minWidth={90} flexGrow={1} sortable verticalAlign="middle">
                            <HeaderCell>Size</HeaderCell>
                            <SizeCell dataKey="size" />
                        </Column>
                        <Column role="button" flexGrow={2} minWidth={120} sortable verticalAlign="middle">
                            <HeaderCell>Updated by</HeaderCell>
                            <Cell dataKey="updated_by" />
                        </Column>
                        <Column role="button" flexGrow={3} minWidth={170} sortable verticalAlign="middle">
                            <HeaderCell>Updated</HeaderCell>
                            <Cell dataKey="updated_at" />
                        </Column>
                        <Column verticalAlign="middle" fixed="right">
                            <HeaderCell>Actions</HeaderCell>
                            <ActionsCell history={history} onEdit={handleEdit} onDelete={handleDelete} />
                        </Column>
                    </Table>
                </Panel>
            </Content>
        </Container>
    );
};

const SizeCell = ({ rowData, ...props }) => {
    return <Cell {...props}>{rowData.size > 0 ? utils.KBtoStr(rowData.size) : '-'}</Cell>;
};

const ActionsCell = ({ rowData, onEdit, onDelete, history, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                onClick={() =>
                    history(
                        '/analytics/performance?filter:app=' + rowData.app + '&filter:scene=' + rowData._id
                    )
                }
                appearance="subtle"
                icon={<i className="fas fa-chart-line pe-none" />}
            />

            <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={(props, ref) => {
                    const { className, onClose } = props;

                    return (
                        <Popover ref={ref} className={className} style={{ width: '150px' }} full>
                            <Dropdown.Menu onSelect={(key, e) => onClose()}>
                                <Dropdown.Item onSelect={() => onEdit(rowData)}>Edit</Dropdown.Item>
                                <Dropdown.Item onSelect={() => onDelete(rowData)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Popover>
                    );
                }}
            >
                <IconButton appearance="subtle" icon={<i className="fas fa-ellipsis-h pe-none" />} />
            </Whisper>
        </Cell>
    );
};

const TagsCell = ({ rowData, ...props }) => {
    const limited = rowData.tags.length > 2;

    const speaker = (
        <Popover style={{ width: '350px' }}>
            {rowData.tags.map((e) => (
                <Tag key={e} className="m-1">
                    {e}
                </Tag>
            ))}
        </Popover>
    );

    return (
        <Cell {...props} style={{ padding: '0 10px 0 10px' }}>
            <Whisper placement="top" trigger={limited ? 'hover' : 'none'} speaker={speaker}>
                <div className="text-wrap">
                    {rowData.tags.slice(0, 2).map((e) => (
                        <Tag key={e} className="m-1">
                            {e}
                        </Tag>
                    ))}
                    {limited && <Tag className="m-1">+{rowData.tags.length - 2}</Tag>}
                </div>
            </Whisper>
        </Cell>
    );
};

const TypeCell = ({ rowData, ...props }) => {
    const type = SceneTypes[rowData.type] || {
        name: 'Unknown',
        icon: 'fas fa-exclamation-triangle',
        color: 'secondary'
    };
    return (
        <Cell {...props}>
            <div
                className={
                    'fw-normal pb-1 lh-sm rounded-pill badge badge-lg bg-' +
                    type.color +
                    '-subtle text-' +
                    type.color +
                    '-emphasis'
                }
            >
                <i className={type.icon + ' me-1'} />
                {type.name} ({rowData.creatives})
            </div>
        </Cell>
    );
};

const AppCommitInfo = (props) => {
    const { repository } = props;

    let commitMessage = '- No data';
    let username = 'Somebody';
    let userImage = user;
    if (repository.commit) {
        commitMessage = '- ' + repository.commit.message;
        if (repository.commit.pusher) {
            username = repository.commit.pusher.name;
        }
    }

    if (repository.pusher) {
        if (repository.pusher.name && repository.pusher.name !== '') {
            username = repository.pusher.name;
        }
        if (repository.pusher.avatar_url && repository.pusher.avatar_url !== '')
            userImage = repository.pusher.avatar_url;
    }

    const update = date(repository.updated_at || new Date(), 'ago');

    return (
        <div className="d-flex flex-column commit-info">
            <span
                className="commit-message"
                data-toggle="tooltip"
                data-placement="top"
                title={commitMessage.length > 35 ? commitMessage : ''}
            >
                {commitMessage}
            </span>
            <div className="d-flex flex-row mt-1 user-row text-muted">
                <img alt="github-user" className="commit-user mx-1" src={userImage} />
                <span>&nbsp;{username}</span>
                <span>&nbsp;commited {update}</span>
            </div>
        </div>
    );
};

const SourceCell = ({ rowData, ...props }) => {
    const repository = rowData.source.repository;

    const hasScene = rowData.hasScene;

    const speaker = (
        <Popover style={{ width: '350px' }}>
            <h6>Commit info</h6>
            {hasScene && <AppCommitInfo repository={repository} />}
            {!hasScene && (
                <>
                    <AppCommitInfo repository={repository} />
                    <Divider className="my-3" />
                    <div className="text-danger">
                        <h6>
                            <i className="fas fa-exclamation-circle me-2 fa-sm" />
                            ERROR
                        </h6>
                        <div className="mt-2">
                            Source scene "{rowData.source.scene}" not found in {repository.name}
                        </div>
                        <div className="mt-1">Ask developer to fix it</div>
                    </div>
                </>
            )}
        </Popover>
    );

    return (
        <Cell {...props}>
            {rowData.source.type === 'repository' && (
                <Whisper delayOpen={300} placement="top" speaker={speaker}>
                    <a>
                        <i
                            className={
                                'fas me-2 align-middle ' +
                                (hasScene ? 'fa-code-branch text-muted' : 'fa-exclamation-circle text-danger')
                            }
                        />

                        {repository.name}
                    </a>
                </Whisper>
            )}
        </Cell>
    );
};

export default ScenesListPage;
