import { useEffect, useState } from 'react';
import DEBUGGER_CONSTATS from '../../../playground/Debugger/DEBUGGER_CONSTATS';
import PlayableDebugger from '../../../playground/Debugger';

const Requirement = (props) => {
    const { status } = props;

    let tc = '';
    let icon = 'fa-exclamation-triangle';

    if (status === 'success') {
        tc = 'text-success';
        icon = 'fa-check-circle';
    } else if (status === 'danger') {
        tc = 'text-danger';
        icon = 'fa-exclamation-circle';
    }

    return (
        <>
            <div className={'list-group-item py-2 ' + tc}>
                <i className={`fa debugger-list-icon ${icon}`} /> {props.name} : <span>{props.type}</span>
            </div>
        </>
    );
};

const RequirementsList = () => {
    const [reqStatus, setReqStatus] = useState([]);

    useEffect(() => {
        const reqCb = (g) => {
            setReqStatus(g);
        };

        PlayableDebugger.on('requirements', reqCb);

        return () => {
            PlayableDebugger.off('requirements', reqCb);
        };
    }, []);

    // const errors = reqStatus.reduce((c, v) => {
    //     if (v==="danger") c++;
    //     return c;
    // }, 0)

    // let errorCount = " "
    // let tS = ""
    // let icon = "fa-exclamation-triangle"
    // if (errors) {

    //     errorCount = " " + errors;
    //     tS = "text-danger"
    //     icon = "fa-exclamation-circle"
    // } else {
    //     tS = "text-success"
    //     icon = "fa-check-circle"
    // }

    return (
        <>
            <h5>Playable requirements</h5>

            <div className="list-group list-group-flush" id="req_list">
                {DEBUGGER_CONSTATS.PLAYABLE_GLOBALS.map((val, i) => {
                    return (
                        <Requirement
                            name={val.name}
                            type={val.type}
                            status={reqStatus[i]}
                            key={i}
                        ></Requirement>
                    );
                })}
            </div>
        </>
    );
};

export default RequirementsList;
