/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import { useContext } from 'react';
import { adDynamicContext } from '../../../services/contexts';

const ControlBar = () => {
    const { changed, publish } = useContext(adDynamicContext);

    return (
        <div
            className={
                'd-flex justify-content-center align-items-center pt-3 pb-2 mt-3 border-top w-100'
            }
        >
            <button disabled={!changed} onClick={publish} className={changed ? 'btn btn-success shadow-sm' : 'btn btn-light'}>
                Publish changes
            </button>
        </div>
    );
};

export default ControlBar;
