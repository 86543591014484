import { useEffect, useState } from 'react';
import PlayableDebugger from '../../../playground/Debugger';

const TechItem = (props) => {
    const { description, github, version, img, url, title } = props.value;

    return (
        <>
            <div className="card my-2">
                <div className="card-body">
                    <div className="d-flex mb-2">
                        <a className="" target="_blank" rel="noreferrer" href={url}>
                            <img className="rounded" alt="" width="50" src={img} />
                        </a>
                        <div className="ps-3">
                            <h5 className="card-title mb-0">{title + (version ? ' v' : '') + version}</h5>
                            <a
                                className="text-secondary text-muted"
                                target="_blank"
                                rel="noreferrer"
                                href={github}
                            >
                                GitHub link
                            </a>
                        </div>
                    </div>
                    <div className="card-text" style={{ fontSize: '14px' }}>
                        {description}
                    </div>
                </div>
            </div>
        </>
    );
};

const TechnologiesList = () => {
    const [list, setList] = useState([]);

    useEffect(() => {
        const techCb = (tl) => {
            setList(tl);
        };

        PlayableDebugger.on('technologies', techCb);

        return () => {
            PlayableDebugger.off('technologies', techCb);
        };
    }, []);

    return (
        <>
            <h5>Technologies</h5>
            <div className="list-group list-group-flush" id="tech_list" style={{ overflowX: 'hidden' }}>
                {list.map((value, i) => {
                    return <TechItem value={value} key={i}></TechItem>;
                })}
            </div>
        </>
    );
};

export default TechnologiesList;
