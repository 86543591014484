import { useState, useEffect } from 'react';
import { Table, Popover, Whisper, Divider, Loader } from 'rsuite';
import axios from 'axios';
import { utils, str } from '@gpg-web/utils';
import { DIMENSIONS } from './Dimensions';

const _DIMENSIONS = {};
for (let dim of DIMENSIONS) {
    _DIMENSIONS[dim.value] = dim.label;
}

const { Column, HeaderCell, Cell } = Table;

const cache = {};
const getAdNetworkStats = (network, start, end, creativeId) => {
    const key =
        network +
        creativeId +
        start.getYear() +
        start.getMonth() +
        start.getDate() +
        end.getYear() +
        end.getMonth() +
        end.getDate();

    console.log(network, creativeId);

    if (cache[key]) return Promise.resolve(cache[key]);

    return axios
        .post('/api/reports/performance/ad-network', {
            start: start.toISOString(),
            end: end.toISOString(),
            network,
            creativeId
        })
        .then((res) => {
            cache[key] = res;
            return res;
        });
};

let timer,
    networkReqTimer,
    requestId = 0;

const KPICell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div className="rs-table-cell-content">
            {rowData.i && (
                <>
                    <div>{((rowData[dataKey] / rowData.i) * 100).toFixed(2)}%</div>
                    <div className="fw-light text-muted">{rowData[dataKey]}</div>
                </>
            )}
        </div>
    </Cell>
);

const NumberCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        {rowData[dataKey].toLocaleString()}
    </Cell>
);

const SpendCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        {rowData.cpi && rowData.cpi.toFixed(2) + '$'}
    </Cell>
);

const IPMCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
        <Popover style={{ width: '250px' }} title="Ad Network data">
            {rowData.connections &&
                rowData.connections.map((e) => {
                    return (
                        <div title={e.name} key={e.network + e.id}>
                            {!e.stats && !e.err && <Loader size="xs" content=" " />}
                            {e.stats && <i className="fas fa-check-circle text-success me-1" />}
                            {e.err && <i className="fas fa-exclamation-circle text-danger me-1" />}
                            {e.network} | {str.trim(e.name, 20)}
                            {e.err && <div className="text-danger mb-2">{e.err}</div>}
                        </div>
                    );
                })}
            <Divider className="my-2" />

            {rowData.impressions ? (
                <div>
                    <div>
                        Impressions: <b>{rowData.impressions.toLocaleString()}</b>
                    </div>
                    <div>
                        Clicks: <b>{rowData.clicks.toLocaleString()}</b>
                    </div>
                    <div>
                        Installs: <b>{rowData.installs.toLocaleString()}</b>
                    </div>
                    <div>
                        Spend: <b>{rowData.spend.toLocaleString()}$</b>
                    </div>
                </div>
            ) : rowData.adError ? (
                <div class="text-danger">ERROR</div>
            ) : (
                <Loader content="Loading ..." />
            )}
        </Popover>
    );

    return (
        <Cell {...props} style={{ padding: 0 }}>
            {rowData.connections && rowData.connections.length > 0 && (
                <Whisper delayOpen={300} placement="topEnd" enterable speaker={speaker}>
                    <div>
                        {rowData.ipm !== undefined ? (
                            rowData.ipm.toFixed(2)
                        ) : rowData.adError ? (
                            <div class="text-danger">ERROR</div>
                        ) : (
                            <Loader center />
                        )}
                        <i className="fas fa-info-circle ms-2 fa-xs align-middle pb-1" />
                    </div>
                </Whisper>
            )}
        </Cell>
    );
};

const timeout = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const PerformanceTable = (props) => {
    const { loading, data, dimension, dimension2 } = props;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [sorting, setSorting] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (!data) return;

        let rows = [];

        for (let row of data.list) {
            let exists;

            if (row.dm2 !== undefined) {
                const dim1 = row.dm1;
                const dim2 = row.dm2;
                exists = rows.find((e) => e.dm1 === dim1 && e.dm2 === dim2);
                if (!exists) {
                    exists = { dm1: dim1, dm2: dim2, i: 0, e: 0, f: 0, c: 0, connections: row.connections };
                    rows.push(exists);
                }
            } else {
                const dim1 = row.dm1;
                exists = rows.find((e) => e.dm1 === dim1);
                if (!exists) {
                    exists = { dm1: dim1, i: 0, e: 0, f: 0, c: 0, connections: row.connections };
                    rows.push(exists);
                }
            }

            exists.i += row.i;
            exists.e += row.e;
            exists.f += row.f;
            exists.c += row.c;
        }

        setRows(rows);
    }, [data]);

    useEffect(() => {
        requestId++;
        const currentRequestId = requestId;

        if (!data) return;

        const requestFor = [];

        for (let row of data.list) {
            if (row.connections && row.connections.length > 0) {
                for (let adCreative of row.connections) {
                    // if (
                    //     requestFor.findIndex(
                    //         (e) => e.network + e.id === adCreative.network + adCreative.id
                    //     ) === -1
                    // ) {
                    adCreative.stats = null;
                    adCreative.err = null;
                    requestFor.push(adCreative);
                    // }
                }
            }
        }

        if (requestFor.length === 0) return;

        clearTimeout(networkReqTimer);
        networkReqTimer = setTimeout(async () => {
            function updateData() {
                setRows((rows) => {
                    for (let row of rows) {
                        const total = { clicks: 0, impressions: 0, installs: 0, spend: 0 };

                        if (row.connections && row.connections.length > 0) {
                            let errors = 0;
                            let stats = 0;
                            for (let adCreative of row.connections) {
                                if (adCreative.stats) {
                                    stats++;
                                    total.clicks += adCreative.stats.clicks;
                                    total.impressions += adCreative.stats.impressions;
                                    total.installs += adCreative.stats.installs;
                                    total.spend += adCreative.stats.spend;
                                } else if (adCreative.err) errors++;
                            }

                            row.adError = false;
                            if (errors + stats >= row.connections.length) row.adError = !!errors;
                        }

                        row.installs = total.installs;
                        row.clicks = total.clicks;
                        row.impressions = total.impressions;
                        row.spend = total.spend;
                        if (total.spend && total.installs) row.cpi = total.spend / total.installs;
                        if (total.installs && total.impressions)
                            row.ipm = (total.installs / total.impressions) * 1000;
                    }

                    return rows.slice();
                });
            }

            for (let adCreative of requestFor) {
                if (currentRequestId !== requestId) return;
                await timeout(adCreative.network === 'unity' ? 3000 : 1000);
                if (currentRequestId !== requestId) return;

                try {
                    const res = await getAdNetworkStats(
                        adCreative.network,
                        data.dateRange[0],
                        data.dateRange[1],
                        adCreative.id
                    );
                    if (res && res.length > 0) {
                        adCreative.stats = res[0];
                        updateData();
                    }
                } catch (err) {
                    adCreative.err = err;
                    updateData();
                    utils.hintError(err);
                }
            }
        }, 500);

        return () => {
            clearTimeout(networkReqTimer);
        };
    }, [data]);

    const getData = () => {
        if (sortColumn && sortType) {
            return rows.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return rows;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSorting(true);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setSorting(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const _rows = getData();

    return (
        <Table
            disabled
            height={Math.max(200, Math.min(520, _rows.length * 75))}
            minHeight={200}
            data={_rows}
            className="my-4"
            rowHeight={65}
            sortColumn={sortColumn}
            sortType={sortType}
            virtualized
            onSortColumn={handleSortColumn}
            loading={loading || sorting}
        >
            <Column minWidth={150} flexGrow={2} fixed sortable verticalAlign="middle">
                <HeaderCell>{_DIMENSIONS[dimension]}</HeaderCell>
                <Cell dataKey="dm1" />
            </Column>

            {dimension2 && (
                <Column minWidth={150} flexGrow={2} fixed sortable verticalAlign="middle">
                    <HeaderCell>{_DIMENSIONS[dimension2]}</HeaderCell>
                    <Cell dataKey="dm2" />
                </Column>
            )}

            <Column minWidth={100} flexGrow={2} sortable verticalAlign="middle">
                <HeaderCell>Impressions</HeaderCell>
                <NumberCell dataKey="i" />
            </Column>

            <Column minWidth={100} flexGrow={2} sortable verticalAlign="middle">
                <HeaderCell>Engagement</HeaderCell>
                <KPICell dataKey="e" />
            </Column>

            <Column minWidth={100} flexGrow={2} sortable verticalAlign="middle">
                <HeaderCell>Completion Rate</HeaderCell>
                <KPICell dataKey="f" />
            </Column>

            <Column minWidth={100} flexGrow={2} sortable verticalAlign="middle">
                <HeaderCell>Clicked to Store</HeaderCell>
                <KPICell dataKey="c" />
            </Column>

            <Column minWidth={80} flexGrow={1} sortable verticalAlign="middle">
                <HeaderCell>IPM</HeaderCell>
                <IPMCell dataKey="ipm" />
            </Column>

            <Column minWidth={80} flexGrow={1} sortable verticalAlign="middle">
                <HeaderCell>CPI</HeaderCell>
                <SpendCell dataKey="cpi" />
            </Column>
        </Table>
    );
};

export { PerformanceTable };
