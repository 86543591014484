import { utils, date } from '@gpg-web/utils';


import Loader from './Loader';
import Popup from './Popup';
import Hint from './Hint';
import Confirm from './Confirm';
import Promt from './Promt';
import Alert from './Alert';
import QrPreview from './QrPreview';
import Media from './Media';

window.date = date;

Object.assign(utils, {
    load: Loader,
    popup: Popup,
    hint: Hint,
    qrPreview: QrPreview,
    confirm: Confirm,
    promt: Promt,
    alert: Alert,
    media: Media,
    absoluteUrl: function (url) {
        if (typeof url === 'string') {
            return this.staticUrl(url);
        }

        return null;
    },
    staticUrl: function(url) {
        if (typeof url !== 'string') return null;
        
        if (!(url.startsWith('http://') || url.startsWith('https://'))) {

            if (!url.startsWith('/')) url = '/' + url;

            if (window.location.host === "playable.greenpandagames.com") {
                url = "https://static-playable.greenpandagames.com" + url;
            } else {
                url = "https://static-nonprod.greenpandagames.com" + url;
            }
        }

        return url;
    },

    kpis: {
        ctr: (cta, c) => (cta && c ? (Math.round((cta / c) * 100) / 100).toFixed(2) * 100 : 0)
    },
    hintError: function (err) {
        Hint(err, { alert: 'danger' });
    }
});

export default utils;
