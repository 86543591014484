import axios from 'axios';
import Builder from '../playground/Builder';
import { DEFAULT_DYNAMIC_AUTOMATION_CONFIG } from '../consts';

export const initDynamicData = async () => {
    const Playable = window.Playable;
    if (!Playable.ad_dynamic) throw new Error("This playable doesn't support Ad Dynamic");

    var htmlConfig = {};

    htmlConfig.ad_dynamic = {
        type: 'block',
        expanded: true,
        config: Playable.ad_dynamic,
        title: 'Ad Dynamic'
    };

    // htmlConfig.modules = {
    //     type: 'block',
    //     expanded: true,
    //     hidden: true,
    //     config: MODULES_DYNAMIC_CONFIG,
    //     title: 'Modules dynamic'
    // };

    const data = {
        config: JSON.stringify(htmlConfig),
        automation: DEFAULT_DYNAMIC_AUTOMATION_CONFIG
    };

    const tmp = Playable.ad_dynamic;
    Playable.ad_dynamic = false;
    const build = await Builder.production({ network: 'demo' });
    data.preview = build.content;
    Playable.ad_dynamic = tmp;

    return data;
};

export const getDynamicCreative = (creativeId) => {
    return axios.get('/api/dynamic/' + creativeId).then((res) => {
        res.dynamic.config = JSON.parse(res.dynamic.config);
        return res;
    });
};

export const updateDynamicCreative = (creativeId, data) => {
    return axios.put('/api/dynamic/' + creativeId, data);
};
