const icons = {
    warning: 'fa-exclamation-triangle',
    success: 'fa-check-circle',
    danger: 'fa-exclamation-circle'
};

const DebuggerCard = (prop) => {
    const min = prop.min === true;

    const tc = 'text-' + prop.status;
    const icon = icons[prop.status];

    if (min) {
        return (
            <>
                <div className="p-1 text-muted font-monospace">
                    <h6 className="text-start debugger-meter-text mb-0 ms-1">
                        {prop.title}: <span className="fw-bolder">{prop.value + ' ' + prop.label}</span>
                    </h6>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="card shadow-sm w-100">
                <div className={`card-header p-1 px-2`}>
                    <h6 className="m-0 d-inline">{prop.title}:</h6>
                    <i className={`m-1 ${tc} float-end fa ${icon}`} />
                </div>
                <div className={`card-body p-1`}>
                    <h5 className="card-title text-center debugger-text mb-0">
                        {prop.value}
                        <span className="">{' ' + prop.label}</span>
                    </h5>
                </div>
            </div>
        </>
    );
};

export default DebuggerCard;
