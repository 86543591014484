/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

const cache = {};

function enablePlayable(scene) {
    const CURRENT_PLAYABLE = cache[scene.repository._id];

    env.CURRENT_PLAYABLE = CURRENT_PLAYABLE;

    env.CURRENT_SCENE = null;

    const Playable = window.Playable;

    Playable.onbuild = CURRENT_PLAYABLE.onbuild;
    Playable.config = CURRENT_PLAYABLE.config;
    Playable.ad_dynamic = CURRENT_PLAYABLE.ad_dynamic;
    Playable.translations = CURRENT_PLAYABLE.translations;

    // Playable.context = {};
    // Object.assign(Playable.context, CURRENT_PLAYABLE.context);
    Playable.context = CURRENT_PLAYABLE.context;
}

function enableScene(scene) {
    const CURRENT_SCENE = cache[scene._id];

    const CURRENT_PLAYABLE = env.CURRENT_PLAYABLE;

    env.CURRENT_SCENE = CURRENT_SCENE;

    const Playable = window.Playable;

    Playable.onbuild = CURRENT_SCENE.onbuild !== undefined ? CURRENT_SCENE.onbuild : CURRENT_PLAYABLE.onbuild;
    Playable.config = CURRENT_SCENE.config !== undefined ? CURRENT_SCENE.config : CURRENT_PLAYABLE.config;
    Playable.ad_dynamic =
        CURRENT_SCENE.ad_dynamic !== undefined ? CURRENT_SCENE.ad_dynamic : CURRENT_PLAYABLE.ad_dynamic;
    Playable.translations =
        CURRENT_SCENE.translations !== undefined ? CURRENT_SCENE.translations : CURRENT_PLAYABLE.translations;

    Playable.context = CURRENT_SCENE.context;
    // Playable.context = {};
    // Object.assign(Playable.context, CURRENT_PLAYABLE.context);
    // Object.assign(Playable.context, CURRENT_SCENE.context);
}

function resetContext() {
    const Playable = window.Playable;

    // env.CURRENT_PLAYABLE = env.CURRENT_SCENE = null;

    Playable.onbuild =
        Playable.config =
        Playable.ad_dynamic =
        Playable.translations =
        Playable.context =
            void 0;
}

const env = {
    CURRENT_PLAYABLE: null,
    CURRENT_SDK: null,
    CURRENT_SCENE: null,
    cache,
    enablePlayable,
    enableScene,
    resetContext
};

export default env;
