import { useEffect, useState } from 'react';
import PlayableDebugger from '../../../playground/Debugger';
import DEBUGGER_CONSTATS from '../../../playground/Debugger/DEBUGGER_CONSTATS';

const SDKItem = (props) => {
    const { status } = props;

    let tc = '';
    let icon = 'fa-exclamation-triangle';

    if (status === 'success') {
        tc = 'text-success';
        icon = 'fa-check-circle';
    } else if (status === 'danger') {
        tc = 'text-danger';
        icon = 'fa-exclamation-circle';
    }

    return (
        <>
            <div className={'list-group-item debugger-list-item py-2 ' + tc}>
                <i className={`fa  debugger-list-icon ${icon}`} />
                {props.name}
            </div>
        </>
    );
};

const sdkCalls = DEBUGGER_CONSTATS.SDK_EVENTS.map((obj) => obj.name);

const SDKList = () => {
    const [callsStatus, setCallsStatus] = useState([]);

    useEffect(() => {
        const sdkCb = (callSt) => {
            setCallsStatus(callSt);
        };

        PlayableDebugger.on('sdk', sdkCb);

        return () => {
            PlayableDebugger.off('sdk', sdkCb);
        };
    }, []);

    return (
        <>
            <h5>Network SDK calls</h5>
            <div className="list-group list-group-flush">
                {sdkCalls.map((call, i) => {
                    return <SDKItem name={call} key={i} status={callsStatus[i]}></SDKItem>;
                })}
            </div>
        </>
    );
};

export default SDKList;
