/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React, { useContext, useEffect } from 'react';
import { adDynamicContext } from '../../../services/contexts';

import DeviceBar from '../../playground/PlayPanel/DeviceBar';

import ActionBar from './ActionBar';
import { restartPlayable } from '../utils';

// let prevId = '';
// let firstInit = true;

const PlayPanel = () => {
    const { preview, config } = useContext(adDynamicContext);

    useEffect(() => {
        if (!(preview && config)) return;

        const Playable = window.Playable;

        Playable.initDynamic();
        restartPlayable(preview, config);

        return () => {
            Playable.destroyDynamic();
        };
    }, [preview, config]);

    return (
        <div className="play-panel col-lg-5 col-md-12 text-center sticky-top bg-gray-100">
            <DeviceBar restart={() => restartPlayable(preview, config)} />

            <div className="playable-frame-block">
                <iframe title="Playable demo" id="playable-frame" src="about:blank" />
            </div>
            <ActionBar />
        </div>
    );
};

export default PlayPanel;
