import { useContext, useState, useEffect } from 'react';
import {
    Container,
    Panel,
    Header,
    IconButton,
    Breadcrumb,
    Table,
    Whisper,
    Dropdown,
    Popover,
    Button
} from 'rsuite';
import { appConext } from '../../services/app';
import { date, utils } from '@gpg-web/utils';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '@rsuite/icons/Plus';
import { EditAppModal } from './EditAppModal';

const { Column, HeaderCell, Cell } = Table;

const AppList = () => {
    const { apps, resources } = useContext(appConext);

    const history = useNavigate();

    const [sortColumn, setSortColumn] = useState('updated_at');
    const [sortType, setSortType] = useState('desc');
    const [rows, setRows] = useState([]);
    const [addingNew, setAddingNew] = useState(false);
    const [editingApp, setEditingApp] = useState(null);

    useEffect(() => {
        if (!(apps && resources)) return;

        const rows = apps.map((app) => {
            return {
                _id: app._id,
                name: app.name,
                updated_at: app.updated_at,
                picture: app.picture,
                app_store_url: app.app_store_url,
                google_play_url: app.google_play_url,
                scenes: resources.scenes.filter((e) => e.app === app._id).length
            };
        });

        setRows(rows);
    }, [apps, resources]);

    const getData = () => {
        if (sortColumn && sortType) {
            return rows.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (!isNaN(x)) x = Number(x);
                if (!isNaN(y)) y = Number(y);

                if (sortColumn === 'updated_at') {
                    x = new Date(x);
                    y = new Date(y);
                }

                if (sortType === 'asc') {
                    return x < y ? -1 : 1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }
        return rows;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const handleEdit = (e) => {
        setEditingApp(e);
        setAddingNew(true);
    };

    return (
        <Container className="mt-3 mx-3">
            <EditAppModal open={addingNew} editing={editingApp} onClose={() => setAddingNew(false)} />
            <Header className="d-flex justify-content-between">
                <h5>Apps Library</h5>
                <Button
                    onClick={() => {
                        setEditingApp(null);
                        setAddingNew(true);
                    }}
                    startIcon={<PlusIcon />}
                    appearance="primary"
                >
                    New App
                </Button>
            </Header>

            <Breadcrumb>
                <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Apps</Breadcrumb.Item>
            </Breadcrumb>

            <Panel bodyFill bordered>
                <Table
                    autoHeight
                    onRowClick={(data, e) =>
                        e.target.tagName !== 'BUTTON' &&
                        e.target.tagName !== 'LI' &&
                        history('/apps/' + data._id)
                    }
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    rowHeight={70}
                    onSortColumn={handleSortColumn}
                    loading={!apps}
                >
                    <Column role="button" flexGrow={2} minWidth={250} sortable verticalAlign="middle">
                        <HeaderCell>Name</HeaderCell>
                        <ImageCell dataKey="name" />
                    </Column>
                    <Column role="button" flexGrow={1} sortable verticalAlign="middle">
                        <HeaderCell>Scenarios</HeaderCell>
                        <Cell dataKey="scenes" />
                    </Column>
                    <Column role="button" flexGrow={1} sortable verticalAlign="middle">
                        <HeaderCell>Updated</HeaderCell>
                        <DateCell dataKey="updated_at" format="ago-1" />
                    </Column>
                    <Column verticalAlign="middle">
                        <HeaderCell>Actions</HeaderCell>
                        <ActionsCell history={history} onEdit={handleEdit} />
                    </Column>
                </Table>
            </Panel>
        </Container>
    );
};

const DateCell = ({ rowData, dataKey, format, ...props }) => (
    <Cell {...props}>{date(rowData[dataKey], format)}</Cell>
);

const ActionsCell = ({ rowData, onEdit, history, ...props }) => {
    return (
        <Cell {...props} className="link-group">
            <IconButton
                onClick={() => history('/analytics/performance?filter:app=' + rowData._id)}
                appearance="subtle"
                icon={<i className="fas fa-chart-line pe-none" />}
            />

            <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={(props, ref) => {
                    const { className, onClose } = props;

                    return (
                        <Popover ref={ref} className={className} style={{ width: '150px' }} full>
                            <Dropdown.Menu onSelect={(key, e) => onClose()}>
                                <Dropdown.Item onSelect={() => onEdit(rowData)}>Edit</Dropdown.Item>
                            </Dropdown.Menu>
                        </Popover>
                    );
                }}
            >
                <IconButton appearance="subtle" icon={<i className="fas fa-ellipsis-h pe-none" />} />
            </Whisper>
        </Cell>
    );
};

const ImageCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
        <div className="d-flex align-items-center">
            <img className="rounded me-3" alt="application icon" src={utils.staticUrl(rowData.picture)} width="40" />
            <div>{rowData.name}</div>
        </div>
    </Cell>
);

export default AppList;
