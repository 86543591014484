import { useContext } from 'react';
import { adDynamicContext } from '../../../services/contexts';
import ManagePanel from './ManagePanel';
import LogsPanel from './LogsPanel';
import ConfigPanel from './ConfigPanel';
import AnalyticsPanel from './Analytics';

const Configs = () => {
    const { selectedVariation } = useContext(adDynamicContext);

    return (
        <>
            <div className="nav nav-tabs" role="tablist">
                <button
                    title="Variation options"
                    className="nav-link active"
                    id="nav-dynamic-variation-options-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dynamic-variation-options"
                    type="button"
                    role="tab"
                    aria-controls="nav-dynamic-variation-options"
                    aria-selected="true"
                >
                    <i className="fas fa-gamepad" />
                </button>

                <button
                    title="Manage variation"
                    className="nav-link"
                    disabled={!selectedVariation}
                    id="nav-dynamic-manage-variation-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dynamic-manage-variation"
                    type="button"
                    role="tab"
                    aria-controls="nav-dynamic-manage-variation"
                    aria-selected="false"
                >
                    <i className="fas fa-cogs" />
                </button>

                <button
                    title="Variation analytics"
                    className="nav-link"
                    disabled={!selectedVariation}
                    id="nav-dynamic-analytics-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dynamic-analytics"
                    type="button"
                    role="tab"
                    aria-controls="nav-dynamic-analytics"
                    aria-selected="false"
                >
                    <i className="far fa-chart-bar" />
                </button>

                <button
                    title="Logs"
                    className="nav-link"
                    disabled={!selectedVariation}
                    id="nav-dynamic-logs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-dynamic-logs"
                    type="button"
                    role="tab"
                    aria-controls="nav-dynamic-logs"
                    aria-selected="false"
                >
                    <i className="fas fa-clipboard-list" />
                </button>
            </div>

            <div className="tab-content" id="config-content">
                <div
                    className="tab-pane show active"
                    id="nav-dynamic-variation-options"
                    role="tabpanel"
                    aria-labelledby="nav-dynamic-variation-options-tab"
                >
                    <ConfigPanel />
                </div>

                <div
                    className="tab-pane"
                    id="nav-dynamic-manage-variation"
                    role="tabpanel"
                    aria-labelledby="nav-dynamic-manage-variation-tab"
                >
                    <div className="tab-title">Manage Variation</div>
                    {selectedVariation && <ManagePanel />}
                </div>

                <div
                    className="tab-pane"
                    id="nav-dynamic-analytics"
                    role="tabpanel"
                    aria-labelledby="nav-dynamic-analytics-tab"
                >
                    {selectedVariation && <AnalyticsPanel />}
                </div>

                <div
                    className="tab-pane"
                    id="nav-dynamic-logs"
                    role="tabpanel"
                    aria-labelledby="nav-dynamic-logs-tab"
                >
                    <div className="tab-title">Logs</div>
                    {selectedVariation && <LogsPanel />}
                </div>
            </div>
        </>
    );
};

export default Configs;
