import { useContext, useEffect } from 'react';
import { adDynamicContext } from '../../../services/contexts';
import configer from 'configer.js';

const ConfigPanel = () => {
    const { setDynamic, config, canSave, saveVariationConfig } = useContext(adDynamicContext);

    useEffect(() => {
        if (!config) return;

        const gui = new configer.GUI('dynamic_variation_options', {
            config: config,
            parent: 'dynamic-variation-options'
        });

        setDynamic((d) => {
            return { ...d, gui };
        });
    }, [config, setDynamic]);

    return (
        <>
            <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className="tab-title">Variation Options</div>
                <button disabled={!canSave} onClick={saveVariationConfig} className={"btn btn-sm rounded-pill px-3 " + (canSave ? "btn-primary" : "btn-outline-primary")}>Save</button>
            </div>
            <div id="dynamic-variation-options" />
        </>
    );
};

export default ConfigPanel;
