import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ms, utils, str } from '@gpg-web/utils';
import axios from 'axios';
import { Container, Content, Header } from 'rsuite';
import { PerformanceFilter, ORIENTATIONS } from './Filter';
import { PerformanceDimensions } from './Dimensions';
import { PerformanceCards } from './Cards';
import { PerformanceGraphs } from './Graphs';
import { PerformanceTable } from './Table';
import { LanguagesObj, CountriesObj } from '../../../consts';

const today = new Date();
const last7Days = new Date(Date.now() - ms('7d'));

const getFiltersKey = (filters) => {
    return filters
        .filter((e) => e.values.length > 0)
        .map((e) => 'filter:' + e.id + '=' + e.values.join(','))
        .join('&');
};
const parseFilters = (str) => {
    if (!str) return [];

    return str
        .split('&')
        .filter((e) => e.startsWith('filter:'))
        .map((e) => {
            var splt = e.split('=');
            let values = splt[1].split(',');
            values = values.map((e) => (isNaN(e) ? e : Number(e)));
            return { id: splt[0].slice(7), values: values };
        });
};

let cache = null;
const getFilters = () => {
    if (cache) return Promise.resolve(cache);

    return axios.get('/api/reports/performance/filters').then((res) => {
        // cache = res;
        return res;
    });
};

const getData = (start, end, scale, filters, dimension, dimension2) => {
    let dimensions = [dimension];
    if (dimension2) dimensions.push(dimension2);

    return axios.post('/api/reports/performance', {
        scale: scale,
        dimensions: dimensions,
        start: start.toISOString(),
        end: end.toISOString(),
        filters: filters
    });
};

let timer,
    requestId = 0;

const PerformancePage = () => {
    const search = window.location.search.slice(1);

    const [dateRange, setDateRange] = useState([last7Days, today]);
    const [filters, setFilters] = useState(parseFilters(search));
    const [dimension, setDimension] = useState('app');
    const [dimension2, setDimension2] = useState(null);
    const [timeScale, setTimeScale] = useState('HOUR');
    const [activeCard, setActiveCard] = useState('impressions');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [entities, setEntities] = useState(null);
    const history = useNavigate();

    dateRange[0].setHours(0, 0, 0, 0);
    dateRange[1].setHours(23, 59, 59, 0);

    useEffect(() => {
        getFilters()
            .then((res) => {
                setEntities({
                    apps: res.apps.map((e) => ({ label: str.trim(e.name, 20), value: e._id })),
                    scenes: res.scenes.map((e) => ({
                        label: str.trim(e.name, 20),
                        value: e._id,
                        app: e.app
                    })),
                    creatives: res.creatives.map((e) => ({
                        label: str.trim(e.name, 20),
                        value: e._id,
                        app: e.app,
                        scene: e.scene,
                        connections: e.connections
                    }))
                });
            })
            .catch(utils.hintError);
    }, []);

    const filtersStr = getFiltersKey(filters);

    useEffect(() => {
        if (search === filtersStr) return;
        history({ search: '?' + filtersStr });
    }, [history, filtersStr, search]);

    useEffect(() => {
        if (!entities) return;

        setLoading(true);
        setData(null);

        requestId++;
        const requesting = requestId;

        clearTimeout(timer);
        timer = setTimeout(() => {
            const start = dateRange[0];
            const end = dateRange[1];

            getData(start, end, timeScale, parseFilters(filtersStr), dimension, dimension2)
                .then((res) => {
                    if (requesting === requestId) {
                        function applyName(row, dim, fieldName) {
                            let name = row[fieldName];
                            // row['o' + fieldName] = name;

                            if (dim === 'app') {
                                const app = entities.apps.find((e) => e.value === name);

                                if (app) name = app.label;
                                else name = 'Unknown (#' + name + ')';
                            } else if (dim === 'scene') {
                                const scene = entities.scenes.find((e) => e.value === name);

                                if (scene) name = scene.label;
                                else name = 'Unknown (#' + name + ')';
                            } else if (dim === 'creative') {
                                const adCreatives = entities.creatives.find((e) => e.value === name);
                                if (adCreatives) {
                                    name = adCreatives.label;
                                    row.connections = adCreatives.connections;
                                } else {
                                    name = 'Unknown (#' + name + ')';
                                }
                            } else if (dim === 'orientation')
                                name = ORIENTATIONS.find((e) => e.value === name)?.label;
                            else if (dim === 'lang') name = LanguagesObj[name];
                            else if (dim === 'country') name = CountriesObj[name];

                            row[fieldName] = name || 'Unknown';
                        }
                        res.current.forEach((row) => {
                            // e.ts = new Date(e.ts);
                            applyName(row, dimension, 'dm1');
                            if (dimension2) applyName(row, dimension2, 'dm2');

                            if (row.connections) {
                                let network;
                                if (dimension === 'network') network = row['dm1'];
                                else if (dimension2 === 'network') network = row['dm2'];

                                if (network) {
                                    row.connections = row.connections.filter((e) => e.network === network);
                                }
                            }
                        });

                        setData({
                            scale: res.scale,
                            dateRange: [start, end],
                            dimension: dimension,
                            dimension2: dimension2,
                            list: res.current,
                            prev: res.previous[0]
                        });
                        setLoading(false);
                    }
                })
                .catch(utils.hintError);
        }, 4000);

        return () => {
            clearTimeout(timer);
        };
    }, [dateRange, timeScale, filtersStr, dimension, dimension2, entities]);

    return (
        <Container className="mt-2 mx-2">
            <Content>
                <PerformanceFilter
                    entities={entities}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    filters={filters}
                    setFilters={setFilters}
                    timeScale={timeScale}
                    setTimeScale={setTimeScale}
                />

                <Header>
                    <h5 className="title fw-bold">Performance</h5>
                </Header>

                <PerformanceDimensions
                    dimension={dimension}
                    dimension2={dimension2}
                    setDimension={setDimension}
                    setDimension2={setDimension2}
                />

                <PerformanceCards
                    loading={loading}
                    data={data}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                />
                <PerformanceGraphs
                    loading={loading}
                    data={data}
                    activeCard={activeCard}
                    dimension={dimension}
                    dimension2={dimension2}
                />
                <PerformanceTable
                    loading={loading}
                    data={data}
                    dimension={dimension}
                    dimension2={dimension2}
                />
            </Content>
        </Container>
    );
};

export default PerformancePage;
