import React, { useRef, useState } from 'react';
import { utils } from '@gpg-web/utils';
import { Alert } from '../../components';
import { RepositorySelect } from '../../components/select';
import { addApp } from '../../services/app';

const AppAdd = () => {
    const previewRef = useRef(null);

    const [error, setError] = useState(null);

    const formSumbitHandler = (event) => {
        setError(null);
        event.preventDefault();

        utils.popup('loading');

        const data = new FormData(event.target);
        addApp(data)
            .then((res) => event.target.reset())
            .catch(setError);
    };

    const onPictureUpload = (e) => {
        if (e.target.files.length) {
            const reader = new FileReader();

            reader.onloadend = () => {
                previewRef.current.src = reader.result;
            };

            reader.readAsDataURL(e.target.files[0]);
        } else {
            previewRef.current.src = '';
        }
    };

    return (
        <div className="container-lg">
            <form className="app-form" onSubmit={formSumbitHandler}>
                <div className="mb-3">
                    <label htmlFor="newAppName" className="form-label">
                        App Name
                    </label>
                    <input name="name" type="text" className="form-control" id="newAppName" />
                </div>

                <div className="mb-3">
                    <label htmlFor="newAppShortName" className="form-label">
                        App Short Name
                    </label>
                    <input name="short_name" type="text" className="form-control" id="newAppShortName" />
                </div>

                <div className="mb-3">
                    <label className="form-label">Repository</label>
                    <RepositorySelect />
                </div>

                <div className="mb-3">
                    <label htmlFor="ios_link_url" className="form-label">
                        iOS Game Link
                    </label>
                    <input name="ios_url" type="text" className="form-control" id="ios_link_url" />
                </div>

                <div className="mb-3">
                    <label htmlFor="android_link_url" className="form-label">
                        Android Game Link
                    </label>
                    <input name="android_url" type="text" className="form-control" id="android_link_url" />
                </div>

                <div className="mb-3 position-relative d-flex">
                    <div className="me-2">
                        <label className="form-label" htmlFor="newAppPictureFile">
                            Picture
                        </label>
                        <input
                            type="file"
                            accept="image/*"
                            name="picture"
                            className="form-control preview"
                            onChange={onPictureUpload}
                            id="newAppPictureFile"
                        />
                    </div>
                    <img
                        className={
                            'preview-image mt-4 p-2 ' +
                            (previewRef.current && previewRef.current.src ? '' : 'd-none')
                        }
                        ref={previewRef}
                        alt=""
                    />
                </div>
                <button type="submit" className="btn btn-primary m-2">
                    Submit
                </button>

                {error && <Alert text={error} />}
            </form>
        </div>
    );
};

export default AppAdd;
