/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';
import { utils } from '@gpg-web/utils';

let repositories = null;

const getRepositories = () => {
    if (repositories !== null) {
        return Promise.resolve(repositories);
    }

    return axios.get('/api/repository/list').then((data) => {
        repositories = data;
        return data;
    });
};

const addRepository = (data) => {
    return axios.post('/api/repository', data).then((res) => {
        if (repositories !== null) repositories.push(res);

        return res;
    });
};

const updateRepository = (id, data) => {
    return axios.put('/api/repository/' + id, data);
};

let sdkRepoCache = null;

const getSDKRepository = () => {
    if (sdkRepoCache !== null) {
        return Promise.resolve(sdkRepoCache);
    }

    return axios.get('/api/repository/sdk').then((repo) => {
        sdkRepoCache = repo;
        return repo;
    });
};

let pluginsRepoCache = null;

const getPluginsRepository = () => {
    if (pluginsRepoCache !== null) {
        return Promise.resolve(pluginsRepoCache);
    }

    return axios.get('/api/repository/plugins').then((repo) => {
        pluginsRepoCache = repo;
        return repo;
    });
};

const deleteRepository = (id) => {
    return axios.delete('/api/repository/' + id).then((res) => {
        return utils.remove(repositories, (e) => e._id === id);
    });
};

const pullRepository = (id) => {
    return axios.post('/api/repository/pull/' + id);
};

const updateRepositoryCommit = (name) => {
    return axios.post('/api/repository/updateCommit/' + name);
}

export const getRepository = (id) => {
    return axios.get('/api/repository/' + id);
}

export {
    getSDKRepository,
    getPluginsRepository,
    getRepositories,
    addRepository,
    updateRepository,
    deleteRepository,
    pullRepository,
    updateRepositoryCommit
};
