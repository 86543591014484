import { useEffect, useState } from 'react';
import { Playable } from '../../../playground/index';
import PluginManager from '../../../playground/PluginManager';

// const PluginItem2 = (props) => {

//     const [isLoading, setLoading] = useState(false);

//     const { data, uuid, onAdd, isAdded, onConfigure, onRemove} = props;

//     const { name, description, picture } = data;

//     useEffect(() => {

//         const loadingCb = (_uuid) => {
//             if (uuid === _uuid) {
//                 setLoading(true);
//             }
//         }

//         Playable.on("plugins:loading", loadingCb);

//         return () => {
//             Playable.off("plugins:loading", loadingCb);
//         }

//     }, [uuid])

//     const isCached = PluginManager.isPluginCached(uuid);

//     if (isLoading && isCached) {
//         setLoading(false)
//     }

//     let pictureSrc = picture;

//     if (!pictureSrc.startsWith("http")) {
//         pictureSrc = PluginManager.getPluginURL(uuid) + "/" + picture;
//     }

//     let icon = null;
//     let cb = onAdd;

//     if (isLoading) {
//         icon = <div className="plugins-btn spinner-border spinner-border-sm text-secondary"></div>
//         cb = () => {};
//     } else
//     if (!isCached) {
//         icon = <i className="plugins-btn far fa-arrow-alt-circle-down"></i>
//         cb = onAdd;

//     } else
//     if (isAdded) {
//         cb = onConfigure;
//         icon = <>
//             <i className="plugins-btn fas fa-times" onClick={(e) => {
//                 e.stopPropagation()
//                 onRemove()
//             }}></i>
//         </>
//     }

//     return <>
//         <div className="border-bottom p-2 plugins-item" id={"plugin_item_" + uuid} onClick={cb}>
//             <div className="plugins-card py-1 d-flex align-items-center">
//                     <div>
//                         <img className="icon-4"  alt="plugin logo" src={pictureSrc} width="50" />
//                     </div>
//                     <div className="ms-3 pe-2">
//                         <div className="title">
//                             {name}
//                         </div>
//                         <div className="subtitle pe-3">{description}</div>
//                     </div>
//                     <div className="ms-auto">
//                         {icon}
//                     </div>
//             </div>
//         </div>
//     </>

// };

const PluginItem = (props) => {
    const [isLoading, setLoading] = useState(false);

    const { data, uuid, onAdd, isAdded, onConfigure, onRemove, isLast } = props;

    const { name, description, picture } = data;

    useEffect(() => {
        const loadingCb = (_uuid) => {
            if (uuid === _uuid) {
                setLoading(true);
            }
        };

        Playable.on('plugins:loading', loadingCb);

        return () => {
            Playable.off('plugins:loading', loadingCb);
        };
    }, [uuid]);

    const isCached = PluginManager.isPluginCached(uuid);

    if (isLoading && isCached) {
        setLoading(false);
    }

    let pictureSrc = picture;

    if (!(pictureSrc.startsWith('http://') || pictureSrc.startsWith('https://'))) {
        pictureSrc = PluginManager.getPluginURL(uuid) + '/' + picture;
    }

    return (
        <>
            <div
                className={(!isLast ? 'border-bottom ' : '') + 'p-2 plugins-item'}
                id={'plugin_item_' + uuid}
            >
                <div className="plugins-card d-flex align-items-center">
                    <div>
                        <img className="icon-4" alt="plugin logo" src={pictureSrc} width="50" />
                    </div>
                    <div className="ms-3">
                        <div className="title">{name}</div>
                        <div className="subtitle">{description}</div>
                    </div>
                </div>

                <div className="d-flex align-items-end justify-content-end mt-3 me-2 my-2">
                    {isLoading && (
                        <div>
                            <div className="spinner-border spinner-border-sm text-secondary me-1 mb-0"></div>{' '}
                            Downloading...{' '}
                        </div>
                    )}

                    {isAdded && (
                        <>
                            <button onClick={onRemove} className="btn btn-sm ms-2 btn-outline-danger">
                                <i className="fas fa-times me-1"></i> Remove
                            </button>

                            <button onClick={onConfigure} className="btn btn-sm ms-2 btn-primary">
                                <i className="fa fa-cogs me-1"></i> Setup
                            </button>
                        </>
                    )}

                    {!isCached && !isLoading && (
                        <button onClick={onAdd} className="btn btn-sm btn-outline-secondary">
                            <i className="far fa-arrow-alt-circle-down me-1"></i> Install
                        </button>
                    )}

                    {!isAdded && isCached && (
                        <button onClick={onAdd} className="btn btn-sm btn-outline-primary">
                            <i className="fa fa-plus me-1"></i> Add
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

const Plugins = () => {
    const [pluginsData, setPluginsData] = useState({});
    const [activeList, setActiveList] = useState([]);

    useEffect(() => {
        const listCb = (fullList, actvList) => {
            setPluginsData(fullList);
            setActiveList([...actvList]);
        };

        Playable.on('plugins:list', listCb);

        return () => {
            Playable.off('plugins:list', listCb);
        };
    }, []);

    const pluginKeys = Object.keys(pluginsData);

    const availiablePluginKeys = pluginKeys.filter((key) => activeList.indexOf(key) === -1);
    const availiablePluginList = availiablePluginKeys.map((key) => pluginsData[key]);

    const onPluginAdd = (uuid) => {
        PluginManager.add(uuid).then(() => {
            // const newList = [...activeList];
            // newList.push(uuid);
            // setActiveList(newList);
            PluginManager.customize(uuid);
        });
    };

    const onPluginRemove = (uuid) => {
        PluginManager.remove(uuid);
        // const newList = activeList.filter((puuid) => puuid !== uuid);
        // setActiveList(newList);
    };

    const onPluginCustomize = (uuid) => {
        PluginManager.customize(uuid);
    };

    return (
        <>
            <div id="plugin-list" className="plugins-list">
                {activeList.length ? (
                    <>
                        {activeList.map((uuid, index) => (
                            <PluginItem
                                key={uuid}
                                uuid={uuid}
                                data={pluginsData[uuid]}
                                isAdded={true}
                                onConfigure={onPluginCustomize.bind(void 0, uuid)}
                                onRemove={onPluginRemove.bind(void 0, uuid)}
                                isLast={activeList.length === index + 1}
                            ></PluginItem>
                        ))}
                    </>
                ) : (
                    <div className="text-muted small">No plugins added</div>
                )}
                <hr className="mb-1" />
                <div className="text-secondary small">Available plugins ({availiablePluginList.length})</div>
                {availiablePluginList.map((pluginData, i) => (
                    <PluginItem
                        key={availiablePluginKeys[i]}
                        uuid={availiablePluginKeys[i]}
                        data={pluginData}
                        onAdd={onPluginAdd.bind(void 0, availiablePluginKeys[i])}
                        isAdded={false}
                        isLast={availiablePluginList.length === i + 1}
                    ></PluginItem>
                ))}
            </div>
        </>
    );
};

export default Plugins;
