import { str } from '@gpg-web/utils';
import { Link } from 'react-router-dom';
import { RestrictAccess } from '../../../components';
import EditCreative from './EditCreative';

const CreativeListItem = ({
    data,
    onRemove,
    onSelect,
    canRemove,
    onUpdate,
    canEdit,
    onDuplicate,
    onDownload,
    onLock,
    onAdNetworkConnect,
    isSelected
}) => {
    return (
        <div>
            <li
                type="button"
                onClick={(e) => !e.target.attributes['data-button'] && onSelect(data._id)}
                className={
                    'list-group-item d-flex align-items-center py-2 list-group-item-action ' +
                    (isSelected ? ' active' : '')
                }
            >
                <div className="me-auto" title={data.name}>
                    <div className="fw-bold">{str.trim(data.name, 28)}</div>

                    {((data.tags && data.tags.length > 0) || data.dynamic) && (
                        <div className="small text-break">
                            {data.dynamic && (
                                <span className="badge bg-info-subtle text-primary me-1">
                                    <i className="fas fa-chart-line fa-sm me-1" /> Dynamic
                                </span>
                            )}
                            {data.tags.map((e, index) => (
                                <span key={data._id + e + index} className="badge bg-light text-dark me-1">
                                    {e}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                {data.lock && <i className="fas fa-xs fa-lock text-gray-500 me-2" />}

                <RestrictAccess requiredLvl={1}>
                    <div className="dropstart">
                        <button className="btn btn-sm border-0" data-button data-bs-toggle="dropdown">
                            <i data-button className="fas fa-ellipsis-v" />
                        </button>
                        <ul className="dropdown-menu shadow">
                            <li>
                                <RestrictAccess requiredLvl={2}>
                                    <button
                                        onClick={onAdNetworkConnect}
                                        data-button
                                        data-id={data._id}
                                        className="dropdown-item"
                                        type="button"
                                    >
                                        Ad Network connect
                                    </button>
                                </RestrictAccess>
                                {data.dynamic && (
                                    <Link
                                        to={'/dynamic/' + data._id}
                                        data-button
                                        className="dropdown-item"
                                        type="button"
                                    >
                                        Edit variations
                                    </Link>
                                )}

                                <button
                                    onClick={() => onLock(data._id, !data.lock)}
                                    data-button
                                    data-id={data._id}
                                    className="dropdown-item"
                                    type="button"
                                >
                                    {data.lock ? 'Unlock' : 'Lock'}
                                </button>
                                <button
                                    onClick={onDuplicate}
                                    data-button
                                    data-id={data._id}
                                    className="dropdown-item"
                                    type="button"
                                >
                                    Duplicate
                                </button>
                                <RestrictAccess requiredLvl={2}>
                                    <button
                                        onClick={onDownload}
                                        data-button
                                        data-id={data._id}
                                        className="dropdown-item"
                                        type="button"
                                    >
                                        Download
                                    </button>
                                </RestrictAccess>
                                {canRemove && !data.lock && (
                                    <button
                                        onClick={onRemove}
                                        data-button
                                        data-id={data._id}
                                        className="dropdown-item text-danger"
                                        type="button"
                                    >
                                        Delete
                                    </button>
                                )}
                            </li>
                        </ul>
                    </div>
                </RestrictAccess>
            </li>

            {isSelected && (
                <div className="border" style={{ backgroundColor: 'rgba(190, 190, 221, 0.35)' }}>
                    <EditCreative />
                </div>
            )}
        </div>
    );
};

export default CreativeListItem;
