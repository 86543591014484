import { useEffect, useState } from 'react';
import PlayableDebugger from '../../../playground/Debugger';

export const ErrorCard = () => {
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const errorCb = (error) => {
            setErrors([...error]);
        };

        PlayableDebugger.on('console:error', errorCb);

        return () => {
            PlayableDebugger.off('console:error', errorCb);
        };
    }, []);

    return errors.length > 0 ? (
        <div className="mt-2 p-2 border-bottom pb-3">
            <div className="text-danger">
                <i className="fas fa-info-circle me-2" />
                ERROR: Check the console
            </div>
        </div>
    ) : null;
};
