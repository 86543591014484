/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import Select from 'react-select';

const Languages = {
    zh: 'Chinese',
    en: 'English',
    fr: 'French',
    ru: 'Russian',
    uk: 'Ukrainian'
};

const options = Object.keys(Languages).map((e) => {
    return { value: e, label: Languages[e] };
});

const LanguagesSelect = (props) => {
    return (
        <Select
            defaultValue={options.find((e) => e.value === props.defaultValue)}
            name="location"
            isDisabled={props.disabled}
            onChange={(e) => {
                props.onChange && props.onChange(e.value);
            }}
            options={options}
            placeholder="Choose Language"
            className="basic-single"
            classNamePrefix="select"
        />
    );
};

export { LanguagesSelect };
