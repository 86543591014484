/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
// import SceneList from './SceneList';
import Debugger from '../Debugger';
import HostedFiles from './HostedFiles';
import Plugins from '../Plugins';
import DebuggerIcon from '../Debugger/DebuggerIcon';
import TechnologiesList from '../Debugger/TechnologiesList';
import CreativesList from '../Creatives/List';

const ControlPanel = () => {
    return (
        <>
            <div className="nav nav-tabs" role="tablist">
                <button
                    title="Creatives"
                    className="nav-link active"
                    id="nav-creatives-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-creatives-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-creatives-control"
                    aria-selected="true"
                >
                    <i className="fas fa-puzzle-piece" />
                </button>

                <button
                    title="Scenes"
                    className="nav-link"
                    id="nav-scenes-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-scenes-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-scenes-control"
                    aria-selected="false"
                >
                    <i className="fas fa-layer-group" />
                </button>

                <button
                    title="Hosting"
                    className="nav-link"
                    id="nav-host-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-host-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-host-control"
                    aria-selected="false"
                >
                    <i className="fas fa-share-alt" />
                </button>

                <button
                    title="Plugins"
                    className="nav-link"
                    id="nav-plugins-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-plugins-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-plugins-control"
                    aria-selected="false"
                >
                    <i className="fas fa-dice-d6" />
                </button>

                <button
                    title="Debugger"
                    className="nav-link"
                    id="nav-debugger-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-debugger-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-debugger-control"
                    aria-selected="false"
                >
                    <DebuggerIcon />
                </button>

                <button
                    title="Info"
                    className="nav-link"
                    id="nav-info-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-info-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-info-control"
                    aria-selected="false"
                >
                    <i className="fas fa-info-circle" />
                </button>
            </div>

            <div className="tab-content">
                <div
                    className="tab-pane h-100 show active"
                    id="nav-creatives-control"
                    role="tabpanel"
                    aria-labelledby="nav-creatives-control-tab"
                >
                    <div className="d-flex flex-column h-100">
                        <CreativesList />
                    </div>
                </div>

                <div
                    className="tab-pane h-100"
                    id="nav-scenes-control"
                    role="tabpanel"
                    aria-labelledby="nav-scenes-control-tab"
                >
                    <div className="d-flex flex-column h-100">Not ready yet. But. Do you need it ? As for me, now it's not compliant with new concept and app structure.</div>
                </div>

                <div
                    className="tab-pane h-100"
                    id="nav-host-control"
                    role="tabpanel"
                    aria-labelledby="nav-host-control-tab"
                >
                    <div className="d-flex flex-column justify-content-between h-100">
                        <HostedFiles />
                    </div>
                </div>

                <div
                    className="tab-pane"
                    id="nav-plugins-control"
                    role="tabpanel"
                    aria-labelledby="nav-plugins-control-tab"
                >
                    <div className="tab-title">Plugins</div>
                    <div id="plugins-control">
                        <Plugins></Plugins>
                    </div>
                </div>

                <div
                    className="tab-pane"
                    id="nav-debugger-control"
                    role="tabpanel"
                    aria-labelledby="nav-debugger-control-tab"
                >
                    <div className="tab-title">Debugger</div>
                    <Debugger></Debugger>
                </div>

                <div
                    className="tab-pane"
                    id="nav-info-control"
                    role="tabpanel"
                    aria-labelledby="nav-info-control-tab"
                >
                    <div className="tab-title">Playable information</div>
                    <TechnologiesList />
                </div>
            </div>
        </>
    );
};
export default ControlPanel;
