/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from './partials/Sidebar';
import Topbar from './partials/Topbar';
import Footer from './partials/Footer';

import { LoadingPage } from '@gpg-web/react';

import Modals from './modals';
import { ScrollToTop } from '@gpg-web/react';

import { appConext, getApps, appEvents, getResources } from './services/app';
import { layoutContext, playgroundContext } from './services/contexts';
import { getMembers } from './services/organization';
import { CustomProvider } from 'rsuite';

import { getProfile, userContext, userEvents } from './services/user';

// import { GlobalModal } from './globalModal/ModalContext';

let requestProfileData = window.location.pathname !== '/provider/logout';

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {},
            portrait: mql.matches,
            sidebarOpen: false,
            loading: requestProfileData,
            loggedIn: false,
            apps: [],
            app: {},
            scene: null,
            creative: null,
            layout: {
                topbar: true,
                footer: true,
                playground: false
            }
        };

        this.setSidebarOpen = this.setSidebarOpen.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.toogleSidebar = this.toogleSidebar.bind(this);
        this.tooglePlaygroundView = this.tooglePlaygroundView.bind(this);
        this.setApp = this.setApp.bind(this);
        this.setScene = this.setScene.bind(this);
        this.setCreative = this.setCreative.bind(this);
    }

    setApp(app) {
        this.setState({ app: app });
    }

    setScene(scene) {
        this.setState({ scene: scene });
    }

    setCreative(creative) {
        this.setState({ creative: creative });
    }

    setSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    mediaQueryChanged() {
        this.setState({ portrait: mql.matches, sidebarOpen: false });
    }

    toogleSidebar(open) {
        this.setSidebarOpen(!this.state.sidebarOpen);
    }

    updateProfile() {
        // this.setState({user: profile});
    }

    tooglePlaygroundView(value) {
        let layout = this.state.layout;
        layout.topbar = !value;
        layout.footer = !value;
        layout.playground = !!value;

        // this.setState({portrait: !value});

        this.setState({ layout });
        // this.setState({layout: {...layout}});
    }
    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);

        if (requestProfileData) {
            getProfile()
                .then(async (profile) => {
                    try {
                        this.setState({
                            user: profile,
                            loggedIn: true
                        });
                        const members = await getMembers();
                        const resources = await getResources();
                        const apps = await getApps();
                        window.detectAdBlock();
                        this.setState({
                            apps: apps,
                            resources: resources,
                            members: members,
                            loading: false
                        });
                        appEvents.onupdate = (list) => {
                            this.setState({ apps: list });
                        };
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(() => {
                    window.localStorage.removeItem('userStatus');
                });
        }

        userEvents.onprofileupdate = () => {
            getProfile().then((profile) => {
                this.setState({ user: profile });
            });
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.state.user !== undefined && prevState.user !== this.state.user && this.state.user.email)
        // {
        //     if (prevState.user.id === this.state.user.id)
        //     {
        //         return;
        //     }
        // }
    }

    render() {
        if (this.state.loading) {
            return <LoadingPage />;
        }

        return (
            <CustomProvider>
                <userContext.Provider value={this.state.user}>
                    <appConext.Provider
                        value={{
                            apps: this.state.apps,
                            app: this.state.app,
                            setApp: this.setApp,
                            resources: this.state.resources
                        }}
                    >
                        <playgroundContext.Provider
                            value={{
                                setScene: this.setScene,
                                scene: this.state.scene,
                                creative: this.state.creative,
                                setCreative: this.setCreative
                            }}
                        >
                            <layoutContext.Provider
                                value={{
                                    ...this.state.layout,
                                    sidebarDocked: this.state.portrait,
                                    tooglePlaygroundView: this.tooglePlaygroundView,
                                    toogleAnalyticsView: this.toogleAnalyticsView
                                }}
                            >
                                <Topbar toogleSidebar={this.toogleSidebar} />
                                <Sidebar
                                    docked={this.state.portrait}
                                    open={this.state.sidebarOpen}
                                    onSetOpen={this.setSidebarOpen}
                                >
                                    <div id="wrapper">
                                        <div className="wrapper-content pb-2 px-2 pt-2 bg-gray-100">
                                            <Outlet />
                                        </div>

                                        <Footer />
                                    </div>
                                </Sidebar>

                                <ScrollToTop container="page-content" />

                                <Modals />
                            </layoutContext.Provider>
                        </playgroundContext.Provider>
                    </appConext.Provider>
                </userContext.Provider>
            </CustomProvider>
        );
    }
}

export default App;
