/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

const GENERAL_CONFIG = {
    ios_url: {
        type: 'text',
        title: 'App Store URL',
        placeholder: 'App Store URL'
    },

    android_url: {
        type: 'text',
        title: 'Play Market URL',
        placeholder: 'Play Market URL'
    },

    title_div: {
        type: 'title',
        title: 'Playable Title Template'
    },

    playable_title: {
        type: 'text',
        placeholder: 'Playable Title Template',
        value: '{EXCH}_{PLAYABLE}_{HASH}'
    },

    // playable_title_generated:
    // {
    // 	title: "rc_BeatME_EXlvl1_4zelu6b705106c",
    // 	type: "data",
    // 	ignore: true,
    // 	disabled: true
    // },

    lang: {
        type: 'select',
        title: 'Language',
        value: 'auto',
        options: ['auto', 'en', 'de', 'es', 'pt', 'fr', 'ru', 'ja', 'ko', 'th', 'zh'],
        labels: [
            'Auto-detect',
            'English',
            'German',
            'Spanish; Castilian',
            'Portuguese',
            'French',
            'Russian',
            'Japanese',
            'Korean',
            'Thai',
            'Chinese'
        ]
    },

    splash_delay: {
        type: 'range',
        min: 0,
        max: 2,
        step: 0.1,
        value: 1,
        title: 'Splash Screen delay (s.)'
    },

    sound_volume: {
        type: 'range',
        min: 0,
        max: 1,
        step: 0.1,
        value: 1,
        title: 'Sound volume'
    },

    google_size: {
        type: 'data_set',
        title: 'Google ad dimension',
        value: {
            Width: 480,
            Height: 320
        }
    }
};

export default GENERAL_CONFIG;
