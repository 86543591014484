import OptionBlock from 'configer.js/src/blocks/OptionBlock';
import configer from 'configer.js';

import './TranslationOption.css';

class TranslationOption extends OptionBlock {
    parse() {
        return this.obj.value;
    }

    buildMenu() {
        var menu = (this.menu = document.createElement('div'));
        menu.className = 'translation-menu';
        menu.style.display = 'none';

        var list = document.createElement('div');
        list.className = 'translation-menu-list';

        menu.appendChild(list);

        this.menu.show = () => {
            if (this.menu.updateOptions()) {
                this.menu.style.display = 'block';
            }
        };
        this.menu.hide = () => {
            this.menu.style.display = 'none';
        };

        this.menu.updateOptions = () => {
            list.innerHTML = '';

            var value = this.input.value;

            var playable = window.Playable;

            var translations = playable && playable.translations;

            var options = Object.keys(translations || {});

            if (options.length === 0) {
                this.menu.hide();
                return false;
            }

            var added = 0;

            options.forEach((val) => {
                if (val.toLowerCase().includes(value.toLowerCase()) && val !== value) {
                    added++;
                    var option = document.createElement('div');
                    option.className = 'translation-menu-option';
                    option.innerText = val;
                    option.onmousedown = () => {
                        this.change(val, true);

                        this.onInput();
                    };
                    list.appendChild(option);
                }
            });

            if (added === 0) {
                this.menu.hide();
            } else {
                this.menu.style.display = 'block';
            }

            return added > 0;
        };

        return menu;
    }

    updateHint() {
        this.hint.innerHTML = '';

        var msgContainer = document.createElement('span');
        msgContainer.className = 'd-flex align-items-center';

        var playable = window.Playable || {};

        var translations = playable.translations || {};

        var options = Object.keys(translations || {});

        var value = this.input.value;

        if (value !== '') {
            if (options.indexOf(value) > -1 && Object.keys(translations[value]).length > 0) {
                var iconSuccess = document.createElement('i');

                iconSuccess.className = 'fas fa-language text-success me-1';

                msgContainer.innerText = 'Translations';

                Object.keys(translations[value]).forEach((lang, i) => {
                    var badge = document.createElement('span');

                    badge.className = 'badge badge-sm bg-secondary';
                    badge.style.padding = '2px 4px';
                    badge.style.marginRight = '1px';
                    badge.style.marginLeft = '2px';

                    badge.innerText = lang.toUpperCase();

                    badge.title = 'Translation: ' + translations[value][lang];

                    msgContainer.appendChild(badge);
                });

                msgContainer.prepend(iconSuccess);
            } else {
                msgContainer.className = 'text-danger';

                var iconWarning = document.createElement('i');

                iconWarning.className = 'fas fa-exclamation-triangle me-1';
                msgContainer.innerText = 'No translation found';

                msgContainer.prepend(iconWarning);
            }
        }

        this.hint.appendChild(msgContainer);
    }

    onInput() {
        this.menu.updateOptions();
        this.updateHint();

        this.input.style.height = 'auto';

        this.input.style.height = this.input.scrollHeight + 'px';
    }

    build() {
        super.build();

        var _this = this;

        if (this.obj.value === undefined) this.obj.value = '';

        var wrapper = document.createElement('div');

        wrapper.className = 'w-100';

        var container = document.createElement('div');

        container.className = 'translation-container';

        // container.style.position = 'relative';
        // container.style.boxSizing = 'border-box';

        var control = document.createElement('div');
        control.className = 'translation-control';
        container.appendChild(control);

        var valueContainer = document.createElement('div');
        valueContainer.className = 'translation-value-container';
        control.appendChild(valueContainer);

        var inputContainer = document.createElement('div');
        inputContainer.className = 'translation-input-container';
        valueContainer.appendChild(inputContainer);

        var input = document.createElement('textarea');
        input.id = this.id;
        input.value = this.obj.value;
        input.rows = 1;
        input.className = 'translation-input';
        inputContainer.appendChild(input);

        var hint = (this.hint = document.createElement('div'));
        hint.className = 'fw-bold';
        hint.style.fontSize = '75%';

        valueContainer.appendChild(hint);

        var menu = this.buildMenu();

        control.appendChild(menu);

        if (this.obj.placeholder) {
            input.placeholder = this.obj.placeholder;
        }

        this.input = input;

        function change() {
            _this.change(this.value, true);
        }

        input.onchange = change;

        input.oninput = this.onInput.bind(this);

        input.onfocus = () => {
            this.menu.show();
        };

        input.onblur = () => {
            this.menu.hide();
        };

        wrapper.appendChild(container);
        this.html.appendChild(wrapper);
    }

    change(value, callCallback) {
        var changed = false;

        var prevValue = this.input.value;

        if (value !== undefined) {
            if (this.obj.value !== value) {
                changed = true;
            }

            this.obj.value = value;
        }

        if (this.defaultValue === undefined) this.defaultValue = this.obj.value;
        this.input.value = '' + this.obj.value;

        if (this.input.value !== prevValue || changed) {
            changed = true;
            this.obj.changed = true;
        }

        if (changed && callCallback) {
            this.onChange(this.obj.value);
        }

        return changed;
    }

    static getType() {
        return 'translation';
    }
}
configer.plugin.register('translation', TranslationOption, 'value');
