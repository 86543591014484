/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';

const dataLayer = (window.dataLayer = window.dataLayer || []);

const ENABLED = false;

function gtag() {
    dataLayer.push(arguments);
}

if (ENABLED) {
    gtag('js', new Date());

    gtag('config', 'UA-150676465-2');
}

function glog(category, action, label, value) {
    if (ENABLED) {
        gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value
        });
    }
}

function page(title, path) {
    if (path === undefined) path = utils.unifyStr(title);

    gtag('config', 'UA-150676465-2', {
        page_title: title,
        page_path: '/' + path
    });
}

window.gtag = gtag;
window.glog = glog;
window.gpage = page;

window.addEventListener('click', function (event) {
    if (event && event.target) {
        var text = event.target.innerText || '';

        if (text.length > 50 || text.length < 3) {
            if (event.target.id) {
                text = event.target.id;
            } else if (text.length > 50) {
                text = text.substr(1, 40);
            } else if (text.length < 3) {
                if (event.target.src) {
                    text = event.target.src.replace(document.location.origin, '').substr(0, 50);
                }
            }
        }

        if (text && text.length > 2) {
            //if (__DEV__) console.log(text);

            glog('general', 'click', text);
        }
    }
});

export { glog, page };
