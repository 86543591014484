import { useEffect, useState } from 'react';
import Translations from '../../../../playground/Translations';
import TranslationTerm from './TranslationTerm';

const TranslationsPanel = () => {
    const [translations, setTranslations] = useState(Translations.getTranslations());

    useEffect(() => {
        const updateCb = (t) => {
            setTranslations(t);
        };

        Translations.on('update', updateCb);

        return () => {
            Translations.off('update', updateCb);
        };
    }, []);

    const keys = Object.keys(translations);

    if (keys.length === 0) {
        return (
            <>
                <h6>No translations available</h6>
            </>
        );
    }

    const usedLanguages = new Set();

    for (const term in translations) {
        for (const lang in translations[term]) {
            usedLanguages.add(lang);
        }
    }

    const onTranslationChanged = (term, lang, val) => {
        translations[term][lang] = val;

        const newTranslation = { ...translations };

        for (const term in newTranslation) {
            newTranslation[term] = { ...newTranslation[term] };

            for (const lang in newTranslation[term]) {
                if (!newTranslation[term][lang] || newTranslation[term][lang] === '') {
                    delete newTranslation[term][lang];
                }
            }
        }

        Translations.setTranslation(newTranslation);
    };

    const defaultValues = Translations.getDefaultTranslations();

    return (
        <>
            <div id="translations-options">
                <div className="tab-title d-flex">Translations</div>

                {keys.map((key) => (
                    <TranslationTerm
                        key={key}
                        term={key}
                        values={translations[key]}
                        defaultVal={defaultValues[key]}
                        usedLang={[...usedLanguages.values()]}
                        onChange={onTranslationChanged}
                    ></TranslationTerm>
                ))}
            </div>
        </>
    );
};

export default TranslationsPanel;
