/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import axios from 'axios';
import { saveAs } from 'file-saver';

// const ORIGIN = '/hosting/files/';

let directory = '';

const folderAPI = {
    folder: function (name) {
        if (name) {
            directory += name + '/';
        }

        return this;
    },

    path: function (title) {
        title = title || '';
        let path = directory + title;
        directory = '';

        // return ORIGIN + path;
        return path;
    },

    pathname: function (title) {
        title = title || '';
        let path = directory + title;
        directory = '';

        // if (path.startsWith(ORIGIN)) path = path.slice(ORIGIN.length);

        return path;
    },

    absolutePath: function (title) {
        return window.location.origin + this.path(title);
    },

    read: function (title) {
        const path = this.path(title);

        return axios.get(path);
    },

    download: function (title, fn) {
        const path = this.path(title);

        let filename = fn || title.substring(title.lastIndexOf('/') + 1);

        return axios({
            url: path,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            saveAs(response, filename);
            return filename;
        });
    },

    check: function (title) {
        const path = this.path(title);

        return axios.post('/api/hosting/check', { title: path });
    },

    find: function (name, cb) {
        const path = this.path();

        return axios.post('/api/hosting/find', { title: path, name: name });
    },

    remove: function (title) {
        const path = this.path(title);

        return axios.post('/api/hosting/remove', { title: path });
    },

    mkdir: function (title) {
        const path = this.path(title);

        return axios.post('/api/hosting/mkdir', { title: path });
    },

    upload: function (title, content) {
        const path = this.path(title || 'New file 1');

        var formData = new FormData();

        formData.append('content', content);

        console.log('Hosting: file ' + path + ' is uploading...');

        return axios.post('/api/hosting/upload?title=' + path, formData);
    },

    list: function (fullPath) {
        const path = this.path();

        return axios.post('/api/hosting/list', { title: path }).then((list) => {
            if (fullPath) {
                list = list.map((e) => path + e);
            }

            return list;
        });
    },

    ls: function (fullPath) {
        return this.list(fullPath).then((list) => {
            for (let file of list) console.log(file);

            return list;
        });
    }
};

folderAPI.cd = folderAPI.folder;
folderAPI.cat = folderAPI.gc = folderAPI.read;
folderAPI.echo = folderAPI.write = folderAPI.upload;
folderAPI.rm = folderAPI.remove;
folderAPI.has = folderAPI.check;

const Hosting = {
    // origin: ORIGIN,

    reset: function () {
        directory = '';
    }
};

Object.assign(Hosting, folderAPI);

export default Hosting;
