/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

let modal = null;

const Confirm = function (msg, cb) {
    return new Promise((resolve, reject) => {
        const el = document.getElementById('confirmModal');

        if (modal === null) {
            modal = new window.bootstrap.Modal(el);
        }

        const okB = el.querySelector('#confirmModalOkButton');

        el.querySelector('#confirmModalLabel').innerHTML = msg;

        let result = false;

        okB.onclick = function () {
            result = true;
        };

        el.addEventListener(
            'hidden.bs.modal',
            function () {
                cb && cb(result);
                resolve(result);
            },
            { once: true }
        );

        modal.show();
    });
};

export default Confirm;
