/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import Builder from './Builder';
import { getCreative } from '../services/creative';
import GENERAL_CONFIG from './GENERAL_CONFIG';

async function buildNetworks(result, params, creative) {
    for (let network of params.networks) {
        const options = {
            network: network,
            creative: creative,
            ignore_network_title: true,
            include_network_title: false,
            title_prefix: params.prefix || '',
            title_sufix: params.sufix || '',
            return_zip: false
        };

        if (creative) {
            GENERAL_CONFIG.playable_title.value = creative.name;
            // options.title = customTitle + '_' + network;
        }

        const build = await Builder.production(options);

        if (build.blob) {
            result.push({
                network: network,
                name: build.fileName,
                app_store_url: build.app_store_url,
                google_play_url: build.google_play_url,
                blob: build.blob
            });
        } else {
            const blob = new Blob([build.content], {
                type: build.blobType
            });

            result.push({
                network: network,
                name: build.fileName,
                app_store_url: build.app_store_url,
                google_play_url: build.google_play_url,
                blob: blob
            });
        }

        // if (network === 'adwords') {
        //     options.google_rotated = true;

        //     const build = await Builder.production(options);

        //     if (build.blob) {
        //         folder.file(build.fileName, build.blob);
        //     } else {
        //         folder.file(build.fileName, build.content);
        //     }
        // }
    }
}

async function preapreBuilds(params) {
    const Playable = window.Playable;

    const result = [];

    if (params.creatives) {
        const currentSnapshot = Playable.snapshotConfigs();

        for (let crea of params.creatives) {
            const creative = await getCreative(crea._id);

            if (!creative.data) throw new Error("Creative data can't be found");

            await Playable.applyConfigs(creative.data, false);

            await buildNetworks(result, params, creative);
        }

        await Playable.applyConfigs(currentSnapshot, false);
    } else {
        await buildNetworks(result, params);
    }

    return result;
}

export default preapreBuilds;
