/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';
import { getCreative, updateCreative, getAdNetworkCreatives } from '../../../services/creative';
import { utils, store, str } from '@gpg-web/utils';
import { Modal, Spinner, Table } from '@gpg-web/react';
import Skeleton from 'react-loading-skeleton';

const connectedCreativesColumns = [
    {
        Header: 'Name',
        accessor: 'displayName',
        size: 500,
        cellClass: 'cell-center-y',
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? 1 : -1;
        }
    },
    {
        Header: 'Ad Network',
        accessor: 'network',
        cellClass: 'cell-center-y',
        size: 100
    },
    {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        size: 100,
        cellClass: 'cell-center-x cell-center-y text-nowrap'
    }
];

const columns = [
    {
        Header: 'Name',
        accessor: 'displayName',
        size: 500,
        cellClass: 'cell-center-y',
        sortType: (rowA, rowB) => {
            return rowA.original.name > rowB.original.name ? 1 : -1;
        }
    },
    {
        Header: 'Ad Network',
        accessor: 'network',
        cellClass: 'cell-center-y',
        size: 100
    },
    {
        Header: 'Impressions',
        accessor: 'impressions',
        cellClass: 'cell-center-y',
        size: 50
    },
    {
        Header: 'Action',
        accessor: 'action',
        disableSortBy: true,
        size: 50,
        cellClass: 'cell-center-x cell-center-y text-nowrap'
    }
];

const DownloadModal = (props) => {
    let { show, onHide, creativeId } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [list, setList] = useState([]);
    const [connectedList, setConnectedList] = useState([]);
    const [adNetworkCreatives, setAdNetworksCreatives] = useState([]);
    const [storesIds, setStoresIds] = useState(null);
    const [status, setStatus] = useState('Loading creative...');
    const [creative, setCreative] = useState(null);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        setStatus('Loading creative data...');
        if (creativeId)
            getCreative(creativeId)
                .then((res) => setCreative(JSON.parse(JSON.stringify(res))))
                .catch(setError);
        else setCreative(null);
    }, [creativeId]);

    useEffect(() => {
        if (!creative) return;

        setStatus(null);

        const { general } = JSON.parse(creative.data);

        const ios_url = general.ios_url.value;
        const android_url = general.android_url.value;

        setStoresIds({
            ios: store.appId(ios_url),
            android: store.appId(android_url)
        });
    }, [creative]);

    const iosAppId = storesIds && storesIds.ios;
    const androidAppId = storesIds && storesIds.android;

    useEffect(() => {
        if (!(iosAppId && androidAppId)) return;

        setAdNetworksCreatives([]);

        console.log('Loading all networks data');
        async function loadAll() {
            let result = [];

            function pushResult(res, network) {
                res.forEach((e) => {
                    e.network = network;
                    e.id = '' + e.id;
                });
                result = result.concat(res);
                setAdNetworksCreatives(result);
            }

            let res;

            setStatus('Getting creatives for unity - ios - ' + iosAppId);
            res = await getAdNetworkCreatives({ storeId: iosAppId, network: 'unity' });
            pushResult(res, 'unity');

            setStatus('Getting creatives for applovin - ios - ' + iosAppId);
            res = await getAdNetworkCreatives({ storeId: iosAppId, network: 'applovin' });
            pushResult(res, 'applovin');

            setStatus('Getting creatives for ironsource - ios - ' + iosAppId);
            res = await getAdNetworkCreatives({ storeId: iosAppId, network: 'ironsource' });
            pushResult(res, 'ironsource');

            setStatus('Getting creatives for unity - android - ' + androidAppId);
            res = await getAdNetworkCreatives({ storeId: androidAppId, network: 'unity' });
            pushResult(res, 'unity');

            setStatus('Getting creatives for applovin - android - ' + androidAppId);
            res = await getAdNetworkCreatives({ storeId: androidAppId, network: 'applovin' });
            pushResult(res, 'applovin');

            setStatus('Getting creatives for ironsource - android - ' + androidAppId);
            res = await getAdNetworkCreatives({ storeId: androidAppId, network: 'ironsource' });
            pushResult(res, 'ironsource');

            setStatus(null);
        }

        loadAll().catch((err) => {
            utils.hintError(err);
            setStatus('Error getting creatives: ' + err);
        });
    }, [iosAppId, androidAppId]);

    useEffect(() => {
        if (!creative) return;

        let connections = [];

        if (creative.connections) {
            function disconnectCreative(e) {
                const adNetworkCreativeId = e.target.getAttribute('data-id');
                utils.remove(creative.connections, (e) => e.id === adNetworkCreativeId);

                setCreative({ ...creative });
                setChanged(true);
            }

            connections = creative.connections.map((e) => {
                return {
                    name: e.name,
                    displayName: <div title={e.name}>{str.trim(e.name || 'Null', 80)}</div>,
                    network: e.network,
                    id: e.id,
                    action: (
                        <button
                            data-id={e.id}
                            onClick={disconnectCreative}
                            className="btn btn-sm btn-outline-danger rounded-pill"
                        >
                            <i className="fas fa-times me-1" /> remove
                        </button>
                    )
                };
            });
        }

        setConnectedList(connections);

        let list = [];

        if (adNetworkCreatives) {
            function connectCreative(e) {
                const adNetworkCreativeId = e.target.getAttribute('data-id');
                const adNetworkCreative = adNetworkCreatives.find((e) => e.id === adNetworkCreativeId);

                if (!adNetworkCreative) return utils.hintError('Cannot set this creative');

                creative.connections.push({
                    id: adNetworkCreative.id,
                    name: adNetworkCreative.name,
                    network: adNetworkCreative.network
                });

                setCreative({ ...creative });
                setChanged(true);
            }

            list = adNetworkCreatives
                .filter((e) => !connections.find((c) => e.id === c.id && e.network === c.network))
                .map((e) => {
                    return {
                        name: e.name,
                        displayName: <div title={e.name}>{str.trim(e.name || 'Null', 80)}</div>,
                        network: e.network,
                        impressions: e.impressions,
                        action: (
                            <>
                                <button
                                    data-id={e.id}
                                    onClick={connectCreative}
                                    className="btn btn-sm btn-outline-secondary rounded-pill"
                                >
                                    <i className="fas fa-link me-1 pe-none" /> connect
                                </button>
                            </>
                        )
                    };
                });

            setList(list);
        }
    }, [creative, adNetworkCreatives]);

    const _onHide = () => {
        setError(null);
        setCreative(null);
        setLoading(false);
        setList([]);
        onHide(false);
    };

    function save() {
        setLoading(true);
        updateCreative(creativeId, { connections: creative.connections })
            .then(() => {
                setChanged(false);
                setLoading(false);
                onHide();
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    }

    return (
        <Modal show={show} onHide={_onHide} size="xl" title="Ad Network Connection">
            <div className="modal-body px-4">
                <div className="d-flex justify-content-end align-items-center">
                    {error && (
                        <div className="text-danger me-auto">
                            <i className="fa fa-exclamation-circle me-2" />
                            {error}
                        </div>
                    )}
                    <button data-bs-dismiss="modal" className="btn btn-light btn-sm me-2 rounded-pill">
                        Cancel
                    </button>
                    <button
                        disabled={loading || !creative || !changed}
                        onClick={save}
                        className="btn btn-primary btn-sm rounded-pill"
                    >
                        {loading && <Spinner size="10px" />} <span className="ms-1">Save</span>
                    </button>
                </div>
                <div>
                    <h4>Connected creatives with "{creative && creative.name}"</h4>

                    <Table
                        header={false}
                        pageSize={20}
                        footer={false}
                        columns={connectedCreativesColumns}
                        data={connectedList}
                        saveStateId="plaayble-tool-creative-ad-network-connected"
                        className="table-sm table-striped"
                    />

                    {!creative && <Skeleton count={5} height={30} />}

                    {creative && connectedList.length === 0 && (
                        <div className="fw-light small text-center">Nothing connected</div>
                    )}

                    <hr className="mt-5" />

                    <div className="d-flex justify-content-between mb-3">
                        <div>
                            <h4 className="mb-1">Pending creatives</h4>

                            <div className="text-muted small">
                                <i className="fas fa-sm fa-info-circle me-2" />
                                Filtered creatives which has impressions > 1 for the last 30 days.
                            </div>
                        </div>
                        {status && (
                            <div className="fw-light small mb-2">
                                {status}&nbsp;&nbsp;
                                {!status.includes('Error') && <Spinner size="10px" />}
                            </div>
                        )}
                    </div>

                    <Table
                        pageSize={100}
                        saveStateId="plaayble-tool-creative-ad-network-connection"
                        columns={columns}
                        data={list}
                        className="table-sm table-striped"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DownloadModal;
