/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React, { useContext } from 'react';
import profileIcon from '../img/undraw_profile.svg';
import { userContext } from '../services/user';
import { layoutContext } from '../services/contexts';
import { ThemeButton } from '@gpg-web/react';

const Topbar = (props) => {
    const { topbar, sidebarDocked } = useContext(layoutContext);

    const { email, name, picture, provider } = useContext(userContext);

    if (!topbar && sidebarDocked) return null;

    return (
        <>
            <nav className="navbar navbar-expand navbar-light text-dark bg-white  topbar sticky-top shadow-sm px-3">
                {!sidebarDocked && (
                    <button onClick={props.toogleSidebar} className="btn btn-link rounded-circle me-3">
                        <i className="fa fa-bars"></i>
                    </button>
                )}

                <ul className="navbar-nav ms-auto test">
                    <li className="nav-item dropdown no-arrow mx-1">
                        <div className="nav-link">
                            <ThemeButton lightClassName="fas fa-moon text-secondary" />
                        </div>
                    </li>

                    <li className="nav-item dropdown no-arrow mx-1">
                        <div
                            className="nav-link dropdown-toggle"
                            id="alertsDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fas fa-sm fa-bell fa-fw"></i>
                            <span className="badge bg-danger fw-bold badge-counter"></span>
                        </div>
                        <div
                            className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="alertsDropdown"
                        >
                            <h6 className="dropdown-header">Alerts Center</h6>
                            <div className="text-center p-3 small text-gray-500">No Any Alerts</div>
                        </div>
                    </li>

                    <li className="nav-item mx-1 d-none d-sm-block">
                        <a
                            className="nav-link"
                            target="_blank"
                            rel="noreferrer"
                            href="https://greenpandagames.atlassian.net/wiki/spaces/WEB/pages/1510801425/User+guide+Playable+Tool"
                            id="alertsDropdown"
                        >
                            <i className="fa fa-book fa-sm fa-fw"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown no-arrow mx-1">
                        <div
                            className="nav-link dropdown-toggle"
                            id="mobileMenuDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i style={{ fontSize: '22px' }} className="fas fa-ellipsis-h"></i>
                        </div>
                        <div
                            className="dropdown-menu dropdown-menu-light dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="mobileMenuDropdown"
                        >
                            <a
                                className="dropdown-item d-flex align-items-center d-sm-none"
                                target="_blank"
                                rel="noreferrer"
                                href="https://greenpandagames.atlassian.net/wiki/spaces/WEB/pages/1510801425/User+guide+Playable+Tool"
                            >
                                <div className="me-3">
                                    <i className="fa fa-book"></i>
                                </div>
                                <div>Tutorial</div>
                            </a>

                            <a className="dropdown-item d-flex align-items-center" href={provider}>
                                <div className="me-3">
                                    <i className="fas fa-th-large"></i>
                                </div>
                                <div>Dashboard</div>
                            </a>

                            <a
                                className="dropdown-item d-flex align-items-center"
                                target="_blank"
                                rel="noreferrer"
                                href="https://ua-dashboard.greenpandagames.com/apps"
                            >
                                <div className="me-3">
                                    <i className="fas fa-bullhorn"></i>
                                </div>
                                <div>UA Dashboard</div>
                            </a>
                        </div>
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                        <div
                            className="nav-link dropdown-toggle"
                            id="userDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <div className="me-3 d-none d-sm-block small text-gray-600">
                                <span>{name || email}</span>
                            </div>
                            <img className="img-profile rounded-circle" src={picture || profileIcon} alt="" />
                        </div>
                        <div
                            className="dropdown-menu dropdown-menu-light dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown"
                        >
                            <a className="dropdown-item" href={provider + '/profile'}>
                                <i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href={provider + '/account/settings'}>
                                <i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>
                                Settings
                            </a>
                            <a className="dropdown-item" href={provider + '/account/settings/password'}>
                                <i className="fas fa-key fa-sm fa-fw me-2 text-gray-400"></i>
                                Change Password
                            </a>
                            <a className="dropdown-item" href={provider + '/organization/members'}>
                                <i className="fas fa-users fa-sm fa-fw me-2 text-gray-400"></i>
                                Organization
                            </a>
                            <hr className="dropdown-divider" />
                            <a
                                className="dropdown-item"
                                href="#logoutModal"
                                data-bs-toggle="modal"
                                data-bs-target="#logoutModal"
                            >
                                <i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default React.memo(Topbar);
