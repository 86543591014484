const events_debugger = `<script>setTimeout(function() {
	var container = document.createElement("div");
	container.style.cssText = 'position: absolute; pointer-events: none; touch-action: none; left: 0; width: 96vw; height: 46vh; bottom: 0; z-index: 500; background-color: rgba(0, 0, 0, 0.3); font-size: 20px; font-weight: 300; color: #00ff00; padding: 2vh 2vw; font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";';
	document.body.appendChild(container);

	var ms = 0;

	var now = function() {
	    return new Date().getTime();
	}

	function formatTime(val) {
		return val > 1000 ? (val/1000).toFixed(2) + " s" : (val + " ms");
	}

	function addElement(text, insertBefore) {
		var el = document.createElement("div");
		el.innerText = text;
		insertBefore ? container.insertBefore(el, container.childNodes[insertBefore]) : container.appendChild(el);
		return el;
	}

	function addSDKListener(event, once, insertBefore) {
		var fn = once ? gp.once : gp.on;
		fn(event, function() {

			var delta = now() - gp.started;

			addElement("[SDK] " + event + " " + formatTime(delta), insertBefore);
		});
	}

	gp.once('ready', function() {

		var timer = addElement("Time: 0 s | FPS: 0 | Taps: 0");
		var time = 0;
		var empty = document.createElement("div");
		container.appendChild(empty);

		function updateStatus() {
			timer.innerText = "Time: " + time + " s | FPS: " + gp.fps + " | Taps: " + gp.clicks;
		}

		gp.on('second', function() {
			time++;
			updateStatus();
		});

		gp.on('tap', function() {
			updateStatus();
		})


		addSDKListener("boot", true, 2);
		addSDKListener("render", true, 2);
		addSDKListener("finish", true, 2);
		addSDKListener("retry", true, 2);
		addSDKListener("install", true, 2);
		addSDKListener("tap", false, 2);
		addSDKListener("pause", false, 2);
		addSDKListener("resume", false, 2);
		addSDKListener("resize", false, 2);

		var analyticsLogEvent = analytics.logEvent;

		analytics.logEvent = function(event, value) {
			analyticsLogEvent(event, value);

			var delta = now() - gp.started;

			addElement("[Analytics] " + event + (value ? " - " + value : "") + " " + formatTime(delta), 2);
		}
	});
}, 0);</script>`;

export { events_debugger };
