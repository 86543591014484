/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext } from 'react';
import Sidebar from 'react-sidebar';
import { layoutContext } from '../../services/contexts';
import { ThemeButton } from '@gpg-web/react';
import Dashboard from './Dashboard';
import Playground from './Playground';

const AppSidebar = (props) => {
    const { playground, topbar, sidebarDocked } = useContext(layoutContext);

    return (
        <Sidebar
            sidebar={
                <>
                    <ul
                        className="navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
                        id="accordionSidebar"
                    >
                        {playground ? <Playground /> : <Dashboard />}

                        {!topbar && sidebarDocked ? (
                            <div className="mt-auto mx-auto mb-3">
                                <ThemeButton />
                            </div>
                        ) : null}
                    </ul>
                </>
            }
            open={props.open}
            docked={props.docked}
            onSetOpen={props.onSetOpen}
            transitions={!props.docked}
            sidebarClassName={'sidebar-parent' + (playground ? ' sidebar-parent-sm' : '')}
            overlayClassName="sidebar-overlay-fix"
            contentClassName={playground ? "" : "topbar-offset"}
            contentId="page-content"
        >
            {props.children}
        </Sidebar>
    );
};

export default AppSidebar;
