/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import GENERAL_CONFIG from '../GENERAL_CONFIG';
import { utils } from '@gpg-web/utils';
import { Networks, SUPPORT_REQUESTS_NETWORKS } from '../../consts';
import Debugger from '../Debugger';
import configer from 'configer.js';

const configurator = {
    options: {},

    replacements: {},

    init: function (options) {
        options = options || {};

        this.options = options;

        this.local = options.local;

        this.sdk_modules = [];

        // this.bannerEnabled = false;

        // this.introId = null;

        this.replacements = {};

        this.titleReplacements = {};

        this.head = { begin: [], end: [] };

        this.body = { begin: [], end: [] };

        this.network = options.network || 'preview';

        // this.ad_dynamic = options.ad_dynamic || false;

        this.network_str = (Networks[this.network] && Networks[this.network].name) || 'Preview';

        this.app_store_url = GENERAL_CONFIG.ios_url.value || window.Playable.app_store_url;

        this.google_play_url = GENERAL_CONFIG.android_url.value || window.Playable.google_play_url;

        this.lang = GENERAL_CONFIG.lang.value || 'auto';

        this.google_width = GENERAL_CONFIG.google_size.value.Width || 480;

        this.google_height = GENERAL_CONFIG.google_size.value.Height || 320;

        // this.analytics = options.analytics || AnalyticsManager.getConfig();

        // if (
        //     !this.local &&
        //     this.analytics.enabled &&
        //     this.analytics.options.vr === undefined &&
        //     this.analytics.tool === 'gp'
        // ) {
        //     this.analytics.options.vr = this.title();

        //     // this.analytics.options.vr = (analytics.demo && builder.local) ? "Demo" : Playable.getTitle();
        // }

        this.scriptHeader = [];

        this.scriptFooter = [];

        if (options.google_rotated) {
            this.google_width = this.google_width + this.google_height;
            this.google_height = this.google_width - this.google_height;
            this.google_width = this.google_width - this.google_height;
        }

        if (this.network === 'adwords') {
            this.sdk_modules.push('google');
        }
    },

    replaceTitle: function (key, value) {
        if (typeof key === 'object' && !(key instanceof RegExp)) {
            for (var i in key) {
                this.replaceTitle(i, key[i]);
            }
        } else if (key !== undefined) {
            if (this.titleReplacements[key] !== undefined) {
                console.warn('Builder: Key ' + key + ' has already been added to title replacements list.');
            }

            this.titleReplacements[key] = value;
        }
    },

    replace: function (key, value) {
        if (typeof key === 'object' && !(key instanceof RegExp)) {
            for (var i in key) {
                this.replace(i, key[i]);
            }
        } else if (key !== undefined) {
            if (this.replacements[key] !== undefined) {
                console.warn('Builder: Key ' + key + ' has already been added to replacements list.');
            }

            this.replacements[key] = value;
        }
    },

    general: function () {
        const Playable = window.Playable;

        this.template = {};

        if (typeof Playable.onbuild === 'function') {
            try {
                this.template = Playable.onbuild.call(Playable.context);
            } catch (err) {
                console.error(err);
            }
        }

        if (this.options.creative) {
            this.replace('{CREATIVE_ID}', this.options.creative._id);
            this.replace('{CREATIVE_TOKEN}', this.options.creative.token);

            if (SUPPORT_REQUESTS_NETWORKS.includes(this.network)) {
                this.sdk_modules.push('analytics');

                if (this.options.creative.dynamic) {
                    this.sdk_modules.push('ad_dynamic');
                }
            }
        }

        // if (!this.local && this.analytics.enabled) {
        //     this.replace('"{ANALYTICS}"', JSON.stringify(this.analytics));
        //     if (this.analytics.tool === 'facebook') {
        //         this.sdk_modules.push('analytics_fb');
        //     } else if (this.analytics.tool === 'google') {
        //         this.sdk_modules.push('analytics_google');
        //     } else if (this.analytics.tool === 'gp') {
        //         AnalyticsManager.create(this.analytics.options.id, this.analytics.options.vr);
        //         this.sdk_modules.push('analytics');
        //     }
        // }

        // this.replace('"{_ANALYTICS_EXTRA_CODE}"', '');

        if (typeof Playable.ad_dynamic === 'object') {
            this.replace('"{AD_DYNAMIC_DEFAULT}"', JSON.stringify(configer.parse(Playable.ad_dynamic)));
        }

        // if (typeof this.ad_dynamic === 'object') {
        //     this.replace('"{AD_DYNAMIC_CONFIG}"', JSON.stringify(this.ad_dynamic));
        //     this.sdk_modules.push('ad_dynamic');
        // }

        this.replaceTitle(this.template.title);

        this.replace(this.template.replacements);

        this.plugins();

        this.replace('"_{GENERAL_VOLUME}"', GENERAL_CONFIG.sound_volume.value);

        this.replace('"{SPLASH_SCREEN_DELAY}"', GENERAL_CONFIG.splash_delay.value * 1000);

        if (window.location.hostname === 'localhost') {
            this.replace('https://playable-api.greenpandagames.com', 'http://localhost:3012');
        }

        if (Playable.translations) {
            let languagePack = {};

            if (this.lang === 'auto') {
                languagePack = Playable.translations;
            } else {
                for (let key in Playable.translations) {
                    languagePack[key] =
                        Playable.translations[key][this.lang] ||
                        Playable.translations[key]['en'] ||
                        undefined;
                }
            }

            this.replace('"{LANGUAGE_PACK}"', JSON.stringify(languagePack));
        }

        if (this.network === 'mytarget' || this.network === 'moloco') {
            this.replace('{GOOGLE_ADS_WIDTH}', '100%');
            this.replace('{GOOGLE_ADS_HEIGHT}', '100%');
        } else {
            this.replace('{GOOGLE_ADS_WIDTH}', this.google_width);
            this.replace('{GOOGLE_ADS_HEIGHT}', this.google_height);
        }

        this.replace('{GPP_TITLE}', Playable.appName);

        this.replace('{GPP_GOOGLE_PLAY_MARKET_URL}', this.google_play_url);

        this.replace('{GPP_IOS_APP_STORE_URL}', this.app_store_url);

        this.replace('{PLAYABLE_HASH}', Playable.hash);

        this.replace('{PLAYABLE_DATE}', Date.now());

        this.sdk_modules.push('splash');

        Debugger.onbuild(this);
    },

    plugins: function () {
        const Playable = window.Playable;

        const activated = Playable.plugins.activeList;

        for (let pluginUid of activated) {
            Playable.plugins.env.enable(pluginUid);

            const files = Playable.plugins.env.CURRENT_PLUGIN.sources;

            if (typeof Plugin.onbuild === 'function') {
                try {
                    const template = Plugin.onbuild.call(Plugin.context, this.network);

                    if (template.enabled !== false) {
                        for (let key in files) {
                            this.scriptFooter.push(files[key]);
                        }

                        this.replace(template.replacements);
                        this.replaceTitle(template.title);
                    }
                } catch (err) {
                    console.error(err);
                }

                Playable.plugins.env.resetContext();
            } else {
                for (let key in files) {
                    this.scriptFooter.push(files[key]);
                }
            }
        }
    },

    // PLAYABLE_TITLE
    title: function () {
        const Playable = window.Playable;

        let generated_playable_title = GENERAL_CONFIG.playable_title.value || '{ID}_{HASH}';

        let network_exch_str = this.network_str;

        if (this.options.ignore_network_title === true) {
            network_exch_str = '';
        }

        // if ( el('_dev_vconsole_is_on').checked )
        // {
        //     network_exch_str = "vConsole_" + network_exch_str;
        // }

        if (
            this.options.include_network_title &&
            generated_playable_title.indexOf('{EXCH}') === -1 &&
            generated_playable_title.indexOf('{EXCHANGE}') === -1
        ) {
            generated_playable_title = network_exch_str + '_' + generated_playable_title;
        } else {
            generated_playable_title = generated_playable_title.replaceAll('{EXCH}', network_exch_str);
            generated_playable_title = generated_playable_title.replaceAll('{EXCHANGE}', network_exch_str);
        }

        if (generated_playable_title.startsWith('_')) {
            generated_playable_title = generated_playable_title.substr(1);
        }

        if (this.network === 'ironsource_nucleo') {
            generated_playable_title = 'nuc_' + generated_playable_title;
        }

        var options_hash = window.configer.hash(Playable.config);
        generated_playable_title = generated_playable_title.replaceAll('{PLAYABLE}', Playable.name);
        generated_playable_title = generated_playable_title.replaceAll(
            '{playable}',
            Playable.name.toLowerCase()
        );
        generated_playable_title = generated_playable_title.replaceAll('{NAME}', Playable.name);
        generated_playable_title = generated_playable_title.replaceAll('{name}', Playable.name.toLowerCase());
        generated_playable_title = generated_playable_title.replaceAll('{ID}', Playable.id);
        generated_playable_title = generated_playable_title.replaceAll(
            '{id}',
            ('' + Playable.id).toLowerCase()
        );
        generated_playable_title = generated_playable_title.replaceAll('{PLAYABLE_HASH}', Playable.hash);
        generated_playable_title = generated_playable_title.replaceAll('{CONFIG_HASH}', options_hash);
        generated_playable_title = generated_playable_title.replaceAll('{SCENE}', Playable.scene_id);
        generated_playable_title = generated_playable_title.replaceAll(
            '{HASH}',
            options_hash + Playable.hash
        );
        generated_playable_title = generated_playable_title.replaceAll('{TIMESTAMP}', utils.timestamp());

        generated_playable_title = generated_playable_title.replaceAll('{LANG}', this.lang.toUpperCase());
        generated_playable_title = generated_playable_title.replaceAll('{lang}', this.lang);

        // if (typeof this.ad_dynamic === 'object') {
        //     generated_playable_title += '_DynamicAd';
        // }

        // if (this.bannerEnabled)
        // {
        //     var bannerHash = ConfigManager.hash(BannerManager.config);
        //     generated_playable_title = generated_playable_title.replaceAll('{BLC}', "banner_" + BANNER_TITLE_COUNTER);
        //     generated_playable_title = generated_playable_title.replaceAll('{BLH}', "banner_" + bannerHash);
        //     generated_playable_title = generated_playable_title.replaceAll('{BC}', BANNER_TITLE_COUNTER);
        //     generated_playable_title = generated_playable_title.replaceAll('{BH}', bannerHash);
        // }
        // else
        // {
        //     generated_playable_title = generated_playable_title.replaceAll('{BC}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{BH}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{BLC}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{BLH}', "");
        // }

        // if (this.introId)
        // {
        //     var introHash = ConfigManager.hash(IntroManager.currentConfig);
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_ID}', this.introId);
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO}', this.introTitle);
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_ID_HASH}', this.introId + "_" + introHash);
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_HASH}', this.introTitle + "_" + introHash);
        // }
        // else
        // {
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_ID}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_HASH}', "");
        //     generated_playable_title = generated_playable_title.replaceAll('{INTRO_ID_HASH}', "");
        // }

        // generated_playable_title = generated_playable_title.replaceAll('{DEV_VERSION}', DEV_VERSION);

        // if (this.options.include_lang_title && this.lang != "auto")
        // {
        //     generated_playable_title = this.lang.toUpperCase() + '_' + generated_playable_title;
        // }

        // // if (el('super_compress').checked) {
        // //     generated_playable_title = 'LZ_str_' + generated_playable_title;
        // // }

        if (this.options.title_prefix) {
            generated_playable_title = this.options.title_prefix + generated_playable_title;
        }

        if (this.options.title_sufix) {
            generated_playable_title = generated_playable_title + this.options.title_sufix;
        }

        if (this.network === 'adwords') {
            if (this.google_width > this.google_height) {
                generated_playable_title = 'Landscape_' + generated_playable_title;
            } else {
                generated_playable_title = 'Portrait_' + generated_playable_title;
            }
        }

        for (let key in this.titleReplacements) {
            generated_playable_title = generated_playable_title.replaceAll(key, this.titleReplacements[key]);
        }

        generated_playable_title = generated_playable_title.replaceAll(' ', '_');
        // generated_playable_title = generated_playable_title.replaceAll('-', '_');
        generated_playable_title = generated_playable_title.replace(/[^a-zA-Z0-9_-]/g, '');

        if (generated_playable_title.startsWith('_')) {
            generated_playable_title = generated_playable_title.substr(1);
        }

        this._title = generated_playable_title;

        return generated_playable_title;
    }
};

export default configurator;
