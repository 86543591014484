const SceneTypes = {
	playable: {
		name: 'Playable',
		color: 'primary',
		icon: 'fas fa-gamepad'
	},
	iec: {
		name: 'Interactive Endcard',
		color: 'info',
		icon: 'fas fa-image'
	}
};

const SceneTags = ['classic', 'tap', 'choice', 'frozen city', 'multiplayer', 'fix', 'lootbox', 'social', 'simulation', 'merge', 'puzzle'];

export { SceneTypes, SceneTags };
