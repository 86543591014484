import DEBUGGER_CONSTATS from './DEBUGGER_CONSTATS';
import { EventEmitter } from '@gpg-web/utils';
import libs from './libs';

let Playable = {};

let size = 0,
    sizeInKB = 0,
    fps = 0;

let sdk_steps_status = [],
    globals_status = [];

function updateFps() {
    if (Playable.demo) {
        fps = Playable.demo.gp.fps;
        Debugger.emit('fps', fps);
    }
}

function checkForErrors() {
    const hasErrors =
        size > 2 ||
        globals_status.some((step) => step === 'danger') ||
        sdk_steps_status.some((step) => step === 'danger');

    Debugger.emit('error', !!hasErrors);
}

// function getErrors() {
//     return errors;
// }

function updateSize(playable) {
    const content = playable.demo.document.getElementsByTagName('HTML')[0].outerHTML;

    sizeInKB = Math.floor(content.length / 1000);
    size = Math.floor((content.length / 1000 / 1000) * 100) / 100;

    Debugger.emit('size', size);
    checkForErrors();
}

function setSDKCall(i) {
    for (var j = 0; j < i; j++)
        sdk_steps_status[j] = sdk_steps_status[j] === 'warning' ? 'danger' : sdk_steps_status[j];
    sdk_steps_status[i] = sdk_steps_status[i] === 'danger' ? 'danger' : 'success';

    updateSDKCalls();
}

function updateSDKCalls() {
    Debugger.emit('sdk', sdk_steps_status.slice());
    checkForErrors();
}
function updateGlobalsStatus() {
    Debugger.emit('requirements', globals_status.slice());
    checkForErrors();
}

function resetSDKCalls() {
    sdk_steps_status = [];
    let sdkCallsLength = DEBUGGER_CONSTATS.SDK_EVENTS.length;
    while (sdkCallsLength--) sdk_steps_status.push('warning');
    updateSDKCalls();
}

function resetGlobals() {
    globals_status = [];
    let sdkCallsLength = DEBUGGER_CONSTATS.PLAYABLE_GLOBALS.length;
    while (sdkCallsLength--) globals_status.push('warning');
    updateGlobalsStatus();
}

function checkPlayableGlobals(playable) {
    const pw = playable.demo;

    // DEBUGGER_CONSTATS.PLAYABLE_GLOBALS.forEach((g, i) => {
    //     const global = g.parent ? pw[g.parent][g.name] : pw[g.name];
    //     if (typeof global === g.type) {
    //         globals_status[i] = 'success';
    //     } else globals_status[i] = 'danger';
    // });

    updateGlobalsStatus();
}

function checkTechnologies(playable) {
    const techs = [];

    const pw = playable.demo;

    DEBUGGER_CONSTATS.TECHNOLOGIES.forEach((t) => {
        const exists = t.test(pw);

        if (exists) {
            const { title, version, url, github, description, img, tips } = t;

            techs.push({
                title,
                version,
                url,
                github,
                description,
                img,
                tips
            });
        }
    });

    Debugger.emit('technologies', techs);
}

// function updateRepository(playable) {
//     Debugger.emit("repository", playable.repository)
// }

let timer = null,
    startTime = null,
    timeKeys = [];

function startTimer(gp) {
    clearInterval(timer);

    timeKeys = [];

    startTime = Date.now();

    timer = setInterval(() => {
        const time = Date.now() - startTime;

        Debugger.emit('time', time, timeKeys);
    }, 100);

    gp.once('render', () => {
        const time = Date.now() - startTime;

        timeKeys.push({ name: 'Ready', time: time });

        Debugger.emit('time', time, timeKeys);
    });

    gp.once('finish', () => {
        const time = Date.now() - startTime;

        timeKeys.push({ name: 'Finish', time: time });

        Debugger.emit('time', time, timeKeys);
    });

    gp.once('install', () => {
        clearInterval(timer);

        const time = Date.now() - startTime;

        timeKeys.push({ name: 'CTA', time: time });

        Debugger.emit('time', time, timeKeys);
    });
}

const consoleErrors = [];
function trackErrors(playable) {
    consoleErrors.length = 0;

    Debugger.emit('console:error', consoleErrors);
    Debugger.emit('error', false);

    playable.demo.console_error = playable.demo.console.error;

    playable.demo.console.error = (...args) => {
        playable.demo.console_error(...args);

        consoleErrors.push(args[0]);
        Debugger.emit('console:error', consoleErrors);
        Debugger.emit('error', consoleErrors.length > 0);
    };

    playable.demo.addEventListener('error', (event) => {
        consoleErrors.push(event.message);
        Debugger.emit('console:error', consoleErrors);
        Debugger.emit('error', consoleErrors.length > 0);
    });
}

function onPlayableRestart() {
    resetSDKCalls();
    resetGlobals();

    trackErrors(Playable);

    const gp = Playable.demo.gp;

    gp.on('second', updateFps);

    updateSize(Playable);
    startTimer(gp);

    DEBUGGER_CONSTATS.SDK_EVENTS.forEach((e, i) => {
        gp.on(e.event, () => {
            setSDKCall(i);
        });
    });

    gp.once('postrender', () => {
        checkPlayableGlobals(Playable);
        checkTechnologies(Playable);
    });
}

function init(playable) {
    destroy();

    Playable = playable;

    // updateRepository(Playable);

    Playable.on('restart', onPlayableRestart);
}

function destroy() {
    Playable && Playable.off && Playable.off('restart', onPlayableRestart);
}

function getFPS() {
    return fps;
}

function getSize() {
    return size;
}

function getSizeInKB() {
    return sizeInKB;
}

let devOpt = DEBUGGER_CONSTATS.DEVELOPER_OPTIONS.reduce((final, val) => {
    final[val.name] = val.value || false;
    return final;
}, {});

const saved = window.localStorage.getItem('playable-debugger-options');

if (saved) devOpt = JSON.parse(saved);

function setDevOptions(opt) {
    devOpt = opt;
    window.localStorage.setItem('playable-debugger-options', JSON.stringify(devOpt));
    Debugger.emit('options', devOpt);
}

function getDevOptions() {
    return devOpt;
}

function onbuild(builder) {
    const options = getDevOptions();

    if (options.vconsole) {
        builder.scriptHeader.push(libs.vconsole);
    }

    // if (options.vconsole_min) {
    //     builder.scriptHeader.push(libs.vconsole_min)
    // }

    if (options.fps_meter) {
        builder.scriptFooter.push({
            id: '_fps_meter',
            type: 'js',
            content: libs.fps_meter
        });
    }

    if (options.events_debugger) {
        builder.head.begin.splice(0, 0, libs.events_debugger);
    }

    if (options.console_debugger) {
        builder.head.begin.splice(0, 0, libs.console_debugger);
    }

    if (builder.local) {
        if (options.network_ui) {
            builder.scriptFooter.push({
                id: '_network_ui_script',
                type: 'js',
                content: libs.network_ui_script
            });
            builder.body.begin.push(libs.network_ui);
        }
    }
}

const Debugger = {
    init,
    destroy,
    getFPS,
    getSize,
    getSizeInKB,
    setDevOptions,
    getDevOptions,
    onbuild
    // getErrors
};

EventEmitter.call(Debugger);

export default Debugger;
