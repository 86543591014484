/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */
import { copyTextToClipboard } from '@gpg-web/utils';
import hint from './Hint';

let modal = null;

let qrcode = null;

const QrPreview = function (text) {
    const el = document.getElementById('qrPreviewModal');

    if (qrcode === null) {
        qrcode = new window.QRCode('qrCodeImage');
    }

    if (modal === null) {
        modal = new window.bootstrap.Modal(el);
    }

    el.querySelector('#qrPreviewText').value = text;

    el.querySelector('#qrPreviewModalCopy').onclick = function () {
        copyTextToClipboard(text);
        hint('Link copied to clipboard!', { alert: 'success' });
    };

    qrcode.makeCode(text);

    // el.addEventListener('hidden.bs.modal', function()
    // {
    // 	cb && cb(true);

    // }, {once: true});

    modal.show();
};

export default QrPreview;
