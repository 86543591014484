/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import Builder from './Builder';
import JSZip from 'jszip';
import { utils } from '@gpg-web/utils';
import { saveAs } from 'file-saver';
import { Networks } from '../consts';
import { getCreative } from '../services/creative';
import GENERAL_CONFIG from './GENERAL_CONFIG';

async function buildNetworks(zip, params, creative) {
    for (let network of params.networks) {
        let folder = zip;

        if (params.split_folders_by_network) {
            folder = zip.folder(Networks[network].name);
        }

        const options = {
            network: network,
            creative: creative,
            ignore_network_title: params.split_folders_by_network,
            include_network_title: !params.split_folders_by_network,
            title_prefix: params.prefix || '',
            title_sufix: params.sufix || ''
        };

        if (creative) {
            GENERAL_CONFIG.playable_title.value = creative.name;
            // options.title = customTitle + '_' + network;
        }

        const result = await Builder.production(options);

        if (result.blob) {
            folder.file(result.fileName, result.blob);
        } else {
            folder.file(result.fileName, result.content);
        }

        if (network === 'adwords') {
            options.google_rotated = true;

            const result = await Builder.production(options);

            if (result.blob) {
                folder.file(result.fileName, result.blob);
            } else {
                folder.file(result.fileName, result.content);
            }
        }
    }
}

async function downloadFolder(params) {
    const Playable = window.Playable;

    const zip = new JSZip();

    if (params.creatives) {
        const currentSnapshot = Playable.snapshotConfigs();

        for (let crea of params.creatives) {
            const creative = await getCreative(crea._id);
            const folder = zip.folder(creative.name);

            if (!creative.data) throw new Error("Creative data can't be found");

            await Playable.applyConfigs(creative.data, false);

            await buildNetworks(folder, params, creative);
        }

        await Playable.applyConfigs(currentSnapshot, false);
    } else {
        await buildNetworks(zip, params);
    }

    let zipOptions = { type: 'blob' };

    if (params.compression_level) {
        zipOptions = {
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
                level: params.compression_level
            }
        };
    }

    const zipBlobContent = await zip.generateAsync(zipOptions);

    saveAs(zipBlobContent, (params.name || 'Result_' + utils.timestamp()) + '.zip');
}

export default downloadFolder;
