import configer from 'configer.js';

export const restartPlayable = (html, config) => {
    const Playable = window.Playable;

    const parsed = configer.parse(config.ad_dynamic.config);
    html = html.replace('"{AD_DYNAMIC_DEFAULT}"', JSON.stringify(parsed));

    Playable.applySourceToFrame(html);
};

export const recalculatePriorities = (list) => {
    var canContinue = false;

    for (var i in list) {
        if (list[i].status !== undefined) {
            canContinue = true;
            break;
        }
    }

    if (canContinue) {
        let topPriority = 0;
        let best = null;

        for (var l = 0; l < list.length; l++) {
            if (list[l].enabled) {
                if (list[l].status === 0) {
                    best = list[l];
                } else if (list[l].status >= 1) {
                    if (list[l].priority !== 1) {
                        list[l].priority = 1;
                    }

                    topPriority += 1;
                } else {
                    if (list[l].priority !== 2) {
                        list[l].priority = 2;
                    }

                    topPriority += 2;
                }
            }
        }

        if (best) {
            if (best.priority !== topPriority) {
                best.priority = topPriority;
            }
        }
    }
};
