import DebuggerCard from './DebuggerCard';
import PlayableDebugger from '../../../playground/Debugger';
import { useEffect, useState } from 'react';

const SizeCard = (prop) => {
    const [size, setSize] = useState(PlayableDebugger.getSize());

    useEffect(() => {
        const sizeCb = (playableSize) => {
            setSize(playableSize);
        };

        PlayableDebugger.on('size', sizeCb);

        return () => {
            PlayableDebugger.off('size', sizeCb);
        };
    }, []);

    let status = '';

    if (size >= 2) {
        status = 'danger';
    } else if (size > 1.99) {
        status = 'warning';
    } else {
        status = 'success';
    }

    return (
        <>
            <DebuggerCard
                title="File size"
                min={prop.min}
                status={status}
                value={size}
                label="MB"
            ></DebuggerCard>
        </>
    );
};

export default SizeCard;
