import { useState, useRef, useEffect } from 'react';
import {
    Form,
    Schema,
    Button,
    Modal,
    SelectPicker,
    InputGroup,
    Grid,
    Row,
    Col,
    useToaster,
    Message,
    InputPicker,
    Divider,
    TagPicker
} from 'rsuite';
import { addScene, updateScene, useApp } from '../../services/app';
import { SceneTypes, SceneTags } from '../../consts';
import { getRepositories } from '../../services/repository';
import { utils } from '@gpg-web/utils';

const { StringType } = Schema.Types;

const model = Schema.Model({
    name: StringType().isRequired('This field is required.')
});

const sceneTypesOptions = Object.keys(SceneTypes).map((e) => ({ value: e, label: SceneTypes[e].name }));
const sceneTagsOptions = SceneTags.map((item) => ({ label: item, value: item }));

const defaultValue = {
    app_store_url: '',
    google_play_url: '',
    repository_id: '',
    repository_scene: '',
    name: '',
    type: 'playable',
    tags: []
};

const EditSceneModal = (props) => {
    const { open, onClose, setScenes, scenes, editing } = props;
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState(defaultValue);
    const [repos, setRepos] = useState(null);
    const [errors, setErrors] = useState(null);
    const [reposRows, setReposRows] = useState([]);
    const [sceneRows, setSceneRows] = useState([]);
    const toaster = useToaster();
    const app = useApp();

    useEffect(() => {
        if (errors) {
            toaster.push(
                <Message showIcon type="error">
                    {errors}
                </Message>,
                { duration: 5000 }
            );
            setErrors(null);
        }
    }, [toaster, errors]);

    useEffect(() => {
        if (!open) return;

        if (editing)
            setFormValue({
                _id: editing._id,
                app_store_url: editing.app_store_url,
                google_play_url: editing.google_play_url,
                name: editing.name,
                type: editing.type,
                tags: editing.tags,
                repository_id: editing.source.id,
                repository_scene: editing.source.scene
            });
        else setFormValue(defaultValue);
    }, [open, editing]);

    useEffect(() => {
        if (!open) return;

        getRepositories().then((repos) => {
            setRepos(repos);
            setReposRows(repos.filter((e) => !!e.build).map((e) => ({ label: e.name, value: e._id })));
        });
    }, [open]);

    useEffect(() => {
        if (!(formValue.repository_id && repos)) return;

        const buildScenes = repos.find((e) => e._id === formValue.repository_id)?.build?.scenes;

        if (!buildScenes) {
            setErrors('build.json scenes not found');
            return;
        }

        setSceneRows(buildScenes.map((e) => ({ label: e.name || e.id, value: e.id })));
    }, [repos, formValue.repository_id]);

    function handleClose() {
        setLoading(false);
        onClose();
    }

    function handleSave() {
        if (!formRef.current.check()) return;

        setLoading(true);

        formValue.app = app._id;
        const fn = editing ? updateScene : addScene;

        fn(formValue)
            .then((res) => {
                if (editing) {
                    const exists = scenes.find((e) => e._id === formValue._id);
                    Object.assign(exists, {
                        name: formValue.name,
                        type: formValue.type,
                        tags: formValue.tags,
                        app_store_url: formValue.app_store_url,
                        google_play_url: formValue.google_play_url
                    });
                    setScenes(scenes.slice());
                } else {
                    scenes.push(res);
                    setScenes(scenes.slice());
                }
                handleClose();
            })
            .catch((err) => {
                setLoading(false);
                toaster.push(
                    <Message showIcon type="error">
                        {err}
                    </Message>,
                    { duration: 5000 }
                );
            });
    }

    return (
        <>
            <Modal open={open} backdrop="static" onClose={handleClose} size="md" overflow={false}>
                <Modal.Header>
                    <Modal.Title>{editing ? 'Edit' : 'New'} Scenario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid fluid>
                        <Row className="show-grid">
                            <Col sm={6}>
                                <div className="me-3">
                                    <img
                                        width="100%"
                                        alt="application icon"
                                        className="w-100 rounded-3"
                                        src={utils.staticUrl(app && app.picture)}
                                    />
                                </div>
                            </Col>
                            <Col sm={18}>
                                <Form
                                    fluid
                                    model={model}
                                    ref={formRef}
                                    disabled={loading}
                                    onChange={setFormValue}
                                    formValue={formValue}
                                >
                                    <Form.Group controlId="name_id">
                                        <Form.ControlLabel>Scenario name</Form.ControlLabel>
                                        <Form.Control name="name" />
                                    </Form.Group>

                                    <Form.Group controlId="type">
                                        <Form.ControlLabel>Scenario type</Form.ControlLabel>
                                        <Form.Control
                                            name="type"
                                            block
                                            cleanable={false}
                                            accepter={InputPicker}
                                            data={sceneTypesOptions}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="repository_id">
                                        <Form.ControlLabel>Source repository</Form.ControlLabel>
                                        <Form.Control
                                            name="repository_id"
                                            block
                                            cleanable={false}
                                            loading={!repos}
                                            disabled={!!editing}
                                            accepter={SelectPicker}
                                            data={reposRows}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="repository_scene">
                                        <Form.ControlLabel>Source repository scene ID</Form.ControlLabel>
                                        <Form.Control
                                            name="repository_scene"
                                            block
                                            cleanable={false}
                                            disabled={!formValue.repository_id || !!editing}
                                            loading={!repos}
                                            accepter={SelectPicker}
                                            data={sceneRows}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="tags">
                                        <Form.ControlLabel>Scenario tags</Form.ControlLabel>
                                        <Form.Control
                                            name="tags"
                                            block
                                            cleanable={false}
                                            accepter={TagPicker}
                                            data={sceneTagsOptions}
                                        />
                                    </Form.Group>

                                    <Divider />

                                    <Form.Group controlId="app_store_url_id">
                                        <Form.ControlLabel>Custom Apple URL</Form.ControlLabel>
                                        <InputGroup inside>
                                            <InputGroup.Addon>
                                                <i className="fab fa-app-store-ios text-gray-500" />
                                            </InputGroup.Addon>
                                            <Form.Control
                                                placeholder={app && app.app_store_url}
                                                name="app_store_url"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="google_play_url_id">
                                        <Form.ControlLabel>Custom Google Play URL</Form.ControlLabel>
                                        <InputGroup inside>
                                            <InputGroup.Addon>
                                                <i className="fab fa-google-play text-gray-500" />
                                            </InputGroup.Addon>
                                            <Form.Control
                                                placeholder={app && app.google_play_url}
                                                name="google_play_url"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                    <Button disabled={loading} loading={loading} onClick={handleSave} appearance="primary">
                        {editing ? 'Save' : 'Create'} Scenario
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { EditSceneModal };
