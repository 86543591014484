/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RestrictAccess } from '../../components/RestrictAccess';
import { appConext } from '../../services/app';
import { playgroundContext } from '../../services/contexts';
import { utils } from '@gpg-web/utils'; 
// import { apps } from '../../services/app';

const apps = {};

const PlaygroundSidebar = (props) => {
    const history = useNavigate();
    const { app } = useContext(appConext);
    const { scene, creative } = useContext(playgroundContext);

    let analyticsLink = '/analytics/performance';

    const hasApp = app && app._id;

    if (hasApp) {
        analyticsLink += '?filter:app=' + app._id;
        if (creative) analyticsLink += '&filter:creative=' + creative._id;
        else if (scene) analyticsLink += '&filter:scene=' + scene._id;
    }

    return (
        <>
            <div
                onClick={() => {
                    history('/');
                }}
                role="button"
                className="brand text-light justify-content-center"
            >
                <div className="text-logo fs-5">
                    <i className="fas fa-puzzle-piece" />
                </div>
            </div>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item">
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-gamepad"></i>
                </Link>
            </li>
            <RestrictAccess requiredLvl={1}>
                <li className="nav-item">
                    <Link className="nav-link" to={analyticsLink}>
                        <i className="fas fa-chart-bar"></i>
                    </Link>
                </li>
            </RestrictAccess>

            {hasApp && (
                <li className="nav-item" title={app.name}>
                    <Link className="nav-link d-flex justify-content-center" to={'/apps/' + app._id}>
                        <img alt="Playable logo" className="icon-5 rounded-1" src={utils.staticUrl(app.picture)} />
                    </Link>
                </li>
            )}

            {hasApp &&
                Object.keys(apps).map((e) => {
                    const p = apps[e];

                    if (p._id === app._id || !p.loaded) return undefined;

                    return (
                        <li key={p._id} className="nav-item" title={p.name}>
                            <Link
                                className="nav-link d-flex justify-content-center"
                                to={'/playground/' + p._id}
                            >
                                <img alt="Playable logo" className="icon-5 rounded-1" src={utils.staticUrl(p.picture)} />
                            </Link>
                        </li>
                    );
                })}
        </>
    );
};

export default PlaygroundSidebar;
