/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';

const noop = function () {};

function loadFiles(options) {
    return new Promise((resolve, reject) => {
        let { files, baseURL, hash, onProgress, onLoaded, crossOrigin, applyToContext } = options;

        onProgress = onProgress || noop;
        onLoaded = onLoaded || noop;

        const result = applyToContext ? null : [];
        // : {
        //       js: {},
        //       css: {},
        //       json: {}
        //   };

        hash = hash || 'nohash';

        if (crossOrigin === void 0) crossOrigin = true;

        let _files = [];

        if (typeof files === 'object') {
            if (!Array.isArray(files)) {
                _files = Object.keys(files).map((e) => {
                    return { id: e, url: files[e] };
                });
            } else {
                _files = files.map((e) => {
                    return { id: utils.baseName(e), url: e };
                });
            }
        }

        if (_files.length === 0) {
            resolve(result);
            return;
        }

        function loadNextScript() {
            var done = false;
            const body = document.body;

            const xmlhttp = new XMLHttpRequest();

            const currentFile = _files.shift();

            let fileUrl = currentFile.url;
            const key = currentFile.id;

            onProgress(fileUrl, xmlhttp);

            if (!(fileUrl.startsWith('http://') || fileUrl.startsWith('https://'))) {
                fileUrl = baseURL + '/' + fileUrl;
            }

            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    onLoaded();

                    let text = xmlhttp.responseText;

                    if (fileUrl.indexOf('.json') > -1) {
                        try {
                            // text = JSON.parse(text);

                            if (!applyToContext) {
                                result.push({
                                    id: key,
                                    type: 'js',
                                    content: `window["${key}"]=${text};`
                                });
                                // result.json[key] = text;
                            }
                        } catch (e) {
                            utils.hintError(
                                "Your json file <a target='_blank' href='" +
                                    fileUrl +
                                    "'>" +
                                    fileUrl +
                                    '</a> is invalid.'
                            );
                        }
                    } else if (fileUrl.indexOf('.css') > -1) {
                        if (!applyToContext) {
                            // result.css[key] = text;
                            result.push({
                                id: key,
                                type: 'css',
                                content: text
                            });
                        }
                    } else if (fileUrl.indexOf('.js') > -1) {
                        if (applyToContext) {
                            const script = document.createElement('script');
                            script.type = 'text/javascript';
                            script.setAttribute(
                                'id',
                                fileUrl.substring(fileUrl.lastIndexOf('/') + 1).replace('.js', '')
                            );
                            script.innerHTML = '(function(){' + text + '})()';

                            body.appendChild(script);
                        } else {
                            text = text.trim();
                            if (text[text.length - 1] !== ';') text += '\n';
                            // result.js[key] = text;
                            result.push({
                                id: key,
                                type: 'js',
                                content: text
                            });
                        }
                    } else if (fileUrl.indexOf('.html') > -1) {
                        if (applyToContext) {
                            // ignore
                        } else {
                            result.push({
                                id: key,
                                type: 'html',
                                content: text.trim()
                            });
                        }
                    }

                    scriptLoaded();
                }
            };

            xmlhttp.open('GET', utils.staticUrl(fileUrl) + '?hash=ddf' + hash, crossOrigin);
            xmlhttp.send();

            function scriptLoaded() {
                // check done variable to make sure we aren't getting notified more than once on the same script
                if (!done) {
                    xmlhttp.onreadystatechange = xmlhttp.onload = xmlhttp.onprogress = null; // kill memory leak in IE

                    done = true;

                    if (_files.length !== 0) {
                        loadNextScript();
                    } else {
                        resolve(result);
                    }
                }
            }
        }

        loadNextScript();
    });
}

export default loadFiles;
