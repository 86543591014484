/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import PlayableFrame from './PlayableFrame';
import DeviceBar from './DeviceBar';
import ControlBar from './ControlBar';

const PlayPanel = () => {
    return (
        <>
            <DeviceBar />
            <PlayableFrame />
            <ControlBar />
        </>
    );
};

export default PlayPanel;
