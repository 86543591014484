import { useContext } from 'react';

import { userContext } from '../services/user';

export const RestrictAccess = ({ requiredLvl, children }) => {
    const user = useContext(userContext);

    if (user.lvl >= requiredLvl) {
        return <>{children}</>;
    }

    return null;
};
