import { useContext } from 'react';
import { utils } from '@gpg-web/utils';
import { adDynamicContext } from '../../../services/contexts';
import { userContext } from '../../../services/user';
import { recalculatePriorities } from '../utils';

const ManagePane = () => {
    const { dynamic, updateVariation, selectedVariation, removeVariation } = useContext(adDynamicContext);
    const { email } = useContext(userContext);

    const onChangeName = (e) => {
        updateVariation('name', e.target.value);
    };

    const onChangeEnabled = (e) => {
        updateVariation('enabled', !selectedVariation.enabled);
    };

    const onChangePriority = (e) => {
        updateVariation('priority', Math.min(100, Math.max(1, e.target.value)));
    };

    const setBestVariation = () => {
        utils.confirm('Are you sure you want to set this variation as Best performing?', function (yes) {
            var hasBestAlready = null;

            for (var i = 0; i < dynamic.list.length; i++) {
                if (dynamic.list[i].status === 0) {
                    hasBestAlready = dynamic.list[i];
                    break;
                }
            }

            function setAsBest() {
                selectedVariation.status = 0;

                selectedVariation.log.push({
                    t: Date.now(),
                    a: email,
                    msg: 'Setted as Best performing'
                });

                recalculatePriorities(dynamic.list);
                updateVariation('status', 0);
            }

            if (hasBestAlready) {
                utils.confirm(
                    'The Best one has setted already, are you sure you want to rewrite it?',
                    function (yes2) {
                        if (yes2) {
                            hasBestAlready.status = 1;

                            setAsBest();
                        }
                    }
                );
            } else {
                setAsBest();
            }
        });
    };

    const setMediumVariation = () => {
        utils.confirm('Are you sure you want to set this variation as Medium performing?', function (yes) {
            if (yes && selectedVariation.status !== 1) {
                selectedVariation.status = 1;

                selectedVariation.log.push({
                    t: Date.now(),
                    a: email,
                    msg: 'Setted as Medium performing'
                });

                recalculatePriorities(dynamic.list);
                updateVariation('status', 1);
            }
        });
    };

    const setPoorVariation = () => {
        utils.confirm('Are you sure you want to set this variation as Bad performing?', function (yes) {
            if (yes) {
                selectedVariation.status = 2;
                selectedVariation.log.push({
                    t: Date.now(),
                    a: email,
                    msg: 'Setted as Poor performing'
                });
                recalculatePriorities(dynamic.list);
                updateVariation('status', 2);
            }
        });
    };

    return (
        <>
            <div className="px-2">
                <div className="">
                    <input
                        type="text"
                        className="form-control mt-1"
                        value={selectedVariation.name}
                        onChange={onChangeName}
                        placeholder="Variation name"
                    />
                </div>
                <div className="mt-3">
                    <button
                        disabled={selectedVariation.status === 0}
                        className={
                            'btn btn-sm m-1 ' +
                            (selectedVariation.status === 0 ? 'btn-primary' : 'btn-outline-primary')
                        }
                        onClick={setBestVariation}
                    >
                        Set as Best Score
                    </button>
                    <button
                        disabled={selectedVariation.status === 1}
                        className={
                            'btn btn-sm m-1 ' +
                            (selectedVariation.status === 1 ? 'btn-primary' : 'btn-outline-primary')
                        }
                        onClick={setMediumVariation}
                    >
                        Set as Medium Score
                    </button>
                    <button
                        disabled={selectedVariation.status > 1}
                        className={
                            'btn btn-sm m-1 ' +
                            (selectedVariation.status > 1 ? 'btn-primary' : 'btn-outline-primary')
                        }
                        onClick={setPoorVariation}
                    >
                        Set as Bad Score
                    </button>
                </div>

                <div id="dynamic-variation-control-block" className="mt-4 px-2 d-flex flex-column">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input me-2"
                            id="dynamic-variation-enabled"
                            checked={selectedVariation.enabled}
                            onChange={onChangeEnabled}
                        />
                        <label className="custom-control-label" htmlFor="dynamic-variation-enabled">
                            {' '}
                            Enabled <span className="text-muted">(uncheck to temporary ignore it)</span>
                        </label>
                    </div>

                    <div className="my-3 d-flex align-items-center">
                        <label htmlFor="dynamic-variation-priority">Variation priority</label>
                        <input
                            type="number"
                            className="form-control form-control-sm mx-2"
                            style={{ width: '100px' }}
                            value={selectedVariation.priority}
                            id="dynamic-variation-priority"
                            onChange={onChangePriority}
                            min="1"
                            max="100"
                        />
                        <label htmlFor="dynamic-variation-priority" className="text-muted ml-2">
                            from 1 to 100
                        </label>
                    </div>
                    <hr />
                    <div className="mt-3 d-flex align-items-center justify-content-end">
                        <button onClick={removeVariation} className="btn btn-sm ms-2 btn-danger">
                            <i className="fas fa-times me-1" /> Delete variation
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManagePane;
