/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { utils } from '@gpg-web/utils';
import { getPluginsRepository } from '../../services/repository';
import env from './Env';
import axios from 'axios';
import loadFiles from '../Loader/loadFiles';
import { REPO_BASE_DIR } from '../Loader/config';
import configer from 'configer.js';
import dom from './Dom';

const { resetContext, cache } = env;

let pluginsRepo = null;
let pluginsRepositoryList = null;

let list = {};
let activeList = [];
let Playable = null;
let customizeUid = null;

// repo:sticker: {
//  id: "sticker",
//  name: "Sticker",
//  description: "",
//  picture: "",
//  tool_files: [],
//  build_files: []
// }

async function preload() {
    utils.popup('Plugins files loading...');

    resetContext();

    if (pluginsRepo === null) {
        pluginsRepo = await getPluginsRepository();
    }

    if (pluginsRepositoryList === null) {
        pluginsRepositoryList = await axios(
            utils.staticUrl(REPO_BASE_DIR + pluginsRepo.name) +
                '/list.json?nocache=' +
                new Date(pluginsRepo.updated_at).getTime()
        );
    }
}

function register(append, key, repository) {
    if (!Array.isArray(append)) append = [append];

    for (let pluginConfig of append) {
        if (repository) pluginConfig.repo = repository;

        if (pluginConfig) list[key + pluginConfig.id] = pluginConfig;
    }
}

let _initilized = false;

async function init(playable) {
    utils.popup('Plugins initializing...');
    _initilized = false;

    dom.init();

    activeList = [];

    list = {};

    Playable = playable;

    register(pluginsRepositoryList, 'repo');

    if (playable.build_config.plugins) {
        register(playable.build_config.plugins, playable.id, playable.repository);
    }

    Playable.emit('plugins:list', list, activeList);

    // await add('repobanner', false);

    _initilized = true;

    Playable.emit('plugins:ready');

    // register playable plugins register(pluginsRepositoryList, playable.id);
    // register scenes plugins register(pluginsRepositoryList, playable.id + scene.id);
}

function generateHash(repo) {
    let hash = null;

    if (repo && repo.commit) hash = repo.commit.id.substr(0, 7);
    else hash = new Date(repo.updated_at).getTime();

    return hash;
}

async function add(pluginUid, restart = true, customConfig) {
    const pluginConfig = list[pluginUid];

    if (!pluginConfig) return;

    const repo = pluginConfig.repo || pluginsRepo;

    if (!cache[pluginUid]) {
        // utils.popup('loading');
        Playable.emit('plugins:loading', pluginUid);

        Plugin.context = {};

        const pluginsHash = generateHash(repo);
        let pluginBaseDir = REPO_BASE_DIR + repo.name;

        if (pluginConfig.base_path) {
            pluginBaseDir += '/' + pluginConfig.base_path;
        }

        await loadFiles({
            files: pluginConfig.tool_files,
            baseURL: pluginBaseDir,
            hash: pluginsHash,
            applyToContext: true
        });

        const pluginFiles = await loadFiles({
            files: pluginConfig.build_files,
            baseURL: pluginBaseDir,
            hash: pluginsHash
        });

        cache[pluginUid] = {
            id: pluginConfig.id,
            onbuild: Plugin.onbuild,
            config: Plugin.config,
            context: Plugin.context,
            sources: pluginFiles,
            snapshot: configer.snapshot(Plugin.config)
        };

        resetContext();

        // utils.popup('hide');
    }

    if (customConfig) cache[pluginUid].config = configer.mixin(cache[pluginUid].config, customConfig);

    activeList.push(pluginUid);

    Playable.emit('plugins:add', pluginUid);
    Playable.emit('plugins:list', list, activeList);

    restart && Playable.restart();
}

function remove(pluginUid, restart = true) {
    utils.remove(activeList, (e) => e === pluginUid);

    Playable.emit('plugins:list', list, activeList);

    if (customizeUid === pluginUid) {
        dom.disableTab();
    }

    restart && Playable.restart();
}

function customize(pluginUid) {
    if (activeList.indexOf(pluginUid) > -1 && cache[pluginUid]) {
        if (cache[pluginUid].config) {
            const lastSave = configer.snapshot(cache[pluginUid].config);

            configer.mixin(cache[pluginUid].config, cache[pluginUid].snapshot);

            const gui = new configer.GUI('plugin_options_' + pluginUid, {
                config: cache[pluginUid].config,
                parent: 'plugin-options'
            });

            configer.mixin(cache[pluginUid].config, lastSave);
            gui.root.apply(true);

            const title = list[pluginUid].name;

            dom.activateTab(title, pluginUid);
            customizeUid = pluginUid;
        }
    }
}

function getPluginURL(pluginUid) {
    const pluginConfig = list[pluginUid];

    if (!pluginConfig) return false;

    const repo = pluginConfig.repo || pluginsRepo;

    if (!repo) return false;

    let pluginBaseDir = REPO_BASE_DIR + repo.name;

    if (pluginConfig.base_path) {
        pluginBaseDir += '/' + pluginConfig.base_path;
    }

    return utils.staticUrl(pluginBaseDir);
}

function isPluginCached(pluginUid) {
    return cache[pluginUid] !== undefined;
}

const PluginManager = {
    init,
    preload,
    register,
    add,
    remove,
    customize,
    getPluginURL,
    isPluginCached,
    cache: cache,
    env: env,

    whenReady: () => {
        if (_initilized) return Promise.resolve(null);

        return new Promise((resolve) => {
            Playable.on('plugins:ready', resolve);
        });
    },

    resetConfigs: function () {
        for (let pluginId in list) {
            isPluginCached(pluginId) && configer.mixin(cache[pluginId].config, cache[pluginId].snapshot);
        }
    },

    resetAll: async function (restart) {
        PluginManager.removeAll(false);
        PluginManager.resetConfigs();

        // await add('repobanner', restart);
    },

    removeAll: function (restart = true) {
        activeList.length = 0;
        Playable.emit('plugins:list', list, activeList);
        dom.disableTab();

        restart && Playable.restart();
    },

    getPluginConfig: function (pluginUid) {
        return cache[pluginUid] ? cache[pluginUid].config : undefined;
    },

    get repository() {
        return pluginsRepo;
    },

    get list() {
        return list;
    },

    get activeList() {
        return activeList;
    }
};

export default PluginManager;
