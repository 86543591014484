/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

let modal = null;

const Alert = function (msg, cb) {
    const el = document.getElementById('alertModal');

    if (modal === null) {
        modal = new window.bootstrap.Modal(el);
    }

    el.querySelector('#alertModalLabel').innerHTML = msg;

    el.addEventListener(
        'hidden.bs.modal',
        function () {
            cb && cb(true);
        },
        { once: true }
    );

    modal.show();
};

export default Alert;
