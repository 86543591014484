import { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { userContext } from '../../../services/user';
import { utils } from '@gpg-web/utils';
import { creativeContext, playgroundContext } from '../../../services/contexts';
import {
    deleteCreative,
    getCreatives,
    addCreative,
    updateCreative,
    getCreative,
    copyCreative
} from '../../../services/creative';
import { initDynamicData } from '../../../services/dynamic';
import configer from 'configer.js';
import DownloadModal from './DownloadModal';
import AdNetworkConnectModal from './AdNetworkConnectModal';

let requestId = 0;

const CreativesProvider = ({ children }) => {
    const Playable = window.Playable;

    const history = useNavigate();
    const location = useLocation();

    const { lvl: userLvl, email: userEmail } = useContext(userContext);

    const [list, setList] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [adNetworkConnecting, setAddNetworkConnecting] = useState(false);

    const { scene, creative, setCreative } = useContext(playgroundContext);

    // const sceneId = Playable.scene ? Playable.scene.id : null;

    // const [scene, setScene] = useState(sceneId);

    const query = new URLSearchParams(location.search);
    const queryCreativeId = Number(query.get('creative'));

    const selectedCreativeId = creative && creative._id;

    useEffect(() => {
        if (!queryCreativeId) {
            if (window.Playable.demo) window.Playable.resetConfigs();
        }
    }, [queryCreativeId]);

    useEffect(() => {
        if (selectedCreativeId === queryCreativeId) return;

        const Playable = window.Playable;

        if (!queryCreativeId || !list) {
            setCreative(null);
            return;
        }

        const exists = list.find((e) => e._id === queryCreativeId);

        if (!exists) {
            setCreative(null);
            utils.hintError('Creative ' + queryCreativeId + ' not found in list');
            return;
        }

        utils.popup('Loading creative...');
        getCreative(exists._id)
            .then((creative) => {
                setCreative(creative);

                function initCreative() {
                    if (Playable.active) {
                        Playable.applyConfigs(creative.data);
                        utils.popup('hide');

                        if (!(creative.created_by === userEmail || userLvl > 1)) return;

                        const actualHash =
                            configer.hash(Playable.config) + configer.hash(Playable.ad_dynamic);

                        if (creative.hash !== actualHash) {
                            utils.confirm(
                                'The set of options were changed by developer. Would you like to apply these changes to keep creative up to date?',
                                (yes) => {
                                    if (!yes) return;

                                    utils.popup('Saving creative...');

                                    updateCreative(creative._id, {
                                        data: Playable.snapshotConfigs(),
                                        hash: actualHash
                                    })
                                        .then((res) => {
                                            utils.popup('hide');
                                            setCreative(res);
                                        })
                                        .catch(utils.hintError);
                                }
                            );
                        }
                    } else setTimeout(initCreative, 100);
                }

                initCreative();
            })
            .catch(utils.hintError);
    }, [queryCreativeId, list, selectedCreativeId, userEmail, userLvl, setCreative]);

    const handleSelectCreative = (creativeId) => {
        if (creativeId === queryCreativeId) return;

        if (creativeId) {
            query.set('creative', creativeId);
        } else {
            query.delete('creative');
        }

        history({
            search: '?' + query.toString()
        });
    };

    const handleRemoveCreative = (creativeId) => {
        const creative = list.find((e) => e._id === creativeId);

        utils.confirm(`Are you sure you want to delete "${creative.name}" creative?`, (yes) => {
            if (!yes) return;

            utils.popup('removing');

            deleteCreative(creativeId)
                .then(() => {
                    handleSelectCreative(null);

                    utils.remove(list, (e) => e._id === creativeId);
                    setList(list.slice());

                    utils.popup('hide');
                })
                .catch(utils.hintError);
        });
    };

    const handleDuplicateCreative = (creativeId) => {
        const creative = list.find((e) => e._id === creativeId);

        let index = 1;
        let newName = creative.name + ' (' + index + ')';

        function checkName(index) {
            newName = creative.name + ' (' + index + ')';

            return list.findIndex((e) => e.name === newName) === -1;
        }

        while (!checkName(index) && index < 100) {
            index++;
        }

        utils.popup('saving');

        copyCreative({
            _id: creativeId,
            name: newName
        })
            .then((res) => {
                list.push(res);
                setList(list.slice());

                handleSelectCreative(res._id);
                utils.popup('hide');
            })
            .catch(utils.hintError);
    };

    const handleCreateCreative = async (dynamic) => {
        utils.popup('saving');
        try {
            const data = {
                name: Playable.getTitle({ ignore_network_title: true }),
                app: scene.app._id,
                scene: scene._id,
                size: Playable.debugger.getSizeInKB(),
                data: Playable.snapshotConfigs(),
                hash: configer.hash(Playable.config) + configer.hash(Playable.ad_dynamic)
            };

            if (dynamic === true) data.dynamic = await initDynamicData();

            const res = await addCreative(data);

            list.push(res);
            setList(list.slice());

            handleSelectCreative(res._id);
        } catch (err) {
            utils.hintError(err);
        }

        utils.popup('hide');
    };

    const handleUpdateCreative = (creative) => {
        creative.data = Playable.snapshotConfigs();
        creative.hash = configer.hash(Playable.config) + configer.hash(Playable.ad_dynamic);
        creative.size = Playable.debugger.getSizeInKB();

        utils.popup('saving');

        updateCreative(selectedCreativeId, creative)
            .then((res) => {
                const exists = list.find((e) => e._id === res._id);

                exists.name = res.name;
                exists.tags = res.tags;

                setList(list.slice());

                utils.popup('hide');

                setCreative(res);
            })
            .catch(utils.hintError);
    };

    const handleLockCreative = (creativeId, lock) => {
        utils.popup('saving');

        updateCreative(creativeId, { lock })
            .then((res) => {
                const exists = list.find((e) => e._id === res._id);

                exists.lock = res.lock;

                setList(list.slice());

                utils.popup('hide');

                if (selectedCreativeId === res._id) {
                    setCreative(res);
                }
            })
            .catch(utils.hintError);
    };

    // useEffect(() => {
    //     const _Playable = window.Playable;

    //     const sceneChanged = (sc) => {
    //         setScene(sc);
    //     };

    //     _Playable.on('scene:changed', sceneChanged);

    //     setScene(sceneId);

    //     return () => {
    //         _Playable.off('scene:changed', sceneChanged);
    //         setScene(null);
    //     };
    // }, [sceneId]);

    useEffect(() => {
        setList(null);

        requestId++;
        const currentRequestId = requestId;

        if (scene) {
            getCreatives(scene._id)
                .then((list) => {
                    if (requestId === currentRequestId) setList(list);
                })
                .catch(utils.hintError);
        }
    }, [scene]);

    return (
        <creativeContext.Provider
            value={{
                list,
                selectedCreative: creative,
                handleCreateCreative,
                handleRemoveCreative,
                handleSelectCreative,
                handleUpdateCreative,
                handleDuplicateCreative,
                handleLockCreative,
                handleDownload: setDownloading,
                handleAdNetworkConnect: setAddNetworkConnecting
                // setName,
                // name,
            }}
        >
            {children}
            <DownloadModal creatives={downloading} show={!!downloading} onHide={setDownloading} />
            <AdNetworkConnectModal
                creativeId={adNetworkConnecting}
                show={!!adNetworkConnecting}
                onHide={setAddNetworkConnecting}
            />
        </creativeContext.Provider>
    );
};

export default CreativesProvider;
