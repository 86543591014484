/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useContext, useState, useEffect } from 'react';
import { playgroundContext } from '../../../services/contexts';
import Hosting from '../../../services/hosting';
import { Alert } from '../../../components';
import { Spinner } from '@gpg-web/react';
import { utils } from '@gpg-web/utils';
import { RestrictAccess } from '../../../components/RestrictAccess';

const HostedFiles = () => {
    const Playable = window.Playable;

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const [selected, setSelected] = useState([]);

    const { scene } = useContext(playgroundContext);

    useEffect(() => {
        if (scene) {
            setLoading(true);
            Hosting.cd(scene._id)
                .list(true)
                .then((list) => {
                    setList(list);
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err);
                    setLoading(false);
                });
        }
    }, [scene]);

    useEffect(() => {
        function addLink(link) {
            setList((list) => [...list, link]);
        }

        Playable.on('build:host', addLink);

        return () => {
            Playable.off('build:host', addLink);
        };
    }, [Playable]);

    if (loading) {
        return (
            <div className="text-muted d-flex align-items-center">
                <Spinner /> &nbsp; Getting list ...
            </div>
        );
    }

    const onSelectChange = (index, value) => {
        if (value) {
            setSelected([...selected, list[index]]);
        } else {
            setSelected(selected.filter((item) => item !== list[index]));
        }
    };

    function remove(e, link) {
        e.stopPropagation();

        utils.confirm('Are you sure you want to remove this file?', function (yes) {
            if (yes) {
                utils.popup('removing');

                Hosting.remove(Hosting.pathname(link))
                    .then(() => {
                        setList(utils.without(list, link))
                        utils.popup('hide');
                    })
                    .catch(utils.hintError);
            }
        });
    }

    async function removeSelected() {
        utils.confirm('Are you sure you want to remove selected files?', async function (yes) {
            if (yes) {
                for (let link of selected) {
                    try {
                        utils.popup('removing');
                        await Hosting.remove(Hosting.pathname(link));

                        setList((prev) => utils.without(prev, link));
                        setSelected((prev) => utils.without(prev, link));
                    } catch (err) {
                        utils.hintError(err);
                    }
                }
                utils.popup('hide');
            }
        });
    }

    function open(e, link) {
        e.stopPropagation();

        window.open(utils.staticUrl('hosting/' + link), '_blank');
    }

    return (
        <div className="files-block">
            <div className="tab-title">Saved files</div>

            {loading ? (
                <div className="text-muted d-flex align-items-center">
                    <Spinner /> &nbsp; Getting list ...
                </div>
            ) : (
                <>
                    <RestrictAccess requiredLvl={1}>
                        {list.length > 1 && (
                            <>
                                <div className="d-flex justify-content-end mb-2 ">
                                    {selected.length !== 0 && (
                                        <div
                                            className="btn btn-sm btn-danger me-1 py-0"
                                            onClick={removeSelected}
                                        >
                                            Delete Selected
                                        </div>
                                    )}
                                    {selected.length !== list.length && (
                                        <div
                                            className="btn me-1 btn-sm btn-secondary py-0"
                                            onClick={() => setSelected(list.slice())}
                                        >
                                            Select All
                                        </div>
                                    )}
                                    {selected.length !== 0 && (
                                        <div
                                            className="btn btn-sm btn-secondary py-0"
                                            onClick={() => setSelected([])}
                                        >
                                            Unselect All
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </RestrictAccess>

                    <div id="host-control files-list">
                        {error && <Alert text={error} />}
                        {list.length === 0 && <span className="text-gray-500">List is empty</span>}
                        <ul className="list-group list-group-flush">
                            {list.map((e, i) => {
                                return (
                                    <UploadedItem
                                        key={e + i}
                                        link={e}
                                        count={list.length}
                                        isSelected={selected.indexOf(e) > -1}
                                        onSelect={onSelectChange.bind(undefined, i)}
                                        remove={remove}
                                        open={open}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

const UploadedItem = (props) => {
    return (
        <li
            type="button"
            className="list-group-item list-group-item-action d-flex justify-content-between align-items-center py-0"
            //
        >
            <RestrictAccess requiredLvl={2}>
                {props.count > 1 && (
                    <div className="form-check me-2 d-inline-block py-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={props.isSelected}
                            onChange={(e) => {
                                e.stopPropagation();
                                props.onSelect(e.target.checked);
                            }}
                        />
                    </div>
                )}
            </RestrictAccess>
            <div
                className="text-clamp w-100 py-2"
                onClick={() => utils.qrPreview(utils.staticUrl('hosting/' + props.link))}
            >
                {utils.fileName(props.link, false)}
            </div>
            <div className="text-nowrap py-2">
                <button onClick={(e) => props.open(e, props.link)} className="btn btn-sm">
                    <i className="fas fa-external-link-alt" />{' '}
                </button>
                <RestrictAccess requiredLvl={2}>
                    <button onClick={(e) => props.remove(e, props.link)} className="btn btn-sm">
                        <i className="fas fa-times" />{' '}
                    </button>
                </RestrictAccess>
            </div>
        </li>
    );
};

export default HostedFiles;
