/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import { useState, useEffect } from 'react';

const Devices = {
    resp: {
        x: 495,
        y: 870
    },
    //iPhones
    ip3: {
        x: 320,
        y: 480
    },
    ip5: {
        x: 320,
        y: 568
    },
    ip6: {
        x: 375,
        y: 667
    },
    ip6pl: {
        x: 414,
        y: 736
    },
    ip10: {
        x: 375,
        y: 812
    },
    ip10m: {
        x: 414,
        y: 896
    },
    //Samsungs

    sa20: {
        x: 360,
        y: 780
    },
    sj4: {
        x: 360,
        y: 640
    },
    sj8: {
        x: 375,
        y: 720
    },
    sgs8: {
        x: 360,
        y: 740
    }, ////
    sg10e: {
        x: 360,
        y: 760
    }, //
    nex: {
        x: 412,
        y: 732
    },
    gp3: {
        x: 412,
        y: 824
    },
    onep: {
        x: 480,
        y: 853
    },
    zte: {
        x: 320,
        y: 570
    },

    xrn5: {
        x: 360,
        y: 720
    },

    //Tablests
    ipa: {
        x: 768,
        y: 1024
    },
    ipap: {
        x: 1024,
        y: 1366
    },
    sgt10: {
        x: 800,
        y: 1280
    },
    chrpx: {
        x: 1280,
        y: 850
    },
    //Other devices
    jio2: {
        x: 240,
        y: 320
    }
};

const DevToolBar = (props) => {
    const Playable = window.Playable;

    const [params, setParams] = useState(Playable.device.params);
    const [model, setModel] = useState('resp');

    useEffect(() => {
        for (let dev in Devices) {
            if (Devices[dev].x === params.width && Devices[dev].y === params.height) {
                setModel(dev);
                break;
            }
        }
    }, [params.width, params.height]);

    function changeModel(e) {
        const mdl = e.target.value;

        // setModel(mdl);

        params.width = Devices[mdl].x;
        params.height = Devices[mdl].y;

        setParams({ ...params });

        Playable.device.setParams(params);
    }

    const changeParameter = (name, value) => {
        params[name] = value;

        setParams({ ...params });

        Playable.device.setParams(params);
    };

    const toogleFullscreen = () => {
        Playable.device.fullScreen();
    };

    return (
        <div className="d-flex w-100 border-bottom pb-2 my-2">
            <select
                title="Device model"
                className="form-select form-select-sm w-50"
                onChange={changeModel}
                value={model}
            >
                <option value="def" disabled>
                    Choose Device
                </option>
                <option value="resp">Responsive</option>
                <optgroup label="Apple Products">
                    <option value="ip3">iPhone 2G/3G/3GS</option>
                    <option value="ip5">iPhone 5/5S/5C/5SE</option>
                    <option value="ip6">iPhone 6/6S/7/8</option>
                    <option value="ip6pl">iPhone 6+/6S+/7+/8+</option>
                    <option value="ip10">iPhone X, XS</option>
                    <option value="ip10m">iPhone XS MAX/XR</option>
                </optgroup>
                <optgroup label="Android Devices">
                    <option value="sa20">Samsung A20</option>
                    <option value="sj4">Samsung J4/Galaxy S7 Edge/S5</option>
                    <option value="sgs8">Samsung Galaxy S8/S8+/S9/S9+</option>
                    <option value="sg10e">Samsung S10/S10+/Note 9/Note 10/S10E</option>
                    <option value="sj8">Samsung J8</option>
                    <option value="nex">Nexus 6P/5X</option>
                    <option value="gp3">Google Pixel 3</option>
                    <option value="onep">One Plus 3</option>
                    <option value="zte">ZTE Majesty Pro/Maven 3</option>
                    <option value="xrn5">Xiaomi Redmi Note 5</option>
                </optgroup>
                <optgroup label="iPad tablets">
                    <option value="ipa">iPad</option>
                    <option value="ipap">iPad Pro</option>
                    <option value="sgt10">Samsung Galaxy Tab 10</option>
                    <option value="chrpx">Chromebook Pixel</option>
                </optgroup>
                <optgroup label="Other devices">
                    <option value="jio2">JioPhone 2</option>
                </optgroup>
            </select>
            <button
                title="Rotate device"
                className="ms-1 btn btn-sm"
                onClick={() => changeParameter('landscape', !params.landscape)}
            >
                <i className={'fas fa-mobile-alt' + (params.landscape ? ' rotate' : '')} />
            </button>

            <button
                title="Pause / Resume"
                className="btn  btn-sm"
                onClick={() => changeParameter('paused', !params.paused)}
            >
                <i className={'fas fa-' + (params.paused ? 'play' : 'pause')} />
            </button>
            <button
                title="Volume"
                className="btn btn-sm"
                onClick={() => changeParameter('volume', 1 - params.volume)}
            >
                <i className={'fas fa-' + (params.volume === 0 ? 'volume-mute' : 'volume-up')} />
            </button>
            <button title="Volume" className="btn btn-sm" onClick={toogleFullscreen}>
                <i className="fas fa-expand" />
            </button>

            <button title="Show endcard" className="btn ms-auto btn-sm" onClick={Playable.showEndcard}>
                <i className="fas fa-forward" />
            </button>
            <button
                title="Sync & restart playable"
                className="btn ms-1 btn-sm"
                onClick={props.restart || Playable.restart}
            >
                <i className="fas fa-sync-alt" />
            </button>
        </div>
    );
};

export default DevToolBar;
