import DeveloperOptions from './DeveloperOptions';
import { ErrorCard } from './ErrorCard';
import FPSCard from './FPSCard';
import RequirementsList from './RequirementsList';
import SDKList from './SDKList';
import SizeCard from './SizeCard';

const Debugger = () => {
    return (
        <>
            <div className="debugger-control debugger-panel w-100">
                <div className="row border-bottom pb-3">
                    <div className="col-sm-6">
                        <SizeCard></SizeCard>
                    </div>
                    <div className="col-sm-6">
                        <FPSCard></FPSCard>
                    </div>
                </div>

                <ErrorCard />

                <div className="row mt-2 m-1 border-bottom pb-3">
                    <div className="col-sm-12 p-0">
                        <SDKList></SDKList>
                    </div>
                </div>

                <div className="row mt-2 m-1 border-bottom pb-3">
                    <div className="col-sm-12 p-0">
                        <DeveloperOptions></DeveloperOptions>
                    </div>
                </div>

                <div className="row mt-2 m-1">
                    <div className="col-sm-12 p-0">
                        <RequirementsList></RequirementsList>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Debugger;
