import ironsourceLogo from '../img/logos/ironsource.png';
import applovinLogo from '../img/logos/applovin.png';
import mintegralLogo from '../img/logos/mintegral.svg';
import tiktokLogo from '../img/logos/tiktok.png';
import tapjoyLogo from '../img/logos/tapjoy.png';
import vungleLogo from '../img/logos/vungle.png';
import adcolonyLogo from '../img/logos/adcolony.png';
import adwordsLogo from '../img/logos/adwords.png';
import facebookLogo from '../img/logos/logo-fb-ads.png';
import toutiaoLogo from '../img/logos/toutiao.png';
import mytargetLogo from '../img/logos/mytarget.png';
import molocoLogo from '../img/logos/moloco.webp';

const DOWNLOAD_NETWORKS = [
    'preview',
    'ironsource_dapi',
    // 'ironsource_nucleo',
    'facebook',
    'unity',
    'applovin',
    'adcolony',
    'tapjoy',
    'vungle',
    'mintegral',
    'adwords',
    'pangle',
    'toutiao',
    'mytarget',
    'moloco'
];

const UPLOAD_NETWORKS = [
    'preview',
    'ironsource_dapi',
    // 'ironsource_nucleo',
    'facebook',
    'unity',
    'applovin',
    'adcolony',
    'tapjoy'
];

const SUPPORT_REQUESTS_NETWORKS = [
    'unity',
    'vungle',
    'tapjoy',
    'adcolony',
    'ironsource_dapi',
    // 'ironsource_nucleo',
    'snapchat',
    'applovin',
    'appreciate',
    'toutiao',
    'pangle',
    'mintegral'
];

const NETWORK_IMAGE_KEY = {
    ironsource_dapi: 'ironsource',
    // ironsource_nucleo: 'ironsource',
    demo: 'preview'
};

const NetworkImages = {
    preview: (
        <div className="text-nowrap fw-bold fs-6 pb-2">
            <i className="fas fa-globe-americas color-eye me-2" />
            DEMO
        </div>
    ),
    ironsource: <img alt="ironsource logo" src={ironsourceLogo} height="30" />,
    facebook: <img alt="facebook logo" src={facebookLogo} height="30" />,
    unity: (
        <div className="d-flex align-items-center fw-bold h5 mb-0">
            <i className="fab fa-unity color-unity fs-3" /> unity
        </div>
    ),
    applovin: <img alt="applovin logo" src={applovinLogo} height="25" />,
    mintegral: <img alt="mintegral logo" src={mintegralLogo} height="23" />,
    pangle: <img alt="pangle logo" src={tiktokLogo} height="25" />,
    tapjoy: <img alt="tapjoy logo" src={tapjoyLogo} height="30" />,
    vungle: <img alt="vungle logo" src={vungleLogo} height="30" />,
    adcolony: <img alt="adcolony logo" src={adcolonyLogo} height="30" />,
    adwords: <img alt="adwords logo" src={adwordsLogo} height="20" />,
    toutiao: <img alt="toutiao logo" src={toutiaoLogo} height="25" />,
    mytarget: <img alt="mytarget logo" src={mytargetLogo} height="25" />,
    moloco: <img alt="mytarget logo" src={molocoLogo} height="25" />
};

const Networks = {
    applovin: {
        name: 'Applovin',
        displayName: 'Applovin',
        description: 'Applovin single html file version.'
    },

    tapjoy: {
        name: 'Tapjoy',
        displayName: 'TapJoy',
        description: 'TapJoy version.'
    },

    unity: {
        name: 'Unity',
        displayName: 'Unity',
        description: 'Unity Ads mraid compatible single html file version.'
    },

    ironsource_dapi: {
        name: 'IronSource',
        displayName: 'IronSource DAPI',
        description: 'IronSource DAPI single html file version.'
    },

    // ironsource_nucleo: {
    //     name: 'IronSource',
    //     displayName: 'IronSource Nucleo',
    //     description: 'IronSource Nucleo single html file version.'
    // },

    adcolony: {
        name: 'AdColony',
        displayName: 'AdColony',
        description: 'AdColony single html file version.'
    },

    preview: {
        name: 'Demo',
        displayName: 'Preview',
        description: 'Web fullscreen demo version without ad networks features.'
    },

    facebook: {
        name: 'Facebook',
        displayName: 'Facebook HTML',
        description: 'Facebook Playable Ads single html file version.'
    },

    adwords: {
        name: 'Adwords',
        displayName: 'Google Adwords',
        description: 'Google Adwords version with Exit Api implementation'
    },

    mintegral: {
        name: 'Mintegral',
        displayName: 'Mintegral',
        description: 'Mintegral version.'
    },

    toutiao: {
        name: 'Toutiao',
        displayName: 'Toutiao',
        description: 'Toutiao zip archive.'
    },

    pangle: {
        name: 'TikTok Pangle',
        displayName: 'TikTok',
        description: 'Tiktok Pangle zip archive.'
    },

    vungle: {
        name: 'Vungle',
        displayName: 'Vungle',
        description: 'Vungle version in zip archive.'
    },

    mytarget: {
        name: 'MyTarget',
        displayName: 'MyTarget',
        description: 'Facebook Playable Ads zip archive version.'
    },

    moloco: {
        name: 'Moloco',
        displayName: 'Moloco',
        description: 'Facebook Playable Ads single html file version.'
    }
};

const NetworkNameMap = {
    ironsource: 'ironsource_dapi'
};

export {
    Networks,
    NetworkNameMap,
    DOWNLOAD_NETWORKS,
    UPLOAD_NETWORKS,
    NETWORK_IMAGE_KEY,
    NetworkImages,
    SUPPORT_REQUESTS_NETWORKS
};
