/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

import React from 'react';
import VariationsList from './VariationsList';
import Automation from './Automation';

const ControlPanel = () => {
    return (
        <>
            <div className="nav nav-tabs" role="tablist">
                <button
                    title="Variations"
                    className="nav-link active"
                    id="nav-variations-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-variations-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-variations-control"
                    aria-selected="true"
                >
                    <i className="fas fa-th-list" />
                </button>

                <button
                    title="Scenes"
                    className="nav-link"
                    id="nav-automation-control-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-automation-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-automation-control"
                    aria-selected="false"
                >
                    <i className="fas fa-magic" />
                </button>
            </div>

            <div className="tab-content">
                <div
                    className="tab-pane h-100 show active"
                    id="nav-variations-control"
                    role="tabpanel"
                    aria-labelledby="nav-variations-control-tab"
                >
                    <div className="d-flex flex-column h-100">
                        <VariationsList />
                    </div>
                </div>

                <div
                    className="tab-pane h-100"
                    id="nav-automation-control"
                    role="tabpanel"
                    aria-labelledby="nav-automation-control-tab"
                >
                    <div className="d-flex flex-column h-100">
                        <Automation />
                    </div>
                </div>
            </div>
        </>
    );
};
export default ControlPanel;
